import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import './style.scss';

export default class RefreshPrizeCoupon extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;

        this._addEventListeners();
    }

    _addEventListeners() {}
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt045-refresh-prize-coupon');
}
