import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class StrilloBooking extends Component {
    constructor(name, root) {
        super(name, root);

        this.title = this._dEl('title');
        if (!this.title) {
            this._removeMainComponent();
            return;
        }

        this.iconColor = this.root.dataset.iconColor;
        this.textColor = this.root.dataset.textColor;
        this.icon = this._dEl('icon');
        this._initIcon();
        this._initText();
        this.downloadLink = this._dEl('downloadLink');
        this._initDownloadLink();

        this.root.classList.add(this._mod('show'));
    }

    _initIcon() {
        if (this.iconColor) {
            this.icon.style.color = this.iconColor;
        }
    }

    _initText() {
        if (this.textColor) {
            this.root.style.setProperty('--color-strillo-text', this.textColor);
        }
    }

    _initDownloadLink() {
        if (!this.downloadLink) return;
        this.downloadLink.setAttribute('download', '');
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt129-strillo-booking');
}
