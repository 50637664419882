import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { closeLoader, delay, emptyElement, openLoader } from '../../../../../libs/utils';
import { formToJSON } from '../../../../../libs/form-to-json';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getDictionary } from '../../../../../libs/dictionary-provider';

export default class MapSearchStoresForm extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    _doLogic() {
        this.register = getRegister();
        this.isBooksReservation = this.root.hasAttribute('data-books-reservation');
        this.textReservation = this.root.dataset.storeTextBookReservation;
        this.textNoReservation = this.root.dataset.storeTextBookNoReservation;
        this.bookConfig = JSON.parse(this.root.dataset.storeBookConfig);

        try {
            this.citiesMap = JSON.parse(this.root.dataset.citiesMap);
        } catch (error) {
            console.warn('Could not parse cities map');
            this.citiesMap = {};
        }
        try {
            this.stores = JSON.parse(this.root.dataset.stores);
        } catch (error) {
            console.warn('Could not parse store results');
            this.stores = [];
        }
        this.pagination = [];
        this.PAGE_SIZE = 6;
        this.LOAD_MORE_HIDDEN = this._elMod('loadMore', 'hidden');

        this._getElements();
        this._addEventListeners();
    }

    _convertToUrlFriendly(str){

        let tmp = str
            .trim()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .replace(/\s|[/\\_:]/g, '-')
            .replace(/[^A-Za-z0-9-]/g, '')
            .toLowerCase();
    
        return tmp.length > 150 ? tmp.substring(0, 150) : tmp;
    }

    _getElements() {
        this.form = this._dEl('form');
        this.district = this._dEl('district');
        this.city = this._dEl('city'); /* city select is optional */
        this.search = this._dEl('search');
        this.numResults = this._dEl('numResults');
        this.numResultsCount = this.numResults.querySelector('span');
        this.NUM_RESULTS_SHOW = this._elMod('numResults', 'show');
        this.results = this._dEl('results');
        this.loadMore = this._dEl('loadMore');
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            (e) => {
                if (!this.city) return;
                const districtCode = e.data.value;
                this._loadCities(this.citiesMap[`${districtCode}`]);
            },
            this.district
        );
        this._addListener(
            'submit',
            (e) => {
                e.preventDefault();
            },
            this.form
        );
        this._addListener(
            'click',
            () => {
                this._doSearchLogic(formToJSON(this.form));
            },
            this.search
        );
        this._addListener(
            'click',
            () => {
                this.currentPage++;
                this.renderResults(this.currentPage);
                this._checkLoadMore();
            },
            this.loadMore
        );
    }

    _doSearchLogic({ codiceProvincia, codiceComuneLabel }) {
        const districtCode = codiceProvincia;
        const cityName = codiceComuneLabel;

        if (!districtCode) {
            // district required
            this._requireSelection(this.district);
            return;
        }

        if (!this.city) {
            // search only by district
            this._loadStores({ districtCode });
            return;
        }

        if (!cityName) {
            // city required
            this._requireSelection(this.city);
            return;
        }

        // search by district and city
        this._loadStores({ districtCode, cityName });
    }

    _requireSelection(selectEl) {
        const obj = this.register.getClass(selectEl);
        obj?.setState('error');
        obj?._requireField();
    }

    _loadCities(cities) {
        const obj = this.register.getClass(this.city);
        obj.enable();
        obj.reset();
        obj.setItems(cities);
    }

    async _loadStores({ districtCode, cityName }) {
        openLoader('main');
        const uCityName = cityName ? this._convertToUrlFriendly(cityName) : undefined;
        const filtered = this.stores?.filter((store) => {
            const storeComune = this._convertToUrlFriendly(store.nomeComune);
            return uCityName ? (storeComune == uCityName) : (store.codiceProvincia == districtCode)
        });
        await this.paginateStoreCards(filtered);
        this.setNumResults(filtered.length);
        emptyElement(this.results);
        this.renderResults();
        this._checkLoadMore();
        await delay(250);
        closeLoader('main');
    }

    _getStoreCard(store, storeCardHtml) {
        const config = this.bookConfig.find(
            (item) => store.serviziId.filter((service) => service == item.serviceId).length > 0
        );
        const storeCardData = {
            title: store.pdvTitle || store.descrizioneInsegna,
            address: store.pdvAddress || `${store.indirizzo} ${store.nomeComune} (${store.codiceProvincia})`,
            email: this.isBooksReservation ? store.booksProviderEmail : null,
            link: this.isBooksReservation ? store.booksProviderLink : store.pdvLink,
            text: config?.text,
            linkLabel: getDictionary().get(this.isBooksReservation ? this.textReservation : this.textNoReservation),
        };
        return runTemplate(storeCardHtml, storeCardData);
    }

    async paginateStoreCards(stores) {
        const storeCardHtml = (await import('./partials/store-card.html')).default;
        this.pagination.splice(0, this.pagination.length);
        for (const store of stores) {
            const storeCard = this._getStoreCard(store, storeCardHtml);
            this.pagination.push(storeCard);
        }
        this.numPages = Math.ceil(this.pagination.length / this.PAGE_SIZE);
        this.currentPage = 1;
    }

    renderResults(pageIndex = 1) {
        if (!this.numPages || this.numPages <= 0) {
            console.warn('Missing or invalid numPages');
            return;
        }
        const start = (Math.min(pageIndex, this.numPages) - 1) * this.PAGE_SIZE;
        const end = start + this.PAGE_SIZE;
        const page = this.pagination.slice(start, end);
        for (const card of page) {
            this.results.append(card);
        }
    }

    _checkLoadMore() {
        if (this.currentPage >= this.numPages) {
            this.loadMore.classList.add(this.LOAD_MORE_HIDDEN);
        } else {
            this.loadMore.classList.remove(this.LOAD_MORE_HIDDEN);
        }
    }

    setNumResults(num = 0) {
        if (!num || num <= 0) {
            this.numResultsCount.innerText = '0';
            this.numResults.classList.remove(this.NUM_RESULTS_SHOW);
            return;
        }
        this.numResultsCount.innerText = `${num}`;
        this.numResults.classList.add(this.NUM_RESULTS_SHOW);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt145-map-search-stores-form');
}
