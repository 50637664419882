import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { getRegister } from '../../../../../libs/register';
import './style.scss';

export default class Breadcrumb extends Component {
    constructor(name, root) {
        super(name, root);
        this.track = this._dEl('container');
        this._init();
    }

    async _init() {
        await this._initScrollbarBreadcrumb();
        this._addEventListeners();
    }

    async _initScrollbarBreadcrumb() {
        if (!this.track) return;
        this.psBreadcrumb?.destroy();
        this.psBreadcrumb = null;
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        this.psBreadcrumb = new PerfectScrollbar(this.track, {
            swipeEasing: true,
            suppressScrollY: true,
            wheelPropagation: false,
            useBothWheelAxes: true,
            minScrollbarLength: 32,
        });
    }

    _addEventListeners() {
        if (!this.root.classList.contains(this._mod('noFade'))) {
            this._addListener(
                'ps-x-reach-end',
                () => {
                    this.root.classList.add(this._mod('noFade'));
                },
                this.track
            );
            this._addListener(
                'ps-scroll-left',
                () => {
                    this.root.classList.remove(this._mod('noFade'));
                },
                this.track
            );
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt117-breadcrumb');
}
