import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class CardServicesTwoColumnsComponent extends Component {
    constructor(name, root) {
        super(name, root);
    }

}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt147-two-columns-card-services');
}
