///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////// STYLE
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
import './style/global.scss';
import './style/tailwind.css';
import './style/themes/index.scss';
import './style/vendor.scss';

///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////// COMPONENTS
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
import { getRegister } from './libs/register';
import { getStoreManager } from './libs/store-manager';

const register = getRegister();
const storeManager = getStoreManager();

/* Atoms */
import './repository/apps/conad-corporate/templates/rt001-richtext/script.js';
import rt002 from './repository/apps/conad-corporate/templates/rt002-cta/script.js';
import rt003 from './repository/apps/conad-corporate/templates/rt003-checkbox/script.js';
import rt004 from './repository/apps/conad-corporate/templates/rt004-radio/script.js';
import rt005 from './repository/apps/conad-corporate/templates/rt005-toggle/script.js';
import rt006 from './repository/apps/conad-corporate/templates/rt006-tag/script.js';
import './repository/apps/conad-corporate/templates/rt007-label-mdd/script.js';
import rt008 from './repository/apps/conad-corporate/templates/rt008-text-field/script.js';
import rt009 from './repository/apps/conad-corporate/templates/rt009-date-picker/script.js';
import rt010 from './repository/apps/conad-corporate/templates/rt010-search-field/script.js';
import rt011 from './repository/apps/conad-corporate/templates/rt011-select/script.js';
import rt012 from './repository/apps/conad-corporate/templates/rt012-switch/script.js';
import rt013 from './repository/apps/conad-corporate/templates/rt013-step/script.js';
import rt014 from './repository/apps/conad-corporate/templates/rt014-file-upload/script.js';
import './repository/apps/conad-corporate/templates/rt015-badge/script.js';
import rt016 from './repository/apps/conad-corporate/templates/rt016-map-pin/script.js';
import rt017 from './repository/apps/conad-corporate/templates/rt017-accordion/script.js';
import rt018 from './repository/apps/conad-corporate/templates/rt018-textarea/script.js';
import rt019 from './repository/apps/conad-corporate/templates/rt019-status-dot/script.js';
import rt020 from './repository/apps/conad-corporate/templates/rt020-map-info-window/script.js';
import rt021 from './repository/apps/conad-corporate/templates/rt021-store-card/script.js';
import rt022 from './repository/apps/conad-corporate/templates/rt022-picture/script.js';
import rt023 from './repository/apps/conad-corporate/templates/rt023-insignia-card/script.js';
import './repository/apps/conad-corporate/templates/rt024-strillo/script.js';
import rt025 from './repository/apps/conad-corporate/templates/rt025-search-alert/script.js';
import './repository/apps/conad-corporate/templates/rt026-category/script.js';
import rt027 from './repository/apps/conad-corporate/templates/rt027-paginator/script.js';
import rt028 from './repository/apps/conad-corporate/templates/rt028-dynamic-image/script.js';
import rt029 from './repository/apps/conad-corporate/templates/rt029-share-links/script.js';
import rt030 from './repository/apps/conad-corporate/templates/rt030-hotspot-card/script.js';
import rt031 from './repository/apps/conad-corporate/templates/rt031-filter/script.js';
import rt032 from './repository/apps/conad-corporate/templates/rt032-filter-popup/script.js';
import rt033 from './repository/apps/conad-corporate/templates/rt033-video/script.js';
import rt034 from './repository/apps/conad-corporate/templates/rt034-pin-code-field/script.js';
import rt035 from './repository/apps/conad-corporate/templates/rt035-tooltip/script.js';
import rt036 from './repository/apps/conad-corporate/templates/rt036-feedback/script.js';
import rt037 from './repository/apps/conad-corporate/templates/rt037-bookmark/script.js';
import rt038 from './repository/apps/conad-corporate/templates/rt038-category-filter/script.js';
import rt045 from './repository/apps/conad-corporate/templates/rt045-refresh-prize-coupon/script.js';

// register.registerClass('rt001-richtext', rt001Richtext);
register.registerClass('rt002-cta', rt002);
register.registerClass('rt003-checkbox', rt003);
register.registerClass('rt004-radio', rt004);
register.registerClass('rt005-toggle', rt005);
register.registerClass('rt006-tag', rt006);
// register.registerClass('rt007-label-mdd', rt007);
register.registerClass('rt008-text-field', rt008);
register.registerClass('rt009-date-picker', rt009);
register.registerClass('rt010-search-field', rt010);
register.registerClass('rt011-select', rt011);
register.registerClass('rt012-switch', rt012);
register.registerClass('rt013-step', rt013);
register.registerClass('rt014-file-upload', rt014);
// register.registerClass('rt015-badge', rt015);
register.registerClass('rt016-map-pin', rt016);
register.registerClass('rt017-accordion', rt017);
register.registerClass('rt018-textarea', rt018);
register.registerClass('rt019-status-dot', rt019);
register.registerClass('rt020-map-info-window', rt020);
register.registerClass('rt021-store-card', rt021);
register.registerClass('rt022-picture', rt022);
register.registerClass('rt023-insignia-card', rt023);
// register.registerClass('rt024-strillo', rt024);
register.registerClass('rt025-search-alert', rt025);
// register.registerClass('rt026-category', rt026);
register.registerClass('rt027-paginator', rt027);
register.registerClass('rt028-dynamic-image', rt028);
register.registerClass('rt029-share-links', rt029);
register.registerClass('rt030-hotspot-card', rt030);
register.registerClass('rt031-filter', rt031);
register.registerClass('rt032-filter-popup', rt032);
register.registerClass('rt033-video', rt033);
register.registerClass('rt034-pin-code-field', rt034);
register.registerClass('rt035-tooltip', rt035);
register.registerClass('rt036-feedback', rt036);
register.registerClass('rt037-bookmark', rt037);
register.registerClass('rt038-category-filter', rt038);
register.registerClass('rt045-refresh-prize-coupon', rt045);

/* Layouts */
import rl1 from './repository/apps/conad-corporate/components/layout/rl1-layout/script.js';
import './repository/apps/conad-corporate/components/layout/rl2-anchors-layout/script.js';

register.registerClass('rl1-layout', rl1);
//register.registerClass('rl2-anchors-layout', rl2);

/* Structure */
import rt050 from './repository/apps/conad-corporate/templates/rt050-footer/script.js';
import rt051 from './repository/apps/conad-corporate/templates/rt051-header/script.js';
import rt052 from './repository/apps/conad-corporate/templates/rt052-store-locator/script.js';
import rt054 from './repository/apps/conad-corporate/templates/rt054-topbar/script.js';
import rt055 from './repository/apps/conad-corporate/templates/rt055-menu-dsk/script.js';
import rt056 from './repository/apps/conad-corporate/templates/rt056-menu-mob/script.js';
import rt057 from './repository/apps/conad-corporate/templates/rt057-store-header/script.js';
import rt058 from './repository/apps/conad-corporate/templates/rt058-store-info/script.js';
import rt059 from './repository/apps/conad-corporate/templates/rt059-advice/script.js';
import rt060 from './repository/apps/conad-corporate/templates/rt060-recipe/script.js';
import rt061 from './repository/apps/conad-corporate/templates/rt061-anchors/script.js';
import rt062 from './repository/apps/conad-corporate/templates/rt062-faq/script.js';
import rt063 from './repository/apps/conad-corporate/templates/rt063-contact-form/script.js';
import rt064 from './repository/apps/conad-corporate/templates/rt064-filters/script.js';
import rt065 from './repository/apps/conad-corporate/templates/rt065-form-premio/script.js';
import './repository/apps/conad-corporate/templates/rt066-result-premio/script.js';
import rt067 from './repository/apps/conad-corporate/templates/rt067-plays-premio/script.js';
import rt068 from './repository/apps/conad-corporate/templates/rt068-search-results/script.js';
import rt069 from './repository/apps/conad-corporate/templates/rt069-search-bar/script.js';
import './repository/apps/conad-corporate/templates/rt070-contact-heading/script.js';
import rt071 from './repository/apps/conad-corporate/templates/rt071-flyer-filters/script.js';
import rt072 from './repository/apps/conad-corporate/templates/rt072-disaggregated-block/script.js';
import rt073 from './repository/apps/conad-corporate/templates/rt073-assicurazioni-form/script.js';


register.registerClass('rt050-footer', rt050);
register.registerClass('rt051-header', rt051);
register.registerClass('rt052-store-locator', rt052);
register.registerClass('rt054-topbar', rt054);
register.registerClass('rt055-menu-dsk', rt055);
register.registerClass('rt056-menu-mob', rt056);
register.registerClass('rt057-store-header', rt057);
register.registerClass('rt058-store-info', rt058);
register.registerClass('rt059-advice', rt059);
register.registerClass('rt060-recipe', rt060);
register.registerClass('rt061-anchors', rt061);
register.registerClass('rt062-faq', rt062);
register.registerClass('rt063-contact-form', rt063);
register.registerClass('rt064-filters', rt064);
register.registerClass('rt065-form-premio', rt065);
// register.registerClass('rt066-result-premio', rt066);
register.registerClass('rt067-plays-premio', rt067);
register.registerClass('rt068-search-results', rt068);
register.registerClass('rt069-search-bar', rt069);
// register.registerClass('rt070-contact-heading', rt070);
register.registerClass('rt071-flyer-filters', rt071);
register.registerClass('rt072-disaggregated-block', rt072);
register.registerClass('rt073-assicurazioni-form', rt073);


/* Components */
import rt100Hero from './repository/apps/conad-corporate/templates/rt100-hero-carta/script.js';
import rt100 from './repository/apps/conad-corporate/templates/rt100-hero/script.js';
import rt101 from './repository/apps/conad-corporate/templates/rt101-thumb-slider/script.js';
import rt102fmf from './repository/apps/conad-corporate/templates/rt102-carrot-banner-fmf/script.js';
import rt102fsf from './repository/apps/conad-corporate/templates/rt102-carrot-banner-fsf/script.js';
import rt102Hmd from './repository/apps/conad-corporate/templates/rt102-carrot-banner-hmd/script.js';
import rt102hmf from './repository/apps/conad-corporate/templates/rt102-carrot-banner-hmf/script.js';
import rt102hsd from './repository/apps/conad-corporate/templates/rt102-carrot-banner-hsd/script.js';
import rt102hsf from './repository/apps/conad-corporate/templates/rt102-carrot-banner-hsf/script.js';
import rt102 from './repository/apps/conad-corporate/templates/rt102-carrot-banner/script.js';
import './repository/apps/conad-corporate/templates/rt103-categories-map/script.js';
import './repository/apps/conad-corporate/templates/rt104-service-banner/script.js';
import './repository/apps/conad-corporate/templates/rt105-collector/script.js';
import rt106 from './repository/apps/conad-corporate/templates/rt106-lancio/script.js';
import rt107 from './repository/apps/conad-corporate/templates/rt107-slider-mdd/script.js';
import rt108 from './repository/apps/conad-corporate/templates/rt108-select-store/script.js';
import rt109 from './repository/apps/conad-corporate/templates/rt109-container-card-text/script.js';
import rt110 from './repository/apps/conad-corporate/templates/rt110-italy-map/script.js';
import rt111 from './repository/apps/conad-corporate/templates/rt111-listing/script.js';
import rt112 from './repository/apps/conad-corporate/templates/rt112-associate-card-banner/script.js';
import rt113 from './repository/apps/conad-corporate/templates/rt113-italy-nav/script.js';
import './repository/apps/conad-corporate/templates/rt114-categories/script.js';
import rt115 from './repository/apps/conad-corporate/templates/rt115-ed-categories/script.js';
import './repository/apps/conad-corporate/templates/rt116-pub-banner/script.js';
import rt117 from './repository/apps/conad-corporate/templates/rt117-breadcrumb/script.js';
import rt118 from './repository/apps/conad-corporate/templates/rt118-slider-card-elev-editorial/script.js';
import rt119 from './repository/apps/conad-corporate/templates/rt119-slider-card-elev-news/script.js';
import rt120 from './repository/apps/conad-corporate/templates/rt120-slider-card-elev-recipes/script.js';
import rt121 from './repository/apps/conad-corporate/templates/rt121-slider-card-elev-contents/script.js';
import rt122 from './repository/apps/conad-corporate/templates/rt122-slider-card-text/script.js';
import './repository/apps/conad-corporate/templates/rt123-container-card-services/script.js';
import rt124 from './repository/apps/conad-corporate/templates/rt124-slider-card-elev-brands/script.js';
import rt125 from './repository/apps/conad-corporate/templates/rt125-banner-carousel/script.js';
import rt126 from './repository/apps/conad-corporate/templates/rt126-slider-card-spec-stores/script.js';
import rt127 from './repository/apps/conad-corporate/templates/rt127-slider-card-lanci/script.js';
import './repository/apps/conad-corporate/templates/rt128-cards-icon-text/script.js';
import rt129 from './repository/apps/conad-corporate/templates/rt129-strillo-booking/script.js';
import './repository/apps/conad-corporate/templates/rt130-strillo-icon-text-link/script.js';
import rt131 from './repository/apps/conad-corporate/templates/rt131-strillo-specialized-store/script.js';
import rt132 from './repository/apps/conad-corporate/templates/rt132-login-banner/script.js';
import rt133 from './repository/apps/conad-corporate/templates/rt133-flyer-viewer/script.js';
import './repository/apps/conad-corporate/templates/rt134-cookie-settings/script.js';
import rt135 from './repository/apps/conad-corporate/templates/rt135-insieme-cards-banner-cta/script.js';
import './repository/apps/conad-corporate/templates/rt136-strillo-icon-richtext/script.js';
import './repository/apps/conad-corporate/templates/rt137-strillo-app/script.js';
import './repository/apps/conad-corporate/templates/rt138-richtext-section/script.js';
import rt139 from './repository/apps/conad-corporate/templates/rt139-hero-collection/script.js';
import rt140 from './repository/apps/conad-corporate/templates/rt140-hero-premio/script.js';
import rt141 from './repository/apps/conad-corporate/templates/rt141-slider-card-premi/script.js';
import './repository/apps/conad-corporate/templates/rt142-container-card-premi/script.js';
import rt143 from './repository/apps/conad-corporate/templates/rt143-container-card-nearby/script.js';
import './repository/apps/conad-corporate/templates/rt144-slider-label-mdd/script.js';
import rt145form from './repository/apps/conad-corporate/templates/rt145-map-search-stores-form/script.js';
import rt145 from './repository/apps/conad-corporate/templates/rt145-map-search-stores/script.js';
import './repository/apps/conad-corporate/templates/rt146-ingredients-actions/script.js';
import rt147 from './repository/apps/conad-corporate/templates/rt147-two-columns-card-services/script.js';
import rt148 from './repository/apps/conad-corporate/templates/rt148-strillo-60years/script.js';
import rt149 from './repository/apps/conad-corporate/templates/rt149-search-stores/script.js';
import rt150 from './repository/apps/conad-corporate/templates/rt150-disaggregated-flyer/script.js';
import './repository/apps/conad-corporate/templates/rt151-flyer-listing/script.js';
import rt152 from './repository/apps/conad-corporate/templates/rt152-switch-component/script.js';
import rt153 from './repository/apps/conad-corporate/templates/rt153-banner-image/script.js';
import rt154 from './repository/apps/conad-corporate/templates/rt154-update-data-refresh/script.js';
import rt155 from './repository/apps/conad-corporate/templates/rt155-thankyou-simple-image-text/script.js';
import rt156form from './repository/apps/conad-corporate/templates/rt156-excel-to-map-search-stores-form/script.js';
import rt156 from './repository/apps/conad-corporate/templates/rt156-excel-to-map-search-stores/script.js';
import rt157 from './repository/apps/conad-corporate/templates/rt157-cards-faq/script.js';
import rt158 from './repository/apps/conad-corporate/templates/rt158-after-policy-activation/script.js';
import rt159 from './repository/apps/conad-corporate/templates/rt159-strillo-icon-text-link/script.js';

register.registerClass('rt100-hero', rt100);
register.registerClass('rt100-hero-carta', rt100Hero);
register.registerClass('rt101-thumb-slider', rt101);
register.registerClass('rt102-carrot-banner', rt102);
register.registerClass('rt102-carrot-banner-hmd', rt102Hmd);
register.registerClass('rt102-carrot-banner-hsd', rt102hsd);
register.registerClass('rt102-carrot-banner-hmf', rt102hmf);
register.registerClass('rt102-carrot-banner-hsf', rt102hsf);
register.registerClass('rt102-carrot-banner-fmf', rt102fmf);
register.registerClass('rt102-carrot-banner-fsf', rt102fsf);
// register.registerClass('rt103-categories-map', rt103);
// register.registerClass('rt104-service-banner', rt104);
// register.registerClass('rt105-collector', rt105);
register.registerClass('rt106-lancio', rt106);
register.registerClass('rt107-slider-mdd', rt107);
register.registerClass('rt108-select-store', rt108);
register.registerClass('rt109-container-card-text', rt109);
register.registerClass('rt110-italy-map', rt110);
register.registerClass('rt111-listing', rt111);
register.registerClass('rt112-associate-card-banner', rt112);
register.registerClass('rt113-italy-nav', rt113);
// register.registerClass('rt114-categories', rt114);
register.registerClass('rt115-ed-categories', rt115);
register.registerClass('rt117-breadcrumb', rt117);
// register.registerClass('rt116-pub-banner', rt116);
register.registerClass('rt118-slider-card-elev-editorial', rt118);
register.registerClass('rt119-slider-card-elev-news', rt119);
register.registerClass('rt120-slider-card-elev-recipes', rt120);
register.registerClass('rt121-slider-card-elev-contents', rt121);
register.registerClass('rt122-slider-card-text', rt122);
// register.registerClass('rt123-container-card-services', rt123);
register.registerClass('rt124-slider-card-elev-brands', rt124);
register.registerClass('rt125-banner-carousel', rt125);
register.registerClass('rt126-slider-card-spec-stores', rt126);
register.registerClass('rt127-slider-card-lanci', rt127);
// register.registerClass('rt128-cards-icon-text', rt128);
register.registerClass('rt129-strillo-booking', rt129);
// register.registerClass('rt130-strillo-icon-text-link', rt130);
register.registerClass('rt131-strillo-specialized-store', rt131);
register.registerClass('rt132-login-banner', rt132);
register.registerClass('rt133-flyer-viewer', rt133);
// register.registerClass('rt134-cookie-settings', rt134);
register.registerClass('rt135-insieme-cards-banner-cta', rt135);
// register.registerClass('rt136-strillo-icon-richtext', rt136);
// register.registerClass('rt137-strillo-app', rt137);
// register.registerClass('rt138-richtext-section', rt138);
register.registerClass('rt139-hero-collection', rt139);
register.registerClass('rt140-hero-premio', rt140);
register.registerClass('rt141-slider-card-premi', rt141);
// register.registerClass('rt142-container-card-premi', rt142);
register.registerClass('rt143-container-card-nearby', rt143);
// register.registerClass('rt144-slider-label-mdd', rt144);
register.registerClass('rt145-map-search-stores', rt145);
register.registerClass('rt145-map-search-stores-form', rt145form);
// register.registerClass('rt146-ingredients-actions', rt146);
register.registerClass('rt147-two-columns-card-services', rt147);
register.registerClass('rt148-strillo-60years', rt148);
register.registerClass('rt149-search-stores', rt149);
register.registerClass('rt150-disaggregated-flyer', rt150);
// register.registerClass('rt151-flyer-listing', rt151);
register.registerClass('rt152-switch-component', rt152);
register.registerClass('rt153-banner-image', rt153);
register.registerClass('rt154-update-data-refresh', rt154);
register.registerClass('rt155-thankyou-simple-image-text', rt155);
register.registerClass('rt156-excel-to-map-search-stores', rt156);
register.registerClass('rt156-excel-to-map-search-stores-form', rt156form);
register.registerClass('rt157-cards-faq', rt157);
register.registerClass('rt158-after-policy-activation', rt158);
register.registerClass('rt159-strillo-icon-text-link', rt159);

/* Cards */
import './repository/apps/conad-corporate/templates/rt200-card-elev-editorial/script.js';
import './repository/apps/conad-corporate/templates/rt201-card-elev-news/script.js';
import rt202 from './repository/apps/conad-corporate/templates/rt202-card-elev-recipes/script.js';
import './repository/apps/conad-corporate/templates/rt203-card-elev-contents/script.js';
import './repository/apps/conad-corporate/templates/rt204-card-text/script.js';
import './repository/apps/conad-corporate/templates/rt205-card-elev-brands/script.js';
import './repository/apps/conad-corporate/templates/rt206-card-services/script.js';
import './repository/apps/conad-corporate/templates/rt207-big-article/script.js';
import './repository/apps/conad-corporate/templates/rt208-small-articles/script.js';
import './repository/apps/conad-corporate/templates/rt209-card-product-mdd/script.js';
import './repository/apps/conad-corporate/templates/rt210-card-spec-stores/script.js';
import './repository/apps/conad-corporate/templates/rt211-card-lanci/script.js';
import './repository/apps/conad-corporate/templates/rt212-card-lancio/script.js';
import rt213 from './repository/apps/conad-corporate/templates/rt213-card-product-flyer/script.js';
import rt214 from './repository/apps/conad-corporate/templates/rt214-card-mdd/script.js';
import rt215 from './repository/apps/conad-corporate/templates/rt215-card-flyer/script.js';
import rt216 from './repository/apps/conad-corporate/templates/rt216-card-icon-text/script.js';
import rt217 from './repository/apps/conad-corporate/templates/rt217-card-banner/script.js';
import rt218 from './repository/apps/conad-corporate/templates/rt218-card-premi/script.js';
import rt219 from './repository/apps/conad-corporate/templates/rt219-fuel-card/script.js';
import rt220 from './repository/apps/conad-corporate/templates/rt220-card-nearby/script.js';
import rt221 from './repository/apps/conad-corporate/templates/rt221-card-page/script.js';
import rt222 from './repository/apps/conad-corporate/templates/rt222-card-promotional/script.js';

// register.registerClass('rt200-card-elev-editorial', rt200);
// register.registerClass('rt201-card-elev-news', rt201);
register.registerClass('rt202-card-elev-recipes', rt202);
// register.registerClass('rt203-card-elev-contents', rt203);
// register.registerClass('rt204-card-text', rt204);
// register.registerClass('rt205-card-elev-brands', rt205);
// register.registerClass('rt206-card-services', rt206);
// register.registerClass('rt207-big-article', rt207);
// register.registerClass('rt208-small-articles', rt208);
// register.registerClass('rt209-card-product-mdd', rt209);
// register.registerClass('rt210-card-spec-stores', rt210);
// register.registerClass('rt211-card-lanci', rt211);
// register.registerClass('rt212-card-lancio', rt212);
register.registerClass('rt213-card-product-flyer', rt213);
register.registerClass('rt214-card-mdd', rt214);
register.registerClass('rt215-card-flyer', rt215);
register.registerClass('rt216-card-icon-text', rt216);
register.registerClass('rt217-card-banner', rt217);
register.registerClass('rt218-card-premi', rt218);
register.registerClass('rt219-fuel-card', rt219);
register.registerClass('rt220-card-nearby', rt220);
register.registerClass('rt221-card-page', rt221);
register.registerClass('rt222-card-promotional', rt222);

/* Modals */
import rt250 from './repository/apps/conad-corporate/templates/rt250-select-pos-step1/script.js';
import rt251map from './repository/apps/conad-corporate/templates/rt251-select-pos-step2-map/script.js';
import rt251 from './repository/apps/conad-corporate/templates/rt251-select-pos-step2/script.js';
import rt252 from './repository/apps/conad-corporate/templates/rt252-pos-list/script.js';
import rt253 from './repository/apps/conad-corporate/templates/rt253-store-locator-filters/script.js';
import rt254 from './repository/apps/conad-corporate/templates/rt254-filters/script.js';
import rt255 from './repository/apps/conad-corporate/templates/rt255-flyer-filters/script.js';
import rt256 from './repository/apps/conad-corporate/templates/rt256-error/script.js';

register.registerClass('rt250-select-pos-step1', rt250);
register.registerClass('rt251-select-pos-step2', rt251);
register.registerClass('rt251-select-pos-step2-map', rt251map);
register.registerClass('rt252-pos-list', rt252);
register.registerClass('rt253-store-locator-filters', rt253);
register.registerClass('rt254-filters', rt254);
register.registerClass('rt255-flyer-filters', rt255);
register.registerClass('rt256-error', rt256);

/* Popups */
import rt300 from './repository/apps/conad-corporate/templates/rt300-login-popup/script.js';
import rt301 from './repository/apps/conad-corporate/templates/rt301-books-reservation/script.js';
import rt302 from './repository/apps/conad-corporate/templates/rt302-add-store-popup/script.js';
import rt303 from './repository/apps/conad-corporate/templates/rt303-refresh-c-popup/script.js';
import rt304 from './repository/apps/conad-corporate/templates/rt304-refresh-confirm-popup/script.js';
import rt305 from './repository/apps/conad-corporate/templates/rt305-refresh-prize-choice/script.js';
import rt306 from './repository/apps/conad-corporate/templates/rt306-excel-to-map-card-details-popup/script.js';

register.registerClass('rt300-login-popup', rt300);
register.registerClass('rt301-books-reservation', rt301);
register.registerClass('rt302-add-store-popup', rt302);
register.registerClass('rt303-refresh-c-popup', rt303);
register.registerClass('rt304-refresh-confirm-popup', rt304);
register.registerClass('rt305-refresh-prize-choice', rt305);
register.registerClass('rt306-excel-to-map-card-details-popup', rt306);

/* Page */
import rs1 from './repository/apps/conad-corporate/components/structure/rs1-page/script.js';

register.registerClass('rs1-page', rs1);

/* RU - Modal, popups and loaders */
import ru002 from './repository/apps/conad-corporate/templates/ru002-modal/script.js';
import ru003 from './repository/apps/conad-corporate/templates/ru003-popup/script.js';
import ru004 from './repository/apps/conad-corporate/templates/ru004-loader/script.js';

register.registerClass('ru002-modal', ru002);
register.registerClass('ru003-popup', ru003);
register.registerClass('ru004-loader', ru004);

///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////// RUNNING
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
register.apply(document.querySelector('.rs1-page'));
storeManager.checkInit(window.initialStore);
