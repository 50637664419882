import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';
import {
    closeLoader,
    flyerData,
    generateTrackNegozioInfo,
    generateTrackVolantinoInfo,
    openLoader,
} from '../../../../../libs/utils';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class StoreHeader extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.FLYER_HIDDEN = this._elMod('flyer', 'hidden');

        this.flyersWrapper = this._dEl('flyers');
        this.track = this._dEl('flyersTrack');
        this.carousel = this._dEl('flyersCarousel');
        this.showAll = this._dEl('showAll');
        this.prevSlide = this._dEl('flyersPrev');
        this.nextSlide = this._dEl('flyersNext');
        this.loadMore = this._dEl('loadMore');
        this.btnPreferred = this._dEl('preferred');
        this.hoursId = this.root.dataset.hours;
        this.hours = document.getElementById(this.hoursId);
        this.specialized = this.root.hasAttribute('data-specialized');
        this.trackingManager = getTrackingManager();
        this.trackingManager.appendVariable({
            currentServices:
                this.root.dataset.services && !this.specialized
                    ? [...document.querySelectorAll(`.rt210-card-spec-stores[data-type="service"]`)]
                          .map((value) => value.dataset.service)
                          .reduce((acc, value) => acc + '|' + value)
                    : '',
        });

        this.swiper = null;
        this.userService = getUserService();
        this.fuel = this.root.hasAttribute('data-fuel');
        if (this.fuel) {
            await this._initSwiper();
        } else {
            await this._addFlyers();
            this.flyerCards = this._dEl('flyer', true);
            if (this.flyerCards && this.flyerCards.length > 3) {
                this.loadMore.classList.remove(this._elMod('loadMore', 'hidden'));
            }
            await this._initSwiper();
            this._manageSwiperMobile();
        }
        if (this.track && this.track.childElementCount <= 3) {
            this.flyersWrapper.classList.add(this._elMod('flyers', 'noArrows'));
        }

        this._addStoreListeners();
        this._addEventListeners();
        this._checkUser();
        this._trackPageView();

        // set this pdv as the last visited for current user
        this.userService.setLastVisitedStore(this.root.dataset.storeId);
    }

    async _addFlyers() {
        /* get flyers */
        try {
            this.flyers = JSON.parse(this.root.dataset.flyers);
        } catch (error) {
            console.warn('Error in parsing flyers json');
        }
        if (!this.flyers || this.flyers.length <= 0) {
            /* no flyers found */
            return;
        }

        // load html
        const flyerCardHtml = (await import('../rt215-card-flyer/rt215-card-flyer.html')).default;

        /* enrich flyers and add to track */
        this.flyersTrack = this._dEl('flyersTrack');
        for (let i = 0; i < this.flyers.length; i++) {
            const flyer = this.flyers[i];
            const data = flyerData(flyer);
            const flyerEl = runTemplate(flyerCardHtml, {
                uniqueId: data.id,
                extraClasses: `${this._el('flyer')} ${i > 2 ? this._elMod('flyer', 'hidden') : ''}`,
                ...data,
                title: data.feTitle,
                variant: 1,
                imageUrl: data.image,
                downloadHref: data.pdfUrl,
                link: {
                    ...data.link,
                    'data-link-tracking': JSON.stringify(this._getTrackVolantino()),
                },
            });
            this.flyersTrack.appendChild(flyerEl);
        }
    }

    _addStoreListeners() {
        this._addStoreListener('userData', () => {
            this._checkUser();
        });
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                if (!this.fuel) {
                    this._manageSwiperMobile();
                }
            },
            window
        );
        if (this.btnPreferred) {
            this._addListener(
                'click',
                async () => {
                    try {
                        openLoader('main');
                        await this.userService.setPreferredStore(this.root.dataset.storeId);
                        this.trackingManager.track(this.root, {
                            event: 'PdvPreferito',
                            CustomLink: 'Aggiunta Pdv preferito',
                            negozioInfo: {
                                ...generateTrackNegozioInfo(),
                            },
                        });
                    } finally {
                        closeLoader('main');
                    }
                },
                this.btnPreferred
            );
        }

        /* click on show all, show all the opening hours*/
        this._addListener(
            'click',
            (e) => {
                e.preventDefault();
                const offset = window.innerWidth < this.BREAKPOINT_L ? 130 : 200;
                window.scrollBy(0, this.hours.getBoundingClientRect().top - offset);
                this.trackingManager.track(this.root, {
                    event: 'VediOrari',
                    CustomLink: 'Click Vedi orari',
                    negozioInfo: {
                        ...generateTrackNegozioInfo(),
                    },
                });
            },
            this.showAll
        );

        /* click on prev of the side slider*/
        this._addListener(
            'click',
            () => {
                this.swiper.slidePrev(200);
                this.trackingManager.track(this.root, this._getTrackSlide());
            },
            this.prevSlide
        );

        /* click on next of the side slider*/
        this._addListener(
            'click',
            () => {
                this.swiper.slideNext(200);
                this.trackingManager.track(this.root, this._getTrackSlide());
            },
            this.nextSlide
        );

        this.swiper.on('reachBeginning', () => {
            this._disableEl(this.prevSlide, 'flyersPrev');
            this._enableEl(this.nextSlide, 'flyersNext');
        });
        this.swiper.on('reachEnd', () => {
            this._disableEl(this.nextSlide, 'flyersNext');
            this._enableEl(this.prevSlide, 'flyersPrev');
        });
        this.swiper.on('progress', (swiper, progress) => {
            if (progress == 0) {
                this._disableEl(this.prevSlide, 'flyersPrev');
                return;
            }
            if (progress == 1) {
                this._disableEl(this.nextSlide, 'flyersNext');
                return;
            }
            this._enableEl(this.nextSlide, 'flyersNext');
            this._enableEl(this.prevSlide, 'flyersPrev');
        });

        if (this.loadMore) {
            this._addListener(
                'click',
                () => {
                    let step = 3;
                    let hidden = this.flyerCards.filter((flyer) => flyer.classList.contains(this.FLYER_HIDDEN));
                    if (hidden.length < step) {
                        hidden.forEach((flyer) => flyer.classList.remove(this.FLYER_HIDDEN));
                        this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
                    } else {
                        if (hidden.length == step) this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
                        while (step > 0) {
                            hidden[3 - step--].classList.remove(this.FLYER_HIDDEN);
                        }
                    }
                },
                this.loadMore
            );
        }
    }

    async _initSwiper() {
        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, {
            slidesPerView: 'auto',
            spaceBetween: 16,
            wrapperClass: this._el('flyersTrack'),
            slideClass: this.fuel ? this._el('fuelCard') : this._el('flyer'),
            navigation: {
                nextEl: this._dEl('flyersNext'),
                prevEl: this._dEl('flyersPrev'),
            },
        });
    }

    async _checkUser() {
        if (!this.btnPreferred) return;

        let user = await this.userService.getUser();
        if (user) {
            if (this.btnPreferred.classList.contains(this._elMod('preferred', 'hide')))
                this.btnPreferred.classList.remove(this._elMod('preferred', 'hide'));

            const store = await this.userService.getStore();
            if (store && store.anacanId == this.root.dataset.storeId && this.btnPreferred) {
                const ctaPreferred = this.btnPreferred.querySelector('.ec-icon-like-off');
                if (ctaPreferred) {
                    ctaPreferred.classList.remove('ec-icon-like-off');
                    ctaPreferred.classList.add('ec-icon-like-on');
                }
            }
        } else if (!this.btnPreferred.classList.contains(this._elMod('preferred', 'hide'))) {
            this.btnPreferred.classList.add(this._elMod('preferred', 'hide'));
        }
    }

    _manageSwiperMobile() {
        if (window.innerWidth < this.BREAKPOINT_L) {
            if (this.flyerCards && this.flyerCards.length > 0) {
                this.swiper.slideTo(0);
            }
            this.swiper.disable();
        } else {
            this.swiper.enable();
        }
    }

    _disableEl(el, elName) {
        if (!el || !elName) return;
        if (!el.classList.contains(this._elMod(elName, 'disabled'))) el.classList.add(this._elMod(elName, 'disabled'));
    }
    _enableEl(el, elName) {
        if (!el || !elName) return;
        el.classList.remove(this._elMod(elName, 'disabled'));
    }

    _trackPageView() {
        const track = {
            event: 'pageView',
            funnel: {
                nomeFunnel: 'Scheda Negozio',
                stepFunnel: 'Esplorazione Scheda Negozio',
            },
            negozioInfo: {
                ...generateTrackNegozioInfo(null, true),
            },
        };

        this.trackingManager.track(this.root, track);
    }

    _getTrackVolantino() {
        return {
            event: 'data.flyerEvent||VaiAlVolantino',
            CustomLink: 'Click Vai al volantino',
            negozioInfo: {
                ...generateTrackNegozioInfo(),
            },
            volantinoInfo: {
                ...generateTrackVolantinoInfo(),
            },
        };
    }

    _getTrackSlide() {
        return {
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
            funnel: {
                stepFunnel: 'Slide laterale Card Volantino',
            },
            negozioInfo: {
                ...generateTrackNegozioInfo(),
            },
        };
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt057-store-header');
}
