import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';

export default class CategoryFilterCard extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.input.checked ? this.select(false) : this.unselect(false);
        
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    /* override */
    getValue() {
        return this.input.checked ? this.input.value : null;
    }

    /* override */
    getLabel() {
        return this.root.dataset.label;
    }

    _addEventListeners() {
        this._addListener(
            'change',
            (event) => {
                event.preventDefault();
                this._checkState();
                this._changedInput();
            },
            this.input
        );
    }

    isSelected() {
        return this.input.checked;
    }

    select(sendChangedInputEvent = true) {
        this.input.checked = true;
        this._checkState();
        if (!sendChangedInputEvent) return;
        this._changedInput();
    }

    unselect(sendChangedInputEvent = true) {
        this.input.checked = false;
        this._checkState();
        if (!sendChangedInputEvent) return;
        this._changedInput();
    }

    setDisabled(disabled = true) {
        this.input.checked = false;
        disabled ? this.root.classList.add(this.DISABLED) : this.root.classList.remove(this.DISABLED);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt038-category-filter');
}
