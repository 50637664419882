import { getApiProvider } from '../../../../../libs/api-provider';
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { closeLoader, htmlToElement, openLoader } from '../../../../../libs/utils';

export default class MapSearchStoresForm extends Component {
    constructor(name, root) {
        super(name, root);
        this.apiProvider = getApiProvider();
        this._doLogic();
    }

    _doLogic() {
        this.register = getRegister();
        this.storeCards = null;
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.selectedAddress = null;
        this.PAGE_SIZE = this.root.dataset.pageSize;
        this.LOAD_MORE_HIDDEN = this._elMod('loadMore', 'hidden');
        this.STORE_CARD_HIDDEN = this._elMod('store', 'hidden');
        this.NO_RESULTS_HIDDEN = this._elMod('noResults', 'hidden');

        this._getElements();
        this._addEventListeners();
    }

    _getElements() {
        this.form = this._dEl('form');
        this.address = this._dEl('address');
        this.search = this._dEl('search');
        this.numResults = this._dEl('numResults');
        this.numResultsCount = this.numResults.querySelector('span');
        this.NUM_RESULTS_SHOW = this._elMod('numResults', 'show');
        this.results = this._dEl('results');
        this.loadMore = this._dEl('loadMore');
        this.noResults = this._dEl('noResults');
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            (event) => {
                if (!event.data.value || !event.data.value.geometry || !event.data.value.types) {
                    this.selectedAddress = null;
                    return;
                }
                this.selectedAddress = event.data.value;
            },
            this.address
        );
        this._addListener(
            'click',
            async (event) => {
                event.preventDefault();
                openLoader('main');
                const addr = this.selectedAddress;
                if (addr == null) {
                    this._requireSelection(this.address);
                } else {
                    await this._loadResults(addr);
                }
                closeLoader('main');
            },
            this.search
        );
        this._addListener(
            'click',
            () => {
                this.currentPage++;
                this.renderResults(this.currentPage);
                this._checkLoadMore();
            },
            this.loadMore
        );
    }

    _requireSelection(address) {
        const obj = this.register.getClass(address);
        obj?.setState('error');
        obj?._requireField();
    }

    renderResults(pageIndex = 1) {
        if (!this.numPages || this.numPages <= 0) {
            console.warn('Missing or invalid numPages');
            return;
        }
        if (pageIndex == 1) {
            const hiddenStores = this.storeCards.slice(this.PAGE_SIZE);
            for (const card of hiddenStores) {
                card.classList.add(this.STORE_CARD_HIDDEN);
            }
        } else {
            const start = (Math.min(pageIndex, this.numPages) - 1) * this.PAGE_SIZE;
            const end = start + this.PAGE_SIZE;
            const page = this.storeCards.slice(start, end);
            for (const card of page) {
                card.classList.remove(this.STORE_CARD_HIDDEN);
            }
        }
    }

    _checkLoadMore() {
        if (this.currentPage >= this.numPages) {
            this.loadMore.classList.add(this.LOAD_MORE_HIDDEN);
        } else {
            this.loadMore.classList.remove(this.LOAD_MORE_HIDDEN);
        }
    }

    async _loadResultsSection(data) {
        const url = this.loaderEndpoint;
        const params = {
            ...data,
            __eck: 'nocache',
        };
        try {
            const html = await this.apiProvider.loaderGet(url, params);
            this.results?.remove();
            const element = htmlToElement(html);
            if (element == null) {
                this.noResults.classList.remove(this.NO_RESULTS_HIDDEN);
                this.numResults.classList.remove(this.NUM_RESULTS_SHOW);
                return;
            }
            this.numResults.after(element);

            this.results = this._dEl('results');
            this.storeCards = this._dEl('store', true);
            this.noResults.classList.add(this.NO_RESULTS_HIDDEN);
            this.numResults.classList.add(this.NUM_RESULTS_SHOW);
            this.setNumResults(this.storeCards.length);
            this.numPages = Math.ceil(this.storeCards.length / this.PAGE_SIZE);
            this.currentPage = 1;
            this.renderResults(this.currentPage);
            this._checkLoadMore();
        } catch (error) {
            console.error(error);
        }
    }

    async _loadResults(address) {
        if (!address || !address.geometry || !address.types) return;

        const data = {
            latitudine: `${address.geometry.location.lat()}`,
            longitudine: `${address.geometry.location.lng()}`,
            raggioRicerca: this._getSearchRadius(address),
        };

        await this._loadResultsSection(data);
    }

    _getSearchRadius(address) {
        for (const type of address.types) {
            if (type == 'postal_code' || type == 'street_address' || type == 'route') return '5';
        }
        return '15';
    }

    setNumResults(num = 0) {
        if (!num || num <= 0) {
            this.numResultsCount.innerText = '0';
            this.numResults.classList.remove(this.NUM_RESULTS_SHOW);
            return;
        }
        this.numResultsCount.innerText = `${num}`;
        this.numResults.classList.add(this.NUM_RESULTS_SHOW);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt149-search-stores');
}
