import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getRegister } from '../../../../../libs/register';
import { openLoader } from '../../../../../libs/utils';

export default class MenuMob extends Component {
    constructor(name, root) {
        super(name, root);

        this.menus = this._dEl('menu', true);
        this.submenus = this._dEl('submenu', true);
        this.menuItems = this._dElMod('menuItem', 'submenu', true);
        this.form = this._dEl('search');
        this.searchInput = this._dEl('searchInput');
        this.searchResultsPath = this.root.dataset.searchResultsPath || console.warn('Missing search results path');
        const submenuColumnItems = this.root.querySelectorAll('.rt056-menu-mob__columnItems');
        this.submenuItems = [];
        submenuColumnItems.forEach((el) => {
            Array.from(el.children).forEach((subEl) => {
                this.submenuItems.push(subEl);
            });
        });

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        for (const menuItem of this.menuItems) {
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    const current = event.target.closest(this._el('menu', true)).dataset.name;
                    this._showMenu(event.target.dataset.menu, current);
                },
                menuItem
            );
        }

        for (const submenuItem of this.submenuItems) {
            this._addListener(
                'click',
                (event) => {
                    // if it has not child elements, than on click it goes directly into the page linked, otherwise,
                    // open third level submenu
                    if (submenuItem.dataset.otherSubmenu === 'true') {
                        event.preventDefault();
                        const current = event.target.closest(this._el('menuItem', true)).dataset.itemRef;
                        this._showSubmenu(current);
                    }
                },
                submenuItem
            );
        }

        this._addListener('click', (event) => {
            if (event.target.matches(this._el('close', true))) this._emit('menuMobStatus', false);
        });

        this._addListener('click', (event) => {
            if (!event.target.matches(this._el('back', true))) return;
            event.target.closest(this._el('menu', true)).classList.remove('active');
        });

        this._addListener('click', (event) => {
            if (!event.target.matches(this._el('backSubmenu', true))) return;
            event.target.closest(this._el('submenu', true)).classList.remove('active');
        });

        this._addListener(
            'resize',
            () => {
                if (window.innerWidth >= BREAKPOINTS.l && this.root.classList.contains(this._mod('open'))) {
                    this._emit('menuMobStatus', false);
                }
            },
            window
        );

        this._addListener(
            'rt10SearchQuery',
            (e) => {
                if (!e.data.value || !this.searchResultsPath) return;
                const url = new URL(this.searchResultsPath);
                url.searchParams.set('query', e.data.value);
                openLoader('main');
                window.location = url.href;
            },
            this.searchInput
        );

        this._addListener(
            'submit',
            (e) => {
                e.preventDefault();
            },
            this.form
        );
    }

    _addStoreListeners() {
        this._addStoreListener('menuMobStatus', (path, data) => {
            if (data) {
                this._resetView();
                this.root.classList.add(this._mod('open'));
                this._emitIncrement('blockScroll');
            } else {
                this.root.classList.remove(this._mod('open'));
                this._emitDecrement('blockScroll');
            }
        });

        this._addStoreListener('currentPage', (path, data) => {
            const items = this._dEl('menuItem', true);
            for (const item of items) {
                if (item.matches(`[data-item-path='${data.path}']`)) item.classList.add('selected');
                else if (item.classList.contains('selected')) item.classList.remove('selected');
            }
        });
    }

    _resetView() {
        for (const tpMenu of this.menus) {
            tpMenu.classList.remove('active');
        }
    }

    _showMenu(id) {
        let menu = null;
        for (const tpMenu of this.menus) {
            if (tpMenu.dataset.name == id) {
                menu = tpMenu;
                break;
            }
        }

        if (menu == null) return;
        menu.classList.add('active');
    }

    _showSubmenu(id) {
        let menu = null;
        for (const tpMenu of this.submenus) {
            if (tpMenu.dataset.name == id) {
                menu = tpMenu;
                break;
            }
        }

        if (menu == null) return;
        menu.classList.add('active');
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt056-menu-mob');
}
