import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';

export default class Footer extends Component {
    constructor(name, root) {
        super(name, root);

        this.links = this._dEl('links');
        this.privacyPolicyLink = document.getElementById('privacy-policy-link');
        this._addEventListeners();
        this._getUser();
    }

    async _getUser() {
        if (!this.privacyPolicyLink) return;

        let coop = 'cnd';
        this.user = await getUserService().getUser();
        if (this.user) coop = this.user?.newMappedCooperative ? this.user.newMappedCooperative : 'cnd';
        const url = new URL(this.privacyPolicyLink.href, window.origin);
        url.searchParams.set('coop', coop?.toLowerCase());
        this.privacyPolicyLink.setAttribute('href', url.href);
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (e) => {
                const container = e.target.closest(this._el('linkContainer', true));
                if (!container) return;

                if (container.classList.contains(this._elMod('linkContainer', 'open'))) {
                    container.classList.remove(this._elMod('linkContainer', 'open'));
                } else {
                    container.classList.add(this._elMod('linkContainer', 'open'));
                }
            },
            this.links
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt050-footer');
}
