import './style.scss';
import Component from '../../../../../libs/components/component';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getStoreManager } from '../../../../../libs/store-manager';
import { storeData } from '../../../../../libs/pdv-utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { BREAKPOINTS } from '../../../../../libs/constants';

export default class MapPin extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.gMapsHelper = getGMapsHelper();
        this.register = getRegister();
        this.storeManager = getStoreManager();
        this.trackingManager = getTrackingManager();

        this.runTemplate = runTemplate;
        this.SELECTED = this._mod('selected');
        this.AGGREGATOR = this._mod('aggregator');
        this.MIXED = this._mod('mixed');
        this.MOBILE = window.innerWidth < BREAKPOINTS.l;
        this.PAGE_SELECTOR = '.rs1-page';

        this.mixed = false;
        this.id = this.root.id;
        this.pinId = this.root.dataset.pinId;
        this.pin = this._dEl('pin');
        this.map = null;
        this.position = null;
        this.mapPinObj = null;
        this.fav = false;

        this.pois = [];
        this.infoWindows = [];
        this.infoWindowElements = [];

        this._addMapListeners();
    }

    async setMapParams(map, position, mapPinObj, poi = null, simplified = false) {
        this.map = map;
        this.position = position;
        this.mapPinObj = mapPinObj;
        if (!poi) return;

        this.pois = [poi];
        this.simplified = simplified;
        await this._initInfoWindow();
    }

    _addMapListeners() {
        /* handle click on map markers pin */
        this._addListener(
            'click',
            () => {
                if (!this.mapPinObj?.getVisible()) return;
                /* aggregator case */
                if (this.isAggregator()) {
                    this.trackingManager.track(this.selectedResItem, {
                        event: 'PinMappa',
                        CustomLink: 'Click Pin Mappa',
                        negozioInfo: {
                            indirizzoRicerca: 'vars.currentSearchAddress',
                            PinMappa: 'Pin Aggregatore',
                            idNegozio: '',
                            CooperativaNegozio: '',
                            tipologiaRisultati: 'Mappa',
                        },
                    });
                    /* emit clicked pin */
                    const clickedAggregator = new CustomEvent('clickedAggregator', { bubbles: true });
                    clickedAggregator.data = { position: this.getPosition() };
                    this.root.dispatchEvent(clickedAggregator);
                } else {
                    //tracking map pin
                    this.trackingManager.track(this.selectedResItem, {
                        event: 'PinMappa',
                        CustomLink: 'Click Pin Mappa',
                        negozioInfo: {
                            indirizzoRicerca: 'vars.currentSearchAddress',
                            PinMappa: this.getPinType(),
                            idNegozio: this.pois[0].anacanId,
                            CooperativaNegozio: this.pois[0].codiceCooperativa,
                            tipologiaRisultati: 'Mappa',
                        },
                    });
                    /* emit clicked pin */
                    const clickedStore = new CustomEvent('clickedStore', { bubbles: true });
                    clickedStore.data = { pinId: this.pinId };
                    this.root.dispatchEvent(clickedStore);
                }
            },
            this.pin
        );
    }

    addMixed(poi) {
        this.root.classList.add(this._mod('mixed'));
        this.pois.push(poi);
        this._initInfoWindow();
    }

    async _initInfoWindow() {
        const maps = await this.gMapsHelper.getGMaps();
        const mapInfoWindowCall = (await import('../rt020-map-info-window/rt020-map-info-window.html')).default;

        this.infoWindowElements = []; // reset elements
        this.infoWindows = []; // reset infoWindows
        for (let i = 0; i < this.pois.length; i++) {
            let offset = 358 * i - 175 * (this.pois.length > 1 ? 1 : 0);
            if (this.MOBILE && this.isMixed()) offset = 188 * i - 90 * (this.pois.length > 1 ? 1 : 0);

            //create info window
            this.infoWindows.push(
                new maps.InfoWindow({
                    minWidth: this.MOBILE && this.isMixed() ? 180 : 350,
                    maxWidth: this.MOBILE && this.isMixed() ? 180 : 350,
                    pixelOffset: new maps.Size(8 + offset, -4, 'px', 'px'),
                    position: this.position,
                })
            );
        }

        for (const poi of this.pois) {
            // info window data
            const infoWindowData = {
                simplified: this.simplified,
                pinId: this.pinId,
                mixed: this.isMixed(),
                ...storeData(poi, `${this.id}-info-window`),
            };

            this.infoWindowElements.push(this.runTemplate(mapInfoWindowCall, infoWindowData));
        }
    }

    getPosition() {
        return this.position;
    }

    isSelected() {
        return this.root.classList.contains(this.SELECTED);
    }

    isAggregator() {
        return this.root.classList.contains(this.AGGREGATOR);
    }

    isMixed() {
        return this.root.classList.contains(this.MIXED);
    }

    setSelected(select = true, infoWindowOptions = this.DEFAULT_INFO_WINDOW_OPTIONS) {
        if (select) {
            this.root.classList.add(this.SELECTED);
            for (let i = 0; i < this.infoWindows.length; i++) {
                const infoWindow = this.infoWindows[i];
                if (infoWindowOptions) infoWindow.setOptions(infoWindowOptions);
                infoWindow.setContent(this.infoWindowElements[i]);
                infoWindow.open(this.map, this.mapPinObj);
            }
        } else {
            this.root.classList.remove(this.SELECTED);
            for (let i = 0; i < this.infoWindows.length; i++) {
                const infoWindow = this.infoWindows[i];
                infoWindow.close();
                infoWindow.setOptions(this.DEFAULT_INFO_WINDOW_OPTIONS);
            }
        }
    }

    resetPins() {
        const page = document.querySelector(this.PAGE_SELECTOR);
        const pins = Array.from(page.querySelectorAll(this.getSelector()));
        for (const pin of pins) {
            const pinObj = this.register.getClass(pin);
            if (pinObj.isAggregator()) continue;
            if (pinObj.id !== this.id) pinObj.setSelected(false);
        }
    }

    getPinType() {
        if (this.root.classList.contains(this._mod('fuel'))) return 'Pin Carburante';
        if (this.root.classList.contains(this._mod('margherita'))) return 'Pin Store';
        if (this.root.classList.contains(this._mod('eye'))) return 'Pin Ottico';
        if (this.root.classList.contains(this._mod('pharmacy'))) return 'Pin Parafarmacia';
        if (this.root.classList.contains(this._mod('pet'))) return 'Pin PetStore';
        if (this.root.classList.contains(this._mod('mixed'))) return 'Pin Mixed';
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt016-map-pin');
}
