import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';



export default class StatusDot extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.STATUS = ['success', 'error', 'warning', 'info'];
    }

    setStatus(status) {
        if (!this.STATUS.includes(status)) return;
        this.root.dataset.status = status;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt019-status-dot');
}
