import { getApiProvider } from './api-provider';
import { getMyConadApiProvider } from './myconad-api-provider';
import { TIME_IN_MILLIS } from './constants';
import { getStoreManager } from './store-manager';
import { buildWaitForEvent, getCookieByName, isReferrerCurrentDomain, writeCookieWithName, nextTick } from './utils';

export class UserService {
    constructor() {
        this.reg = [];
        this.storeManager = getStoreManager();
        this.apiProvider = getApiProvider();
        this.myConadApiProvider = getMyConadApiProvider();
        this.userLoadedReady = buildWaitForEvent('userLoadedReady');
        this.userLoaded = 0;

        this.complementaryDataReady = buildWaitForEvent('complementaryDataReady');
        this.complementaryDataLoaded = 0;

        this.disFavoriteProductsLoaded = {};
    }

    /**
     *
     * @returns The user if is logged, null otherwise
     */
    async getUser() {
        switch (this.userLoaded) {
            case 0:
                await this._loadUser();
                return this._getUser();
            case 1:
                await this.userLoadedReady(document.body);
                return this._getUser();
            case 2:
                return this._getUser();
            default:
                console.error('Could not retrieve user');
                break;
        }
        return null;
    }

    /**
     *
     * @returns The preferred store if user is logged, the last visited store if user is anonymous or null
     */
    async getStore() {
        await this.getUser(); // wait user loading
        return this._getStore(this.storeManager.get('userData'));
    }

    /**
     *
     * @returns The saved stores if user is logged, an empty array if not
     */
    async getSavedStores() {
        await this.getUser(); // wait user loading
        return await this._getSavedStores(this.storeManager.get('userData'));
    }

    /**
     * Check if a content is in complementary data of the user, if true returns an object with id
     * @param {*} path
     * @p
     */
    async checkComplementaryData(path) {
        const complementaryData = await this.getComplementaryData();
        return complementaryData[path];
    }

    /**
     * The complementary data for the user, used in recipes and advices to mantain preferred recipes
     * @returns
     */
    async getComplementaryData() {
        const user = await this.getUser();
        if (user == null) return {};

        switch (this.complementaryDataLoaded) {
            case 0:
                return await this._loadComplementaryData();
            case 1:
                await this.complementaryDataReady(document.body);
                return this.storeManager.get('userComplementaryData');
            case 2:
                return this.storeManager.get('userComplementaryData');
            default:
                console.error('Could not retrieve user complementary data');
                break;
        }
        return {};
    }

    /**
     * Add complementary data by path
     * @param {*} param0
     * @returns true on success
     */
    async addComplementaryData(path) {
        try {
            const data = await this.myConadApiProvider.associateComplementaryData(path);
            if (data) {
                const cd = this.storeManager.get('userComplementaryData');
                cd[path] = data.id;
                this.storeManager.emit('userComplementaryData', cd, true);
                // invalidate fe cache
                window.localStorage?.removeItem('user-cdata-fe-cache');
                return true;
            }
        } catch (error) {
            console.error(`Could not add complementary data for path ${path}`);
        }
        return false;
    }

    /**
     * Remove complementary data by path.
     * @param {*} param0
     * @returns true if success
     */
    async removeComplementaryData(path) {
        try {
            const id = await this.checkComplementaryData(path);
            if (id) {
                await this.myConadApiProvider.removeComplementaryData(id);
                const cd = this.storeManager.get('userComplementaryData');
                delete cd[path];
                this.storeManager.emit('userComplementaryData', cd);
                // invalidate fe cache
                window.localStorage?.removeItem('user-cdata-fe-cache');
                return true;
            }
        } catch (error) {
            console.error(`Could not remove complementary data with path: ${path}`);
        }
        return false;
    }

    /**
     * Check if a content is in complementary data of the user, if true returns an object with id
     * @param {*} path
     * @p
     */
    async checkDisFavoriteProduct(anacanId, disaggregatedId, productId) {
        const disFavorites = await this.getDisFavoriteProduct(anacanId);
        if (!disFavorites) return null;
        const items = disFavorites.items;
        if (!disFavorites.items) return null;
        for (const disFav of items) {
            if (disFav.anacanId == anacanId && disFav.lsfid == productId) return disFav.idProdottoNegozioUtente;
        }
        return null;
    }

    /**
     * The complementary data for the user, used in recipes and advices to mantain preferred recipes
     * @returns
     */
    async getDisFavoriteProduct(anacanId) {
        const user = await this.getUser();
        if (user == null) return {};

        switch (this.disFavoriteProductsLoaded[anacanId]) {
            case 1: {
                const disFavoriteProductsReady = buildWaitForEvent(`disFavoriteProductsReady-${anacanId}`);
                await disFavoriteProductsReady(document.body);
                return this.storeManager.get(`userDisFavoriteProducts-${anacanId}`);
            }
            case 2: {
                return this.storeManager.get(`userDisFavoriteProducts-${anacanId}`);
            }
            default:
                return await this._loadDisFavoritesProducts(anacanId);
        }
    }

    /**
     * Add favorite product
     * @param {*} product
     * @returns true on success
     */
    async addDisFavoriteProduct(anacanId, disaggregatedId, productId) {
        try {
            await this.myConadApiProvider.addDisFavoriteProduct(anacanId, disaggregatedId, productId);
            await this.reloadDisFavoriteProduct(anacanId);
            return true;
        } catch (error) {
            console.error(`Could not set dis favorites for current user`, error);
            return false;
        }
    }

    /**
     * Remove dis favorite product using idProdottoNegozioUtente
     * @param {*} idProdottoNegozioUtente
     * @returns true if success
     */
    async removeDisFavoriteProduct(anacanId, idProdottoNegozioUtente) {
        try {
            await this.myConadApiProvider.removeDisProduct(idProdottoNegozioUtente);
            await this.reloadDisFavoriteProduct(anacanId);
            return true;
        } catch (error) {
            console.error(`Could not set dis favorites for current user`, error);
            return false;
        }
    }

    /**
     * Set the preferred store for the user
     * @param {*} anacanId
     */
    async setPreferredStore(anacanId) {
        try {
            await this.myConadApiProvider.setPreferredStore({ anacanId });
            await this.reloadUser();
            return true;
        } catch (error) {
            console.error(`Could not set preferred store for current user`, error);
            return false;
        }
    }

    /**
     * Add the saved  store for the user
     * @param {*} anacanId
     */
    async addSavedStore(anacanId) {
        try {
            const res = await this.myConadApiProvider.setSavedStore(anacanId);
            await this.reloadUser();
            return res?.anacanId;
        } catch (error) {
            console.error(`Could not add ${anacanId} as saved store for current user`, error);
            return false;
        }
    }

    /**
     * Removed the saved store for the user
     * @param {*} anacanId
     */
    async removeSavedStore(anacanId) {
        try {
            await this.myConadApiProvider.removeSavedStore(anacanId);
            await this.reloadUser();
            return true;
        } catch (error) {
            console.error(`Could not remove ${anacanId} form saved store list for current user`, error);
            return false;
        }
    }

    /**
     * Set the last visited shop
     * @param {*} anacanId
     */
    async setLastVisitedStore(anacanId) {
        try {
            const store = await this.apiProvider.getPointOfServiceByAnacanId({ anacanId });
            store.syncDate = Date.now();
            window.localStorage.setItem('lastShop', JSON.stringify(store));
            return store;
        } catch (err) {
            console.warn(`store ${anacanId} not found`);
            window.localStorage.removeItem('lastShop');
        }
        return null;
    }

    resetUser() {
        this.userLoaded = 0;
        window.sessionStorage?.removeItem('user-info-fe-cache');
    }

    async reloadUser() {
        this.resetUser();
        await this._loadUser();
    }

    async reloadDisFavoriteProduct(anacanId) {
        this.disFavoriteProductsLoaded[anacanId] = 0;
        window.localStorage?.removeItem(`user-disfav-fe-cache-${anacanId}`);
        await this._loadDisFavoritesProducts(anacanId);
    }

    /////////////////////////////// INTERNAL

    _getUser() {
        const userData = this.storeManager.get('userData');
        if (userData && userData.feType == 'anonymous') return null;
        return userData.user;
    }

    _getStore(userData) {
        if (userData && userData.feType == 'anonymous') {
            //get store from local storage
            let store = null;
            const storeJson = window.localStorage.getItem('lastShop');
            if (!storeJson) return null;

            store = JSON.parse(storeJson);
            if (!store) return null;

            //check if store was sync in last 24h
            if (!store.syncDate || !this._isToday(new Date(store.syncDate))) {
                store = this.setLastVisitedStore(store.anacanId);
                if (!store) return null;
            }

            store.lastVisited = true;
            return store;
        } else {
            return userData.negozioPreferitoObj;
        }
    }

    _getSavedStores(userData) {
        const savedStore = userData && userData.feType == 'anonymous' ? [] : userData.negoziDiInteresse;
        return savedStore ? savedStore : [];
    }

    async _loadUser() {
        this.userLoaded = 1;

        let user;
        const loc = window.sessionStorage?.getItem('user-info-fe-cache');
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (
                deserialized &&
                deserialized.update > Date.now() - TIME_IN_MILLIS.THIRTY_SECONDS &&
                isReferrerCurrentDomain()
            ) {
                user = deserialized.data;
                await nextTick();
            }
        }

        if (!user) {
            // fix strange app cookies
            const tokenId = getCookieByName('ssoTokenId');
            const appTokenFixed = getCookieByName('appTokenFixed');
            if (tokenId && !appTokenFixed) {
                //read domain
                let domainParts = location.hostname.split('.');
                let domain = location.hostname;
                if (domainParts.length >= 2) {
                    domain = '.' + domainParts.slice(-2).join('.');
                }
                writeCookieWithName('ssoTokenId', tokenId, domain);
                writeCookieWithName('appTokenFixed', 'true', domain);
                console.log('Rewritten TOKEN for APP');
            }

            try {
                user = await this.myConadApiProvider.user();
                window.sessionStorage?.setItem(
                    'user-info-fe-cache',
                    JSON.stringify({ update: Date.now(), data: user })
                );
            } catch (e) {
                console.warn(e);
            }
        }

        //wait store manager event
        this.storeManager.addListener('userData', () => {
            this.userLoaded = 2;
            document.body.dispatchEvent(new CustomEvent('userLoadedReady'));
        });

        //userData
        let userData = { feType: 'anonymous' };
        if (user && user != 'anonymous') {
            userData = { ...user, feType: 'logged' };
        }

        this.storeManager.emit('userData', userData);
        return userData;
    }

    async _loadComplementaryData() {
        this.complementaryDataLoaded = 1;

        let complementaryData;
        const loc = window.localStorage?.getItem('user-cdata-fe-cache');
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (
                deserialized &&
                deserialized.update > Date.now() - TIME_IN_MILLIS.FIVE_MINUTES &&
                isReferrerCurrentDomain()
            ) {
                complementaryData = deserialized.data;
            }
        }

        if (!complementaryData) {
            try {
                complementaryData = await this.myConadApiProvider.getComplementaryData();

                window.localStorage?.setItem(
                    'user-cdata-fe-cache',
                    JSON.stringify({ update: Date.now(), data: complementaryData })
                );
            } catch (e) {
                console.warn(e);
                complementaryData = {};
            }
        }

        //wait store manager event
        this.storeManager.addListener('userComplementaryData', () => {
            this.complementaryDataLoaded = 2;
            document.body.dispatchEvent(new CustomEvent('complementaryDataReady'));
        });

        this.storeManager.emit('userComplementaryData', complementaryData);
        return complementaryData;
    }

    async _loadDisFavoritesProducts(anacanId) {
        this.disFavoriteProductsLoaded[anacanId] = 1;

        let disFavoriteProducts;
        const loc = window.localStorage?.getItem(`user-disfav-fe-cache-${anacanId}`);
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (
                deserialized &&
                deserialized.update > Date.now() - TIME_IN_MILLIS.FIVE_MINUTES &&
                isReferrerCurrentDomain()
            ) {
                disFavoriteProducts = deserialized.data;
            }
        }

        if (!disFavoriteProducts) {
            try {
                disFavoriteProducts = await this.myConadApiProvider.getDisProduct(anacanId);

                window.localStorage?.setItem(
                    `user-disfav-fe-cache-${anacanId}`,
                    JSON.stringify({ update: Date.now(), data: disFavoriteProducts })
                );
            } catch (e) {
                console.warn(e);
                disFavoriteProducts = {};
            }
        }

        //wait store manager event
        this.storeManager.addListener(`userDisFavoriteProducts-${anacanId}`, () => {
            this.disFavoriteProductsLoaded[anacanId] = 2;
            document.body.dispatchEvent(new CustomEvent(`disFavoriteProductsReady-${anacanId}`));
        });

        this.storeManager.emit(`userDisFavoriteProducts-${anacanId}`, disFavoriteProducts);
        return disFavoriteProducts;
    }

    _isToday(date) {
        const today = new Date();
        if (today.toDateString() === date.toDateString()) {
            return true;
        }
        return false;
    }
}

/**
 * Get the current UserService
 * @returns {UserService}
 */
export const getUserService = () => {
    if (!window.rcUserService) {
        window.rcUserService = new UserService();
    }
    return window.rcUserService;
};
