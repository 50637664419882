import Component from '../../../../../libs/components/component';
import {getRegister} from '../../../../../libs/register';
import { getInsurancesManager } from '../../../../../libs/insurances-manager';
import './style.scss';
import { getDictionary } from '../../../../../libs/dictionary-provider';


export default class StrilloIconTextLinkStructure extends Component {
    constructor(name, root) {

        super(name, root);

        this.insurancesManager = getInsurancesManager();

        this.wrapper = this._dEl('wrapper');


        this.icons = { guest: this._dEl('iconGuest'),
                       light: this._dEl('iconLight'),
                       logged: this._dEl('iconLogged'),
                       policyAlreadyActive: this._dEl('iconPolicyAlreadyActive') };



        this.ctaLabels = { guest: this.root.dataset.ctaLabelGuest,
                           light: this.root.dataset.ctaLabelLight,
                           logged: this.root.dataset.ctaLabelLogged,
                           policyAlreadyActive: this.root.dataset.ctaLabelPolicyAlreadyActive };


        this.titleAndTexts = { guest: this._dEl('titleAndTextGuest'),
                               light: this._dEl('titleAndTextLight'),
                               logged: this._dEl('titleAndTextLogged'),
                               policyAlreadyActive: this._dEl('titleAndTextPolicyAlreadyActive') };

        this.cta = this._dEl('link');

        this._doLogic();
    }

    async _doLogic() {
        let link = await this.insurancesManager.getLink();
        // in this case, those two status are equivalent
        if (link.status === 'noReceipts') {
            link.status = 'logged';
        }

        this.icons[link.status]?.classList.remove('!hidden');
        this.titleAndTexts[link.status]?.classList.remove('hidden');

        const label = getDictionary().get(this.ctaLabels[link.status] || link.label);
        const ctaLabelContent = this.cta?.querySelector('.rt002-cta__label');
        if (ctaLabelContent) {
            ctaLabelContent.innerHTML = label;
        }
    }

}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt159-strillo-icon-text-link');
}
