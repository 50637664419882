import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';

export default class ItalyNav extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        this.L = await getExtApiHelper().getLeaflet();
        this._getElements();
        this._makeLeafletIcons();
        this._initMap();
        await this._setPins();
    }

    _getElements() {
        this.mapPath = this.root.dataset.mapPath;
        if (!this.mapPath) {
            console.warn('Missing map path, cannot render map');
            return;
        }
        this.mapEl = this._dEl('map');
        this.mapBgColor = this.root.dataset.mapBgColor;
        if (this.mapBgColor) {
            this.mapEl.style.backgroundColor = `${this.mapBgColor}`;
        }
        try {
            this.pinConf = JSON.parse(this.root.dataset?.pinConf);
        } catch (error) {
            console.warn(error);
        }
        this.pinNotSelectedImg = this.root.dataset.pinNotSelected;
        this.pinSelectedImg = this.root.dataset.pinSelected;
    }

    _makeLeafletIcons() {
        this.pinNotSelected = this.L.icon({
            iconUrl: this.pinNotSelectedImg,
            iconSize: [36, 55],
            iconAnchor: [18, 55],
        });
        this.pinSelected = this.L.icon({
            iconUrl: this.pinSelectedImg,
            iconSize: [43, 67],
            iconAnchor: [21, 67],
        });
    }

    _initMap() {
        const maxBounds = [
            [0, 0],
            [2500, 4266],
        ];
        this.map = this.L.map(this.mapEl, {
            crs: this.L.CRS.Simple,
            maxBounds: maxBounds,
            minZoom: -2,
        });
        const bounds = [
            [0, 0],
            [2500, 4266],
        ];
        this.L.imageOverlay(this.mapPath, bounds).addTo(this.map);
        this.map.fitBounds(bounds);
    }

    async _setPins() {
        const popupHtml = (await import('./partials/leaflet-popup.html')).default;

        for (const pin of this.pinConf) {
            const popupData = {
                pinId: pin.name,
                title: pin.title,
                text: pin.text,
            };
            const popupEl = runTemplate(popupHtml, popupData);
            const markerOptions = {
                icon: this.pinNotSelected,
                title: pin.title,
            };
            pin.marker = this.L.marker([pin.x, pin.y], markerOptions);
            const popupOptions = {
                keepInView: true,
                closeButton: false,
                className: this._el('popup'),
            };
            pin.marker.bindPopup(popupEl, popupOptions);
            pin.marker.addTo(this.map);
            pin.marker.on('click', (e) => {
                this._pinClick(e.latlng);
            });
            pin.marker.on('popupopen', () => {
                const popup = pin.marker.getPopup();
                popup.options.offset = this.L.point({ x: 0, y: 7 + popupEl.scrollHeight + 32 });
                popup.update();
            });
        }
    }

    _pinClick(pos) {
        if (this.selectedPin) {
            this.selectedPin.marker.setIcon(this.pinNotSelected);
            this.selectedPin = null;
        }
        for (const pin of this.pinConf) {
            if (pin.x == pos.lat && pin.y == pos.lng) {
                this.selectedPin = pin;
                break;
            }
        }
        if (!this.selectedPin) return;
        this.selectedPin.marker.setIcon(this.pinSelected);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt113-italy-nav');
}
