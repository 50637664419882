import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';


export default class Switch extends Component {
    constructor(name, root) {
        super(name, root);

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.root.setAttribute('data-on', 'left');
            },
            'left'
        );

        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.root.setAttribute('data-on', 'right');
            },
            'right'
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt012-switch');
}
