import './style.scss';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import ValidableComponent from '../../../../../libs/components/validable-component';

export default class FileUpload extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        let parsingError = null;
        try {
            this.maxNumFiles = JSON.parse(this.root.dataset.maxNumFiles);
            this.maxFileSize = JSON.parse(this.root.dataset.maxFileSize);
        } catch (error) {
            parsingError = true;
        }
        if (parsingError) {
            console.error('Could not parse max num files and/or max file size');
            return;
        }
        this.formattedMaxSize = this._formattedFileSize(this.maxFileSize);
        this._initText();

        this.input = this._dEl('input');
        this.accept = this.input.accept ? this.input.accept.split(',') : [];
        this.dropzone = this._dEl('label');
        this.files = null;
        this.filesMap = {};
        this.filesUploaded = null;
        this.preview = this._dEl('preview');

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    getName() {
        return this._getInput().name;
    }

    getLabel() {
        return this.getName();
    }

    getFiles() {
        return Array.from(this._getInput().files);
    }

    getValue() {
        return this.getFiles();
    }

    isValid() {
        if (this.getFiles().length <= 0) return !this._getInput().required;
        if (!this._validNumFiles()) return false;
        return this.getFiles()
            .map((file) => this._validFileType(file) && this._validFileSize(file))
            .reduce((x, y) => x && y);
    }

    _addEventListeners() {
        this._addListener(
            'change',
            () => {
                this._initFiles();
                this._updateFiles();
                this._checkState();
                this._changedInput();
            },
            this.input
        );
        this._addListener(
            'drop',
            (event) => {
                /* prevent default behavior (prevent file from being opened) */
                event.preventDefault();
                if (event.dataTransfer.items) {
                    /* use DataTransferItemList interface to access the file(s) */
                    this.files = Array.from(event.dataTransfer.items)
                        .filter((item) => item.kind === 'file')
                        .map((item) => item.getAsFile());
                } else {
                    /* use DataTransfer interface to access the file(s) */
                    this.files = Array.from(event.dataTransfer.files);
                }
                this.input.files = this._getDataTransfer(this.files).files;
                this._initFiles();
                this._updateFiles();
                this._checkState();
                this._changedInput();
            },
            this.dropzone
        );
        this._addListener(
            'dragover',
            (event) => {
                /* prevent default behavior (prevent file from being opened) */
                event.preventDefault();
            },
            this.dropzone
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
            },
            this.root,
            true
        );
    }

    _initFiles() {
        this.files = this.input.files;
        this.filesMap = {};
        Array.from(this.files).forEach((file) => {
            this.filesMap[file.name] = file;
        });
    }

    async _updateFiles() {
        this._resetPreview();
        await this._loadPreview();
        this._attachRemoveHandlers();
    }

    _resetPreview() {
        while (this.preview.firstChild) {
            this.preview.removeChild(this.preview.firstChild);
        }
    }

    async _loadPreview() {
        if (this.files.length === 0) return;
        for (const file of this.files) {
            let errorText = null;
            if (!this._validFileType(file)) errorText = `Formato del file non supportato.`;
            if (!errorText && !this._validFileSize(file))
                errorText = `Il file è troppo grande (${this._formattedFileSize(file.size)}). Massimo: ${
                    this.formattedMaxSize
                }.`;
            const invalid = !this._validFileType(file) || !this._validFileSize(file);
            const data = {
                name: file.name,
                size: file.size,
                formattedSize: this._formattedFileSize(file.size),
                errorText: errorText,
                invalid: invalid,
            };

            const fileUploadedCall = (await import('./partials/file-uploaded.html')).default;
            const fileUploaded = runTemplate(fileUploadedCall, data);
            this.preview.appendChild(fileUploaded);
        }
    }

    _attachRemoveHandlers() {
        this.filesUploaded = Array.from(this.preview.querySelectorAll(this._el('fileUploaded', true)));
        if (!this.filesUploaded || this.filesUploaded.length <= 0) return;
        this.filesUploaded.forEach((fu) => {
            const remove = fu.querySelector(this._el('remove', true));
            const fileName = fu.dataset.fileName;
            remove.addEventListener('click', (event) => {
                event.preventDefault();
                delete this.filesMap[fileName];
                this.files = Object.values(this.filesMap);
                this.input.files = this._getDataTransfer(this.files).files;
                this._updateFiles();
                this._checkState();
                this._changedInput();
            });
        });
    }

    _getDataTransfer(files) {
        const dataTransfer = new DataTransfer();
        for (const file of files) {
            dataTransfer.items.add(file);
        }
        return dataTransfer;
    }

    _validNumFiles() {
        return this.getFiles().length <= this.maxNumFiles;
    }

    _validFileSize(file) {
        return file.size <= this.maxFileSize;
    }

    _validFileType(file) {
        return (
            this.accept.length <= 0 ||
            this.accept.includes(file.type) ||
            this.accept.includes('.' + file.name.split('.').pop())
        );
    }

    _formattedFileSize(number) {
        if (number < 1024) {
            return `${number} B`;
        }
        if (number >= 1024 && number < 1048576) {
            return `${(number / 1024).toFixed(1)} KB`;
        }
        return `${(number / 1048576).toFixed(1)} MB`;
    }

    _initText() {
        const textEl = this._dEl('text');
        if (!textEl.innerText.includes('{{maxFileSize}}')) return;
        textEl.innerText = textEl.innerText.replace('{{maxFileSize}}', this.formattedMaxSize);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt014-file-upload');
}
