import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class EdCategories extends Component {
    constructor(name, root) {
        super(name, root);

        this.pagination = this._dEl('pagination');
        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track', false);
        this._initSwiper();
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (slideCounts == 0) return;
        if (this.pagination && slideCounts <= 6) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            centerInsufficientSlides: true,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        options['breakpoints'] = {
            768: {
                slidesPerView: 6,
            },
        };

        if (this.pagination) {
            options['pagination'] = {
                el: this._dEl('pagination', true),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
                clickable: true,
            };
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.root, options);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper.destroy(true, true);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt115-ed-categories');
}
