import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { calcLeft, calcTop } from '../../../../../libs/utils';


export default class HotspotCard extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {

        this._addListener(
            'showHotspotCard',
            (event) => {
                if (event.data.forceClose) {
                    this.root.classList.remove(this._mod('show'));
                    return;
                }
                if (!event.data.hotspot) return;
                this.hotspot = event.data.hotspot;
                if (!this.isOpen()) this._setTooltipPosition();
                this.root.classList.toggle(this._mod('show'));
                if (this.isOpen()) this.root.focus();
            },
        );

        this._addListener(
            'resize',
            () => {
                if (!this.isOpen()) return;
                this._setTooltipPosition();
            },
            window
        );

        /* click outside the card */
        this._addListener(
            'click',
            (event) => {
                if (!this.isOpen()) return;
                const target = event.target;
                if (target.closest(this.getSelector())) return;
                this.root.classList.remove(this._mod('show'));
            },
            document
        );
    }

    isOpen() {
        return this.root.classList.contains(this._mod('show'));
    }

    _setTooltipPosition() {
        if (!this.hotspot) return;
        this.root.style.top = calcTop(this.root, this.hotspot);
        this.root.style.left = calcLeft(this.root, this.hotspot);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt030-hotspot-card');
}
