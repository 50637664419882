import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class SpecializedStores extends Component {
    constructor(name, root) {
        super(name, root);

        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track', false);
        this._initSwiper();
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        if (slideCounts == 4) {
            options['breakpoints'] = {
                768: {
                    slidesPerView: 4,
                },
            };
        }

        if (slideCounts == 3) {
            options['breakpoints'] = {
                768: {
                    slidesPerView: 3,
                },
            };
        }

        if (slideCounts == 2) {
            options['breakpoints'] = {
                768: {
                    slidesPerView: 2,
                },
            };
        }

        if (slideCounts == 1) {
            options['breakpoints'] = {
                0: {
                    slidesPerView: 1,
                },
            };
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.root, options);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt126-slider-card-spec-stores');
}
