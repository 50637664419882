import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class SwitchComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this.showPdf = this.root.dataset.show != undefined;

        /*- titles */
        this.pdfTitle = this._dEl('pdfTitle');
        this.productsTitle = this._dEl('productsTitle');
        this.productsNumber = this._dEl('productsNumber');
        this.filterCount = this._dEl('count');
        this.trackingManager = getTrackingManager();

        /* tab panels */
        this.panelAContent = this._dEl('disaggregatedContent');
        this.panelBContent = this._dEl('flyerContent');
        this.panelCContent = this._dEl('bottomSection');

        /* Emit current store for favorite product complementary data */
        const urlParams = new URLSearchParams(window.location.search);
        const anacanId = urlParams.get('anacanId');
        const disaggregatedId = this.root.dataset.volDisaggregatedId;
        const cooperative = this.root.dataset.volCoop;
        this._emit('PropsCurrentFlyer', { anacanId, disaggregatedId, cooperative });

        this.cta = this._dEl('cta');

        if (this.panelAContent) {
            this.flowManager = getFlowManager();
        }

        if (this.panelAContent && this.panelBContent) {
            this.left = this.root.querySelector('.rt012-switch__left');
            this.rigth = this.root.querySelector('.rt012-switch__right');
            this.tabs = [this.left, this.rigth];
            this.tabList = this._dEl('tabs');
            this.tabPanels = [this.panelAContent, this.panelBContent, this.panelCContent];
            /* switch components*/

            this.firstTime = true;
            this.tabFocus = this.showPdf ? 1 : 0;
            const firstOpenTab = this.tabs[this.tabFocus];
            if (this.tabList) {
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelListFromTab(t), false);
                });
            }

            // Set this tab as selected
            if (firstOpenTab) {
                this._selectTab(firstOpenTab, this._getTabPanelListFromTab(firstOpenTab), true, false);
                this.switch = document.querySelector('.rt012-switch');
            }
        }

        if (this.panelBContent && !this.panelAContent) {
            if (this.productsTitle && !this.productsTitle.classList.contains(this._elMod('productsTitle', 'hidden')))
                this.productsTitle.classList.add(this._elMod('productsTitle', 'hidden'));
            this.panelBContent.classList.remove(this._elMod('panel', 'hidden'));
            if (this.panelCContent) this.panelCContent.classList.remove(this._elMod('panel', 'hidden'));
        }

        this._addEventListeners();
        this._addStoreListeners();

    }

    _addStoreListeners() {
        this._addStoreListener('updateProductsNumber', (path, nProd) => {
            this.productsNumber.textContent = nProd;
        });
        this._addStoreListener('updateFilterNumber', (path, nFilter) => {
            this.filterCount.textContent = nFilter;
        });
    }

    _addEventListeners() {
        if (this.panelAContent && this.panelBContent) {
            this.tabs?.forEach((tab) => {
                tab?.addEventListener('click', () => {
                    if (this._isTabActive(tab)) return;
                    // Remove all current selected tabs
                    const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                    selectedTabs?.forEach((t) => {
                        this._selectTab(t, this._getTabPanelListFromTab(t), false);
                    });
                    // Set this tab as selected
                    this._selectTab(tab, this._getTabPanelListFromTab(tab), true);
                });
            });

            /* switch logic */
            this.tabList?.addEventListener('keydown', (e) => {
                if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
                this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
                this.tabFocus = 1 - this.tabFocus; // toggle 1 and 0 because is a two option switch
                this.tabs[this.tabFocus].setAttribute('tabindex', '0');
                this.tabs[this.tabFocus]?.focus();
            });
        }

        if (this.panelAContent) {
            this._addListener(
                'click',
                () => {
                    this.flowManager.startFlow({
                        flowName: 'flyer-filters',
                        flowSteps: [{ name: 'flyer-filters' }],
                    });
                },
                this.cta
            );
        }
    }

    _getTabPanelListFromTab(tab) {
        return this.root.querySelectorAll(`div[aria-labelledBy*=${tab.getAttribute('aria-controls')}]`);
    }

    _selectTab(tab, tabPanelList, select = true, emitTracking = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanelList?.forEach((t) => {
                t.classList.remove(this._elMod('panel', 'hidden'));
            });
            switch (tab) {
                case this.tabs[0]: {
                    this._left(emitTracking);
                    break;
                }
                case this.tabs[1]: {
                    this._right(emitTracking);
                    if (this.firstTime) {
                        this.firstTime = false;
                        this.root.dispatchEvent(new CustomEvent('rcInitFlyerViewer', { bubbles: true }));
                    }
                    break;
                }
                default:
                    break;
            }
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanelList?.forEach((t) => {
                t.classList.add(this._elMod('panel', 'hidden'));
            });
        }
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _left(emitTracking) {
        if (this.productsTitle && this.productsTitle.classList.contains(this._elMod('productsTitle', 'hidden')))
            this.productsTitle.classList.remove(this._elMod('productsTitle', 'hidden'));

        if (this.pdfTitle && !this.pdfTitle.classList.contains(this._elMod('pdfTitle', 'hidden')))
            this.pdfTitle.classList.add(this._elMod('pdfTitle', 'hidden'));

        const cEvent = new CustomEvent('rl1flyerSwitchSpaceBottom', { bubbles: true });
        cEvent.data = {
            action: 'show',
            htmlTarget: this.root.parentNode,
        };
        this.root.dispatchEvent(cEvent);
        this.cta.classList.remove(this._elMod('cta', 'hidden'));

        if (emitTracking)
            this.trackingManager.track(this.root, {
                event: 'ListaProdotti',
                CustomLink: 'Click Lista prodotti',
                funnel: { stepFunnel: 'vars.interactions||Interazioni con pagina volantino' },
            });
    }

    _right(emitTracking) {
        if (this.pdfTitle && this.pdfTitle.classList.contains(this._elMod('pdfTitle', 'hidden')))
            this.pdfTitle.classList.remove(this._elMod('pdfTitle', 'hidden'));

        if (this.productsTitle && !this.productsTitle.classList.contains(this._elMod('productsTitle', 'hidden')))
            this.productsTitle.classList.add(this._elMod('productsTitle', 'hidden'));

        this.cta.classList.add(this._elMod('cta', 'hidden'));

        const cEvent = new CustomEvent('rl1flyerSwitchSpaceBottom', { bubbles: true });
        cEvent.data = {
            action: 'hide',
            htmlTarget: this.root.parentNode,
        };
        this.root.dispatchEvent(cEvent);

        if (emitTracking)
            this.trackingManager.track(this.root, {
                event: 'SfogliaPDF',
                CustomLink: 'Click Sfoglia PDF',
                funnel: { stepFunnel: 'vars.interactions||Interazioni con pagina volantino' },
                volantinoInfo: {
                    TipologiaVolantino: 'Sfogliatore',
                },
            });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt152-switch-component');
}
