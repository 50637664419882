
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class StrilloSpecializedStore extends Component {
    constructor(name, root) {
        super(name, root);
        this.link = this._dEl('link');
        this.appliedTheme = this.root.dataset.appliedTheme;
        if (this.appliedTheme) this._initStoreLocatorLink();
    }

    _initStoreLocatorLink() {
        if (!this.link.hasAttribute('href')) return;
        const url = new URL(this.link.getAttribute('href'), window.location.origin);
        url.searchParams.set('ff', this.appliedTheme);
        this.link.setAttribute('href', url.href);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt131-strillo-specialized-store');
}
