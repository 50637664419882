import { format, parse } from 'date-fns';
import { getApiProvider } from '../../../../../libs/api-provider';
import Component from '../../../../../libs/components/component';
import { formToJSON } from '../../../../../libs/form-to-json';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { closeLoader, generateTooltip, openLoader } from '../../../../../libs/utils';
import * as ctaHtml from '../rt002-cta/rt002-cta.html';
import './style.scss';

export default class FormPremio extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();
        this.apiProvider = getApiProvider();
        this.userService = getUserService();

        this._doLogic();
    }

    async _doLogic() {
        this._getElements();
        if (this.tooltipIcon) {
            this._initTooltip();
        }
        if (!window.wcmmodeDisabled) {
            this.form.classList.add('rt065-form-premio__form--visible');
        } else {
            openLoader('main');
            this.user = await this.userService.getUser();
            if (this.user) {
                this._populateUserForm();
                this._setContacts();
                this._addEventListeners();
                closeLoader('main');
                this.form.classList.add('rt065-form-premio__form--visible');
            } else {
                if (this.loginPageUrl) {
                    const redirectUrl = new URL(this.loginPageUrl);
                    redirectUrl.searchParams.set('cb', window.location);
                    window.location = redirectUrl.href;
                }
            }
        }
    }

    _getElements() {
        this.loginPageUrl = this.root.dataset.loginLink;
        this.privacyDataReadAll = this._dEl('readAll');
        this.form = this._dEl('form');
        this.sendCode = this._dEl('sendCode');
        this.errorCodeField = this.root.getElementsByClassName('rt034-pin-code-field__error');
        this.errorSendCodeGeneric = this._dEl('errorSendCode');
        this.incorrectPinErrorLabel = this.root.dataset.incorrectPinErrorLabel;
        this.alreadyUsedPinErrorLabel = this.root.dataset.alreadyUsedPinErrorLabel;
        this.tooltipText = this.root.dataset.tooltipText;
        this.tooltipIcon = this._dEl('tooltipIcon');
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            () => {
                this._checkSendStatus();
            },
            this.form
        );
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this._sendCode();
            },
            this.sendCode
        );
        /* show/hide tooltip if present */
        if (this.tooltipIcon) {
            this._addListener(
                ['mouseover', 'click'],
                () => {
                    this._callTooltip();
                },
                this.tooltipIcon
            );
            this._addListener(
                'mouseout',
                () => {
                    this._callTooltip(false);
                },
                this.tooltipIcon
            );
            /* click outside the tooltip icon */
            this._addListener(
                'click',
                (event) => {
                    const target = event.target;
                    if (target.closest(`${this._el('tooltipIcon', true)}`)) return;
                    this._callTooltip(false);
                },
                document
            );
        }
    }

    async _sendCode() {
        let resultSendCode;
        try {
            let data = {
                ...formToJSON(this.form, true),
            };
            data.loyaltyId = this.user.cartaFedelta;

            const birthDate = data['birthDate'];
            const mm = parse(birthDate, 'D-M-YYYY', new Date());
            data.birthDate = format(mm, 'YYYY-MM-DD HH:mm');

            openLoader('main');
            resultSendCode = await this.apiProvider.sendPinCode(data);

            if (
                resultSendCode.errorCode &&
                resultSendCode.errorCode == '0' &&
                resultSendCode.errorDescription == 'Code burned'
            ) {
                if (
                    this.errorCodeField[0]
                        .closest('.rt034-pin-code-field')
                        .classList.contains('rt034-pin-code-field--errorState')
                ) {
                    this.errorCodeField[0]
                        .closest('.rt034-pin-code-field')
                        .classList.remove('rt034-pin-code-field--errorState');
                }
                const redirectUrl = new URL(this.sendCode.href);
                redirectUrl.searchParams.set('result', 'success');
                window.location = redirectUrl.href;
            }
        } catch (error) {
            switch (error.codice) {
                case 'IS_ERR:CODICE_GIA_USATO': {
                    if (
                        !this.errorCodeField[0]
                            .closest('.rt034-pin-code-field')
                            .classList.contains('rt034-pin-code-field--errorState')
                    ) {
                        this.errorCodeField[0]
                            .closest('.rt034-pin-code-field')
                            .classList.add('rt034-pin-code-field--errorState');
                    }
                    this.errorCodeField[0].innerHTML = this.alreadyUsedPinErrorLabel;
                    break;
                }
                case 'IS_ERR:CODICE_NON_VALIDO': {
                    if (
                        !this.errorCodeField[0]
                            .closest('.rt034-pin-code-field')
                            .classList.contains('rt034-pin-code-field--errorState')
                    ) {
                        this.errorCodeField[0]
                            .closest('.rt034-pin-code-field')
                            .classList.add('rt034-pin-code-field--errorState');
                    }
                    this.errorCodeField[0].innerHTML = this.incorrectPinErrorLabel;
                    break;
                }
                case 'IS_ERR:CREAZIONE_FALLITA': {
                    if (!this.errorSendCodeGeneric.innerHTML) {
                        this.errorSendCodeGeneric.innerHTML = 'Indirizzo email o numero di telefono non validi';
                    }
                    break;
                }
                case 'IS_ERR:BLOCKED_USER': {
                    if (!this.errorSendCodeGeneric.innerHTML) {
                        this.errorSendCodeGeneric.innerHTML = 'Utenza temporaneamente bloccata, riprova tra 48 ore';
                    }
                    break;
                }
                default: {
                    if (!this.errorSendCodeGeneric.innerHTML) {
                        this.errorSendCodeGeneric.innerHTML = 'Errore generico invio pin concorso';
                    }
                    break;
                }
            }
            console.error(error.codice + ' ' + error.errore);
        }

        closeLoader('main');
    }

    async _initTooltip() {
        this.tooltipId = await generateTooltip(this.tooltipText, this._el('tooltip'));
    }

    _callTooltip(show = true) {
        const event = new CustomEvent('showTooltip', { bubbles: true });
        event.data = {
            tooltipIcon: this.tooltipIcon,
            show: show,
        };
        document.getElementById(this.tooltipId).dispatchEvent(event);
    }

    _checkSendStatus() {
        const sendObj = this.register.getClass(this.sendCode);
        sendObj.setStatus(this._getFormValidity() ? 'enabled' : 'disabled');
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) return false;
        const formData = new FormData(this.form);
        return formData.get('privacy') == 'S';
    }

    _setContacts() {
        /* phone */
        const phoneEl = this._dEl('phone');
        const phone = phoneEl.dataset.phone;
        if (phoneEl && phone) {
            const phoneData = {
                extraClasses: this._el('link'),
                variant: 'link',
                type: 'none',
                label: phone,
                link: {
                    href: `tel:${phone}`,
                    target: '_blank',
                },
                size: 'small',
            };
            const phoneCta = runTemplate(ctaHtml, phoneData);
            phoneEl.appendChild(phoneCta);
        }
        /* mail */
        const mailEl = this._dEl('mail');
        const mail = mailEl.dataset.mail;
        if (mailEl && mail) {
            const mailData = {
                extraClasses: this._el('link'),
                variant: 'link',
                type: 'none',
                label: mail,
                link: {
                    href: `mailto:${mail}`,
                    target: '_blank',
                },
                size: 'small',
            };
            const mailCta = runTemplate(ctaHtml, mailData);
            mailEl.appendChild(mailCta);
        }
    }

    _populateUserForm() {
        let nameField = document.getElementsByName('name');
        if (nameField) {
            let parentDivName = nameField[0].closest('.rt008-text-field');
            parentDivName.classList.add('rt008-text-field--locked');
            nameField[0].value = this.user.nome;
            nameField[0].disabled = true;
        }
        let surnameField = document.getElementsByName('surname');
        if (surnameField) {
            let parentDivSurname = surnameField[0].closest('.rt008-text-field');
            parentDivSurname.classList.add('rt008-text-field--locked');
            surnameField[0].value = this.user.cognome;
            surnameField[0].disabled = true;
        }
        let emailField = document.getElementsByName('email');
        if (emailField) {
            let parentDivSurname = emailField[0].closest('.rt008-text-field');
            parentDivSurname.classList.add('rt008-text-field--locked');
            emailField[0].value = this.user.email;
            emailField[0].disabled = true;
        }
        if (this.user.dataNascita) {
            let birthDateField = document.getElementsByName('birthDate');
            if (birthDateField) {
                let parentDivBirthDate = birthDateField[0].closest('.rt009-date-picker');
                parentDivBirthDate.classList.add('rt009-date-picker--locked');
                birthDateField[0].value = this.user.dataNascita;
                birthDateField[0].disabled = true;
            }
        }
        if (this.user.telefonoCellulare) {
            let telField = document.getElementsByName('mobilePhone');
            if (telField) {
                let parentDivTel = telField[0].closest('.rt008-text-field');
                parentDivTel.classList.add('rt008-text-field--locked');
                telField[0].value = this.user.telefonoCellulare;
                telField[0].disabled = true;
            }
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt065-form-premio');
}
