import PopupComponent from '../../../../../libs/components/popup-component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';
import './style.scss';

export default class ExcelToMapCardDetailsPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();
        this.textContent = this._dEl('textContent');
        this.primaryCta = this._dEl('cta');
    }

    /* override */
    _onBeforeOpen() {
        super._onBeforeOpen();
        this.textContent.innerHTML = "";
        let data = this.flowManager.getDataFromFlow();
        let indexTextForType = 0;
        let bulletsSectionCreated = false;
        for(let [key, value] of Object.entries(data.currentStore)) {
            if(!data.config.tipologies[key])
                continue;

            const elementType = data.config.tipologies[key].type;

            if(elementType != 'break' && !value)
                continue;

            if (elementType && elementType != '') {
                switch (elementType) {
                     case 'title': {
                        let element = document.createElement('h3');
                        element.innerText = value;
                        element.classList.add(this._el('title', false));
                        this.textContent.append(element);

                        break;
                     }
                     case 'description':
                     case 'text':
                     case 'textFont': {
                        let element = document.createElement('p');
                        let typeOfText = data.config.tipologies[key].typeText;

                        element.classList.add(this._el('infoSingle', false));

                        if(elementType == 'textFont') {
                            element.classList.add(this._el('infoSingleSizedDown', false));
                        }

                        if(data.config.tipologies[key].printColumnTitle) {
                            element.innerText = key + " ";
                        }

                        if(typeOfText && typeOfText != '') {
                            let elementValue = document.createElement('a');
                            if(data.config.tipologies[key].bold) {
                                elementValue.classList.add(this._el('infoSingleBold', false));
                            }
                            if(typeOfText == 'email') {
                                elementValue.href = 'mailTo:' + value;
                                elementValue.innerText = value;
                            } else if (typeOfText == 'telephone') {
                                elementValue.href = 'tel:' + value;
                                elementValue.innerText = value;
                            } else if (typeOfText == 'address') {
                                elementValue.href = 'https://www.google.com/maps/dir/?api=1&destination=' + value;
                                elementValue.innerText = value;
                            }
                            element.append(elementValue);
                        } else {
                            let elementValue = document.createElement('span');
                            if(data.config.tipologies[key].bold) {
                                elementValue.classList.add(this._el('infoSingleBold', false));
                            }
                            elementValue.innerText = value;
                            element.append(elementValue);
                        }

                        this.textContent.append(element);
                        indexTextForType++;
                        break;
                     }
                     case 'tagsBuilding': {
                        let elementsContainer = document.createElement('div');
                        elementsContainer.classList.add(this._el('tagsContainer', false));
                        if(data.config.tipologies[key].printColumnTitle) {
                            elementsContainer.innerText = key;
                        }

                        let element = document.createElement('div');
                        element.classList.add(this._el('tagsChipsContainer', false));

                        for(let singleTag of value.split(',')) {
                            let tagElement = document.createElement('span');
                            tagElement.classList.add(this._el('tagSingle', false));
                            tagElement.innerText = singleTag.trim();
                            element.append(tagElement);
                        }

                        elementsContainer.append(element);
                        this.textContent.append(elementsContainer);
                        break;
                     }
                     case 'tagsServices': {
                        let elementsContainer = document.createElement('div');
                        elementsContainer.classList.add(this._el('tagsContainer', false));
                        if(data.config.tipologies[key].printColumnTitle) {
                            elementsContainer.innerText = key;
                        }

                        let element = document.createElement('div');
                        element.classList.add(this._el('tagsChipsContainer', false));

                        for(let singleTag of value.split(';')) {
                            let tagElement = document.createElement('span');
                            tagElement.classList.add(this._el('serviceSingle', false));
                            tagElement.innerText = singleTag.trim();
                            element.append(tagElement);
                        }

                        elementsContainer.append(element);
                        this.textContent.append(elementsContainer);
                        break;
                     }
                     case 'urlPrimaryCta': {
                        this.primaryCta.innerText = key;

                        if(data.config.tipologies[key].addPrefix && data.config.tipologies[key].addPrefix != '') {
                            this.primaryCta.href = data.config.tipologies[key].addPrefix + value;
                        } else {
                            this.primaryCta.href = value;
                        }

                        break;
                     }
                     case 'urlSecondaryCta': {
                        let element = document.createElement('a');
                        element.classList.add(this._el('link', false));
                        element.href = value;
                        element.innerText = key;
                        this.textContent.append(element);
                        break;
                     }
                     case 'break': {
                        let element = document.createElement('br');
                        this.textContent.append(element);
                        break;
                     }
                     case 'bullets': {
                        let elementsListContainer = document.createElement('div');
                        let elementsContainer = document.createElement('div');

                        if(bulletsSectionCreated) {
                            let bulletsSectionsList = document.querySelectorAll(this._el('listContainer', true));
                            elementsContainer = bulletsSectionsList[bulletsSectionsList.length - 1];
                        } else {
                            elementsContainer.classList.add(this._el('listContainer', false));
                        }

                        elementsListContainer.classList.add(this._el('listElements', false));

                        if(data.config.tipologies[key].printColumnTitle) {
                            elementsListContainer.innerText = key;
                        }

                        let list = document.createElement('ul');

                        for(let singleOpt of value.split(/[\n;]/)) {
                            let singleElement = document.createElement('li');
                            singleElement.innerText = singleOpt.trim();
                            list.append(singleElement);
                        }

                        elementsListContainer.append(list);
                        elementsContainer.append(elementsListContainer);

                        if(!bulletsSectionCreated) {
                             this.textContent.append(elementsContainer);
                             bulletsSectionCreated = true;
                        }

                        break;
                     }
                     default: {
                        break;
                     }
                }

                if((elementType != 'text' || elementType != 'textSizedDown') && indexTextForType != 0) {
                    indexTextForType = 0;
                }

                if(elementType != 'bullets' && bulletsSectionCreated == true) {
                    bulletsSectionCreated = false;
                }
            }
        }
    }

    /* override */
    _onOpen() {
        super._onOpen();
    }

    /* override */
    _onClose() {
        super._onClose();
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt306-excel-to-map-card-details-popup');
}
