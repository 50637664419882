import './style.scss';
import LazyLoad from 'vanilla-lazyload';
import Component from '../../../../../libs/components/component';
import { nextTick } from '../../../../../libs/utils';
import { getRegister } from '../../../../../libs/register';
import { decodeBlurHash } from 'fast-blurhash';

export default class Picture extends Component {
    constructor(name, root) {
        super(name, root);

        this.isSimple = this.root.matches('img');
        if (!this.isSimple) this.img = this._dEl('img');
        else this.img = this.root;

        this.urlDefault = this.root.dataset.placeHolder;
        this.lazy = this.root.classList.contains(this._mod('lazy'));
        if (this.lazy) {
            this._initAsync();
        }
    }

    async _initAsync() {
        await nextTick(); // wait next tick
        if (this.img.dataset.blurhash) {
            this._addBlurHash(this.img.dataset.blurhash);
        }
        this._addLazy();
    }

    _addBlurHash(blurhash) {
        try {
            const ratio = this.img.width / this.img.height;
            const dWidth = Math.trunc(ratio >= 1 ? 32 * ratio : 32);
            const dHeight = Math.trunc(ratio < 1 ? 32 / ratio : 32);

            const pixels = decodeBlurHash(blurhash, dWidth, dHeight);
            const canvas = document.createElement('canvas');
            canvas.width = dWidth;
            canvas.height = dHeight;
            const ctx = canvas.getContext('2d');
            const imageData = ctx.createImageData(dWidth, dHeight);
            imageData.data.set(pixels);
            ctx.putImageData(imageData, 0, 0);

            const dataURL = canvas.toDataURL('image/png');
            this.img.src = dataURL;

            this.root.classList.add(this._mod('init'));
        } catch (e) {
            console.warn('Cannot add blurhash', e);
        }
    }

    _addLazy() {
        this.lazyLoad = new LazyLoad(
            {
                callback_loaded: () => {
                    this._callbackLazy();
                },
                callback_error: () => {
                    if (!this.urlDefault) return;

                    // change src of image
                    this.img.setAttribute('src', this.urlDefault);

                    // if picture, remove all sources
                    if (!this.isSimple) {
                        this.img.setAttribute('srcset', `${this.urlDefault} 1x, ${this.urlDefault} 2x`);
                        const sources = Array.from(this.root.querySelectorAll('source'));
                        for (let i = 0; i < sources.length; i++) {
                            sources[i].remove();
                        }
                    }
                    this._callbackLazy();
                },
            },
            [this.img]
        );
    }

    _callbackLazy() {
        this.root.classList.add(this._mod('loaded'));
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt022-picture');
}
