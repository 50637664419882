import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';

export default class Filters extends Component {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();

        this.results = this._dEl('results', true);
        this.count = this._dEl('count');
        this.cta = this._dEl('cta');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.flowManager.startFlow({
                    flowName: 'filters',
                    flowSteps: [{ name: 'listing-filters' }],
                });
            },
            this.cta
        );
    }

    _addStoreListeners() {
        this._addStoreListener('filterResults',  (path, data) => {
            if (!data.results) return;
            this._setResultsText(data.results);
        });
        this._addStoreListener('filterCountMob',  (path, data) => {
            if (!data.count) return;
            this._setCount(data.count);
        });
    }

    _setResultsText(text) {
        this.results.forEach((el) => {
            el.innerText = text;
        });
    }

    _setCount(count) {
        this.count.innerText = count <= 0 ? '' : `(${count})`;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt064-filters');
}
