import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';
import { getRegister } from '../../../../../libs/register';

export default class Topbar extends Component {
    constructor(name, root) {
        super(name, root);
        this.userService = getUserService();
        this.link = this._dEl('link');
        this.next = this._dEl('next');
        this.storeLocatorLink = this.root.hasAttribute('data-store-locator-link')
            ? this.root.dataset.storeLocatorLink
            : null;
        this._load();

        this._addStoreListeners();
    }

    async _load() {
        const store = await this.userService.getStore();
        if (store) {
            if (store.lastVisited) {
                this.root.classList.add(this._mod('lastShop'));
            } else {
                this.root.classList.add(this._mod('prefShop'));
            }

            if (this.link) {
                this.link.innerText = store.descrizioneInsegna + ' - ' + store.indirizzo;
                if (store.pdvLink) {
                    this.link.setAttribute('href', store.pdvLink.href);
                    if (this.next) this.next.setAttribute('href', store.pdvLink.href);
                }
            }
        } else {
            if (!this.storeLocatorLink) {
                console.warn('Missing change favorite store link');
                return;
            }

            if (this.link) {
                this.link.setAttribute('href', this.storeLocatorLink);
                if (this.next) this.next.setAttribute('href', this.storeLocatorLink);
            }
        }

        //show link after load
        this.root.classList.add(this._mod('loaded'));
    }

    _addStoreListeners() {
        this._addStoreListener('userData', () => {
            this._load();
        });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt054-topbar');
}
