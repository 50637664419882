
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import * as anchorCall from './partials/anchor.html';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { BREAKPOINTS } from '../../../../../libs/constants';


export default class Anchors extends Component {
    constructor(name, root) {
        super(name, root);
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.layout = this.root.closest('.rl2-anchors-layout');
        this.layoutItems = Array.from(this.layout.querySelectorAll('.rl2-anchors-layout__item'));
        this.anchorsMap = {};
        this._initAnchors();
        this._addEventListeners();
    }

    _initAnchors() {
        for (const item of this.layoutItems) {
            if (!item.dataset.anchorTitle) continue;
            const anchorData = {
                label: item.dataset.anchorTitle,
            };
            const anchor = runTemplate(anchorCall, anchorData);
            this.anchorsMap[item.dataset.anchorTitle] = {
                anchor: anchor,
                item: item,
            };
            this.root.appendChild(anchor);
        }
    }

    _addEventListeners() {
        for (const anchor of Object.values(this.anchorsMap).map((entry) => entry.anchor)) {
            this._addListener(
                'click',
                () => {
                    const item = this.anchorsMap[anchor.innerText.trim()].item;
                    const top = item.getBoundingClientRect().top;
                    const offset = window.innerWidth < this.BREAKPOINT_L ? 64 : 168;
                    window.scrollTo({
                        top: top - offset,
                        behavior: 'smooth',
                    });
                },
                anchor
            );
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt061-anchors');
}
