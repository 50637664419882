import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';

export default class InsigniaCard extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    /* override */
    getValue() {
        return this.input.checked ? this.input.value : null;
    }

    /* override */
    getLabel() {
        return this.root.dataset.insignia;
    }

    _addEventListeners() {

        this._addListener(
            'keypress',
            (event) => {
                if (!(event.key == 'Enter')) return;
                this.root.click();
                this.root.focus();
            }
        );

        this._addListener(
            'change',
            (event) => {
                event.preventDefault();
                this._checkState();
                this._changedInput();
            },
            this.input
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
            },
            this.root,
            true
        );
    }

    isValid() {
        return this.input.required
            ? this.input.validity.valid && !this.input.validity.valueMissing
            : this.input.validity.valid || this.input.validity.valueMissing;
    }

    reset() {
        this.input.checked = false;
        this.setState('');
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt023-insignia-card');
}
