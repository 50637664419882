import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { storeData } from '../../../../../libs/pdv-utils';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getUserService } from '../../../../../libs/user-service';

export default class CarrotBannerHmf extends Component {
    constructor(name, root) {
        super(name, root);

        this.trackingManager = getTrackingManager();
        this.store = this.root.hasAttribute('data-store') ? JSON.parse(this.root.dataset.store) : null;
        this.storeLocatorLink = this.root.hasAttribute('data-store-locator-link')
            ? JSON.parse(this.root.dataset.storeLocatorLink)
            : null;
        this.changeFavoriteStoreLink = this.root.hasAttribute('data-change-favorite-store-link')
            ? JSON.parse(this.root.dataset.changeFavoriteStoreLink)
            : null;
        this.storeManager = getStoreManager();
        this.userService = getUserService();

        this._doLogic();
    }

    async _doLogic() {
        await this._renderContent();
        this._getElements();
        this._addEventListeners();

        if ((await this.userService.getUser()) == null)
            this.changeFavoriteStore?.classList.add(this._elMod('changeFavoriteStore', 'hidden'));
    }

    async _renderContent() {
        const contentCall = (await import('./partials/content.html')).default;
        const data = {
            ...storeData(this.store),
            changeFavoriteStoreLink: this.changeFavoriteStoreLink,
        };

        const contentEl = runTemplate(contentCall, data);
        Array.from(contentEl.children).forEach((child) => this.root.appendChild(child));
    }

    _getElements() {
        this.changeFavoriteStore = this._dEl('changeFavoriteStore');
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.trackingManager.track(this.root, {
                    event: 'CambiaPdv',
                    CustomLink: 'Click Cambia Pdv',
                });
                if (!this.storeLocatorLink || !this.storeLocatorLink.href) {
                    console.warn('Missing change favorite store link');
                    return;
                }
                if (!this.changeFavoriteStoreLink || !this.changeFavoriteStoreLink.plainHref) {
                    console.warn('Missing change favorite store link');
                    return;
                }
            },
            this.changeFavoriteStore
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt102-carrot-banner-hmf');
}
