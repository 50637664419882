import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getUserService } from '../../../../../libs/user-service';
import { openLoader, closeLoader } from '../../../../../libs/utils';

export default class ResultPremio extends Component {
    constructor(name, root) {
        super(name, root);

        this.apiProvider = getApiProvider();
        this.userService = getUserService();
        this.playsTable = this._dEl('table');

        this._init();
    }

    _addEventListeners() {

    }

    async _init() {
        openLoader('main');
        this.user = await this.userService.getUser();

        if(this.playsTable && this.user) {
            try {
                let plays = await this.apiProvider.getPlaysComp();

                if(plays) {
                    plays.forEach(playSingle => {
                      let row = this.playsTable.insertRow();
                      let pinCode = row.insertCell(0);
                      pinCode.innerHTML = playSingle.code;
                      let usedInDate = row.insertCell(1);

                      let date = new Date(playSingle.createdAt);
                      let mm = date.getMonth() + 1;
                      let dd = date.getDate();
                      let yyyy = date.getFullYear();
                      if (dd < 10) dd = '0' + dd;
                      if (mm < 10) mm = '0' + mm;
                      usedInDate.innerHTML = dd + '/' + mm + '/' + yyyy;
                    });
                }
            } catch (error) {
                console.error("error during plays retrieving - " + error);
            }
        }
        this._addEventListeners();
        closeLoader('main');
    }

}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt067-plays-premio');
}
