import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class Loader extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();
        this.page = document.querySelector('.rs1-page');
    }

    open() {
        this.root.classList.add(this._mod('show'));
        this.root.setAttribute('aria-hidden', 'false');
        const pageObj = this.register.getClass(this.page);
        pageObj.setBlockScroll();
    }

    close() {
        const pageObj = this.register.getClass(this.page);
        pageObj.removeBlockScroll();
        this.root.classList.remove(this._mod('show'));
        this.root.setAttribute('aria-hidden', 'true');
    }

    isOpen() {
        return this.root.classList.contains(this._mod('show'));
    }
}

export const getLoader = (name) => {
    const id = `loader-${name}`;
    const el = document.getElementById(id);
    if (!el) return null;

    return getRegister().getClass(el);
};

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.ru004-loader');
}
