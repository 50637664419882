import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { makeid } from '../../../../../libs/utils';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';

export default class DynamicImage extends Component {
    constructor(name, root) {
        super(name, root);
        this.extApiHelper = getExtApiHelper();
        this.HOTSPOT_SELECTOR = '.s7mapoverlay.s7icon';
        this.CARD_SELECTOR = '.rt030-hotspot-card';

        this.config = this.root.hasAttribute('data-configuration') ? JSON.parse(this.root.dataset.configuration) : null;
        this.skuArray = this.root.hasAttribute('data-sku-array') ? JSON.parse(this.root.dataset.skuArray) : null;
        this.container = this._dEl('container');
        this.id = this.container.id;
        this.hotspotsMap = null;
        this._init();
    }

    async _init() {
        await this._initImage();
        await this._renderHotspotCards();
    }

    async _initImage() {
        /* get s7viewers api */
        const s7viewers = await this.extApiHelper.getS7ViewersApi();
        /* create interactive image */
        this.interactiveImage = new s7viewers.InteractiveImage({
            containerId: this.container.id,
            params: this.config,
        });
        /* set image handlers */
        this.interactiveImage.setHandlers({
            initComplete: () => {
                this._getHotspotsMap();
                this._addHotspotsListeners();
            },
            quickViewActivate: (data) => {
                if (!data.sku) {
                    console.error('Missing sku in quickViewActivate');
                    return;
                }
                const cardId = this.skuArray.filter((skuData) => skuData.sku == data.sku)[0].id;
                if (!cardId) {
                    console.error(`Missing cardId for sku ${data.sku}`);
                    return;
                }
                this._callHotspotCard(this.hotspotsMap[data.sku], cardId);
            },
        });
        /* init */
        this.interactiveImage.init();
    }

    _addHotspotsListeners() {
        if (!this.hotspotsMap) {
            console.error('Could not add hotspots listeners, null hotspots map');
            return;
        }
        Object.values(this.hotspotsMap).forEach((el) => {
            this._addListener(
                'click',
                (event) => {
                    el.classList.toggle('open');
                    // if just closed, return
                    if (!el.classList.contains('open')) return;
                    // if just opened, close others
                    this._closeOthers(el);
                    event.stopPropagation();
                },
                el
            );
            /* click outside the hotspot or its card */
            this._addListener(
                'click',
                (event) => {
                    if (!el.classList.contains('open')) return;
                    const target = event.target;
                    if (
                        target.closest(this.HOTSPOT_SELECTOR) ||
                        target.closest(`${this.CARD_SELECTOR}[data-sku="${el.dataset.sku}"]`)
                    )
                        return;
                    el.classList.remove('open');
                },
                document
            );
        });
    }

    async _closeOthers(self) {
        // close other hotspots cards
        for (const skuData of this.skuArray) {
            if (skuData.sku == null) continue;
            if (skuData.sku == self.dataset.sku) continue;
            await this._callHotspotCard(this.hotspotsMap[skuData.sku], skuData.id, true);
        }
        // close other hotspots
        Object.values(this.hotspotsMap).forEach((other) => {
            if (other == self) return;
            other.classList.remove('open');
        });
    }

     _callHotspotCard(hotspot, cardId, forceClose = false) {
        const event = new CustomEvent('showHotspotCard', { bubbles: true });
        event.data = {
            hotspot: hotspot,
            forceClose: forceClose,
        };
        document.getElementById(cardId).dispatchEvent(event);
    }

    _getHotspotsMap() {
        if (this.hotspotsMap) return;
        const hotspotsArray = Array.from(this.container.querySelectorAll(this.HOTSPOT_SELECTOR));
        this.hotspotsMap = {};
        let i = 0;
        for (const skuData of this.skuArray) {
            if (skuData.sku == null) {
                const invalidHotspot = hotspotsArray[i++];
                invalidHotspot.style.display = 'none';
                continue;
            }
            this.hotspotsMap[skuData.sku] = hotspotsArray[i++];
            this.hotspotsMap[skuData.sku].setAttribute('data-sku', skuData.sku);
        }
    }

    async _renderHotspotCards() {
        const hotspotCardHtml = (await import('../rt030-hotspot-card/rt030-hotspot-card.html')).default;
        for (const skuData of this.skuArray) {
            if (!skuData.sku) continue;
            const cardId = this._generateHotspotCard(hotspotCardHtml, skuData.sku, skuData.href, skuData.label);
            skuData.id = cardId;
        }
    }

    _generateHotspotCard(tpl, sku, href, label, extraClasses = null) {
        const id = makeid(10);
        const hotspotCardData = {
            uniqueId: id,
            extraClasses: extraClasses,
            sku: sku,
            href: href,
            label: label,
        };
        const hotspotCard = runTemplate(tpl, hotspotCardData);
        const mainPage = document.body.querySelector('.rs1-page');
        mainPage.appendChild(hotspotCard);
        return id;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt028-dynamic-image');
}
