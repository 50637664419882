import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';

export default class Tag extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        // constants
        this.ICON = this._mod('icon');
        this.DISABLED = this._mod('disabled');
        this.SELECTED = this._mod('selected');

        this.input = this._dEl('input');
        this.labelText = this._dEl('labelText');
        this.remove = this.labelText.querySelector('span');

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    /* override */
    getValue() {
        return this.input.checked ? this.input.value : null;
    }

    /* override */
    getLabel() {
        return this.labelText.textContent;
    }

    _addEventListeners() {

        this._addListener(
            'keypress',
            (event) => {
                if (!(event.key == 'Enter')) return;
                this.root.click();
                this.root.focus();
            }
        );

        this._addListener(
            'change',
            (event) => {
                event.preventDefault();
                this._checkState();
                this._changedInput();
            },
            this.input
        );

        if (this.remove) {
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    this.root.remove();
                },
                this.remove
            );
        }

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
            },
            this.root,
            true
        );
    }

    isSelected() {
        return this.input.checked;
    }

    select(sendChangedInputEvent = true) {
        this.input.checked = true;
        if (!sendChangedInputEvent) false;
        this._checkState();
        this._changedInput();
    }

    unselect(sendChangedInputEvent = true) {
        this.input.checked = false;
        if (!sendChangedInputEvent) return;
        this._checkState();
        this._changedInput();
    }

    setDisabled(disabled = true) {
        this.input.checked = false;
        disabled ? this.root.classList.add(this.DISABLED) : this.root.classList.remove(this.DISABLED);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt006-tag');
}
