import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';


export default class Toggle extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.CHECKED = this._mod('checked');

        this.input = this._dEl('input');
        this.label = this._dEl('label');

        this._addEventListeners();
    }

    _addEventListeners() {
        
        this._addListener(
            'keypress',
            (event) => {
                if (!(event.key == 'Enter')) return;
                this.label.click();
                this.label.focus();
            },
            this.label
        );

        this._addListener(
            'change', 
            () => {
                this.input.checked ? this.root.classList.add(this.CHECKED) : this.root.classList.remove(this.CHECKED);
            },
            this.input
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt005-toggle');
}
