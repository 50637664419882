import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';


export default class Feedback extends Component {
    constructor(name, root) {
        super(name, root);
        this.close = this._dEl('close');
        this._addEventListeners();
    }

    _addEventListeners() {
        if (this.close) {
            this._addListener(
                'click',
                () => {
                    this._close();
                },
                this.close
            );
        }
    }

    _close() {
        if (this.root.closest('#feedback-container') || this.root.hasAttribute('data-close-self')) {
            this.root.remove();
            return;
        }
        /* remove from layout */
        const event = new CustomEvent('removeLayoutItem', { bubbles: true });
        event.data = {
            remove: true,
        };
        this.root.dispatchEvent(event);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt036-feedback');
}
