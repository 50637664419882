import './style.scss';
import { getApiProvider } from '../../../../../libs/api-provider';
import PopupComponent from '../../../../../libs/components/popup-component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { getUserService } from '../../../../../libs/user-service';

export default class AddStorePopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.loginEl = this._dEl('ctaAction');
        this.proceed = this._dEl('proceed');
        this.MAX_STORES = this._mod('maxStores');

        this.userService = getUserService();
        this.flowManager = getFlowManager();

        this._addListeners();
    }

    _addListeners() {
        this._addListener('click', (_) => this.flowManager.complete(), this.proceed);
    }

    async _checkSavedStores() {
        const user = await this.userService.getUser();
        if (user) {
            const savedStores = await this.userService.getSavedStores();
            if (savedStores.length >= window.maxStoreOfInterest) {
                this.root.classList.add(this.MAX_STORES);
            } else {
                const store = await getApiProvider().getPointOfServiceByAnacanId({ anacanId: this.anacanId });
                this._dEl('storeName').innerHTML = store.pdvTitle;
                this._dEl('storeAddress').innerHTML = store.pdvAddress;
            }
        }
    }

    _onOpen() {
        const data = this.flowManager.getDataFromFlow();
        this.anacanId = data.anacanId;
        if (!this.anacanId) return;
        this._checkSavedStores();
    }

    _onCancel() {
        this.flowManager.complete();
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt302-add-store-popup');
}
