/* eslint-disable no-extra-boolean-cast */
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { jsonToForm } from '../../../../../libs/form-to-json';
import { getApiProvider } from '../../../../../libs/api-provider';
import * as richtextHtml from '../rt001-richtext/rt001-richtext.html';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import {
    closeLoader,
    delay,
    emptyElement,
    generateFeedback,
    htmlToElement,
    openLoader,
} from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import * as statusMsgHtml from './partials/status-msg.html';

export default class ContactForm extends Component {
    constructor(name, root) {
        super(name, root);

        this.register = getRegister();
        this.userService = getUserService();
        this.apiProvider = getApiProvider();
        this.trackingManager = getTrackingManager();

        this.CONSTANTS = {
            /* card options (must match the values on BE side) */
            cardTypeInsieme: 'cartainsieme',
            cardTypeConadCard: 'conadcardpay',
            cardTypePrepagata: 'prepagataconad',
            /* form types (must match the CCRecordType values on BE side) */
            formTypeBrandedProd: 'Prodotti_a_Marchio_Conad_Sapori_e_Dintorni',
            formTypeOtherProd: 'Prodotti_di_altre_marche',
            formTypeStores: 'Punti_Vendita',
            formTypeInsurances: 'Assicurazioni',
            formTypeAppSite: 'Segnalazioni_app_sito',
            formTypePrizes: 'Premi_raccolte_bollini_catalogo_Mi_Premio_e_concorsi',
            /* ccrt (must match the CCTipo values on BE side) */
            ccrtComplBrandedProd: 'Reclamo su prodotti a marchio Conad',
            ccrtComplOtherProd: 'Reclamo su prodotti di altre marche',
            ccrtReqInfo: 'Richiesta di informazioni',
            ccrtReqTechSupport: 'Richiesta di supporto tecnico',
            ccrtComplPayments: 'Reclami relativi ai pagamenti',
            /* thematics (must match the CCArgomento values on BE side) */
            thematicExpiredBrandedProd: 'Prodotti a marchio Conad scaduti',
            thematicExpiredOtherProd: 'Prodotti di altre marche scaduti',
            /* delay */
            delayInMillis: 200,
            /* param names */
            paramRecordType: 'recordType',
            paramTipo: 'tipo',
            paramArgomento: 'argomento',
            paramStoreId: 'pdvScelto',
        };
        this.IGNORE_FORM_FIELDS = ['carta', 'ccRequestType', 'ccRequestTypeLabel', 'thematic', 'codiceCartaFidelity2'];

        this._doLogic();
    }

    async _doLogic() {
        if (!this._checkNecessaryData()) return;
        this._getElements();
        await this._initForm();
        this._addEventListeners();
        this._addStoreListeners();
    }

    _checkNecessaryData() {
        // get necessary variables
        if (!this.root.hasAttribute('data-loader-endpoint')) {
            console.warn('Missing loader endpoint');
            return false;
        }
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        if (!this.root.hasAttribute('data-form-type')) {
            console.warn('Missing formType');
            return false;
        }
        try {
            this.formType = JSON.parse(this.root.dataset.formType);
        } catch (error) {
            console.warn('Could not parse formType');
            return false;
        }
        if (this._isCardsForm()) {
            try {
                const values = JSON.parse(this.formType.value);
                // keys = values of the card radios
                this.cardRecordTypes = {
                    [this.CONSTANTS.cardTypeInsieme]: values[0],
                    [this.CONSTANTS.cardTypeConadCard]: values[1],
                    [this.CONSTANTS.cardTypePrepagata]: values[1],
                };
            } catch (error) {
                console.warn('Could not parse formType values');
                return false;
            }
            if (!this.root.hasAttribute('data-ccrt-by-card')) {
                console.warn('Missing ccrt by card');
                return false;
            }
            try {
                this.ccrtByCard = JSON.parse(this.root.dataset.ccrtByCard);
            } catch (error) {
                console.warn('Could not parse ccrtByCard');
                return false;
            }
        }
        try {
            this.invisibleCCRTMap = JSON.parse(this.root.dataset.invisibleCcrtMap);
        } catch (error) {
            console.warn('Could not parse invisibleCCRTMap');
            return false;
        }
        try {
            this.invisibleThematicMap = JSON.parse(this.root.dataset.invisibleThematicMap);
        } catch (error) {
            console.warn('Could not parse invisibleThematicMap');
            return false;
        }
        this.thankyouPageUrl = this.root.dataset.thankyouPageUrl;
        this.eanTooltipText = this.root.dataset.eanTooltipText;

        return true;
    }

    _getElements() {
        this.form = this._dEl('form');
        this.cardRadios = this._dEl('cardRadios');
        this.ccRequestType = this._dEl('ccRequestType');
        this.formSectionCCRT = this._dEl('formSectionCCRT');
        this.privacyData = this._dEl('privacyData');
        this.privacyDataText = this._dEl('privacyDataText');
        this.privacyDataReadAll = this._dEl('readAll');
        this.send = this._dEl('send');
        this.cancel = this._dEl('cancel');
    }

    _addEventListeners() {
        this._addListener(
            'submit',
            (e) => {
                e.preventDefault();
            },
            this.form
        );
        if (this.cardRadios) {
            this._addListener(
                'rcInputChanged',
                async (e) => {
                    if (!this.ccRequestType) return;
                    const selectObj = this.register.getClass(this.ccRequestType);
                    const ccrtOptions = this.ccrtByCard[e.data.value] || null;
                    if (ccrtOptions == null) {
                        console.warn('Could not set ccRequestType options');
                        e.stopPropagation();
                        return;
                    }
                    emptyElement(this.formSectionCCRT);
                    this._hidePrivacyAndCtas();
                    await selectObj.reset();
                    await selectObj.setItems(ccrtOptions);
                    selectObj.enable();
                    e.stopPropagation();
                },
                this.cardRadios
            );
        }
        if (this.ccRequestType) {
            this._addListener(
                'rcInputChanged',
                async (e) => {
                    const ccrt = e.data.value;
                    this._hidePrivacyAndCtas();
                    const card = !this._isCardsForm() ? null : await this._getCardRadioValue();
                    await this._loadFormSectionCCRT(ccrt, card);
                    this._doFormLogic(ccrt);
                    e.stopPropagation();
                },
                this.ccRequestType
            );
        }
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.privacyData.classList.add(this._elMod('privacyData', 'readAll'));
                event.stopPropagation();
            },
            this.privacyDataReadAll
        );
        this._addListener(
            'click',
            () => {
                window.history.back();
            },
            this.cancel
        );
        this._addListener(
            'click',
            async () => {
                if (!(await this._getFormValidity())) {
                    this._showStoreValidity();
                    this._scrollToFirstInvalid();
                    this._showStatusError();
                    return;
                }

                this._hideStatusError();
                this._sendCustomerCare();
                this.trackingManager.track(this.root, {
                    event: 'InviaFormContatto',
                    CustomLink: 'Click su Invia Form di contatto',
                });
            },
            this.send
        );
    }

    _addStoreListeners() {
        this._addStoreListener('selectedStore', (path, data) => {
            if (!data.anacanId) {
                this.selectedStoreId = null;
                return;
            }
            this.selectedStoreId = data.anacanId;
            this._setPrivacyText(data.codiceCooperativa);
        });
    }

    async _loadFormSectionCCRT(ccrt, card = null) {
        openLoader('main');
        const loaderParams = card != null ? { ccrt, card } : { ccrt };
        const data = await this._callLoader(loaderParams);
        if (data == null) {
            console.warn('Null response for form section');
            emptyElement(this.formSectionCCRT);
            this._hidePrivacyAndCtas();
            return;
        }
        this._setFormSection(data, this.formSectionCCRT);
        await delay(this.CONSTANTS.delayInMillis);
        closeLoader('main');
    }

    async _loadSubFormSectionCCRTT(ccrt, ccrtt, subsection) {
        openLoader('main');
        const data = await this._callLoader({ ccrt, ccrtt });
        if (data == null) {
            console.warn('Null response for form subsection');
            emptyElement(subsection);
            return;
        }
        this._setFormSection(data, subsection);
        await delay(this.CONSTANTS.delayInMillis);
        closeLoader('main');
    }

    _doFormLogic(ccrt) {
        // cards form treated separately
        if (this._isCardsForm()) {
            this._showPrivacyAndCtas();
            return;
        }

        // other forms
        if (
            this.formType.value === this.CONSTANTS.formTypeBrandedProd ||
            this.formType.value === this.CONSTANTS.formTypeOtherProd
        ) {
            this._setEanTooltip();
            if (ccrt === this.CONSTANTS.ccrtComplBrandedProd || ccrt === this.CONSTANTS.ccrtComplOtherProd) {
                this._doExpirationDateLogic();
            }
            this._showPrivacyAndCtas();
            return;
        }

        if (
            this.formType.value === this.CONSTANTS.formTypeStores ||
            this.formType.value === this.CONSTANTS.formTypeAppSite
        ) {
            this._showPrivacyAndCtas();
            return;
        }

        // Assicurazioni
        if (
            this.formType.value === this.CONSTANTS.formTypeInsurances ||
            this.formType.value === this.CONSTANTS.formTypeAppSite
        ) {
            this._showPrivacyAndCtas();
            return;
        }

        if (this.formType.value === this.CONSTANTS.formTypePrizes) {
            const formSectionCCRTT = this._dEl('formSectionCCRTT');
            const thematic = this._dEl('thematic');
            if (thematic) {
                this._addListener(
                    'rcInputChanged',
                    async (e) => {
                        const ccrtt = e.data.value;
                        await this._loadSubFormSectionCCRTT(ccrt, ccrtt, formSectionCCRTT);
                        this._showPrivacyAndCtas();
                        e.stopPropagation();
                    },
                    thematic
                );
            }
            return;
        }

        console.warn('Unknown form type.');
    }

    _setEanTooltip() {
        const ean = this._dEl('ean');
        if (!ean) return;
        this.register.getClass(ean).setTooltip(
            `
            <div class="${this._el('eanTooltipContent')}">
                <div></div>
                <div>${this.eanTooltipText}</div>
            </div>
        `,
            this._el('eanTooltip')
        );
    }

    _doExpirationDateLogic() {
        const productComplaintReason = this._dEl('productComplaintReason');
        const expirationDate = this._dEl('expirationDate');
        if (!productComplaintReason || !expirationDate) return;
        this._addListener(
            'rcInputChanged',
            (e) => {
                const obj = this.register.getClass(expirationDate);
                obj.setRequired(
                    e.data.value === this.CONSTANTS.thematicExpiredBrandedProd ||
                        e.data.value === this.CONSTANTS.thematicExpiredOtherProd
                );
            },
            productComplaintReason
        );
    }

    async _getFormValidity() {
        const browserValidity = this.form.checkValidity();
        const filesValidity = await this._validFiles();
        const storeValidity = !!this.selectedStoreId || this.formType.value === this.CONSTANTS.formTypeInsurances;
        return browserValidity && filesValidity && storeValidity;
    }

    _showStoreValidity() {
        const storeObj = this.register.getClass(this._dEl('storeDataSelectStore'));
        if (storeObj) storeObj.checkMissingField();
    }

    async _scrollToFirstInvalid() {
        //scroll to first input invalid
        const browserValidity = this.form.checkValidity();
        if (!browserValidity) {
            const first = this.form.querySelector('input:invalid,textarea:invalid');
            if (first) {
                setTimeout(() => {
                    first.scrollIntoView({
                        block: 'center',
                    });
                }, 300);
                return;
            }
        }
        //scroll to files
        const filesValidity = await this._validFiles();
        if (!filesValidity) {
            setTimeout(() => {
                this._dEl('fileUpload').scrollIntoView({
                    block: 'center',
                });
            }, 300);
            return;
        }
        //scroll to store
        const storeValidity = !!this.selectedStoreId;
        if (!storeValidity) {
            setTimeout(() => {
                this._dEl('storeDataSelectStore').scrollIntoView({
                    block: 'center',
                });
            }, 300);
            return;
        }
    }

    async _sendCustomerCare() {
        openLoader('main');

        try {
            const formData = await this._getFormData();
            await this.apiProvider.customerCare(formData);
            if (!this.thankyouPageUrl) {
                console.warn('Missing thankyou page url');
                return;
            }
            const url = new URL(this.thankyouPageUrl, window.origin);
            window.location = url.href;
        } catch (error) {
            console.warn('Could not send customer care request');
            generateFeedback({
                text: 'Errore generico',
                semanticType: 2,
            });
        } finally {
            setTimeout(() => {
                closeLoader('main');
            }, 200);
        }
    }

    async _getFormData() {
        const data = new FormData(this.form);

        // append fields with custom logic
        const recordType = this._isCardsForm() ? await this._getCardsFormRecordType() : this.formType.value;
        if (!!recordType) data.append(this.CONSTANTS.paramRecordType, recordType);

        const visibleCCRT = await this._getCCRTValue();
        const tipo = visibleCCRT ? visibleCCRT : await this._getInvisibleCCRT();
        if (!!tipo) data.append(this.CONSTANTS.paramTipo, tipo);

        const visibleThematic = await this._getThematicValue();
        const argomento = visibleThematic ? visibleThematic : await this._getInvisibleThematic();
        if (!!argomento) data.append(this.CONSTANTS.paramArgomento, argomento);

        if (this.selectedStoreId) data.append(this.CONSTANTS.paramStoreId, this.selectedStoreId);

        // use fidelity 2 if defined
        if (data.has('codiceCartaFidelity2')) {
            data.set('codiceCartaFidelity', data.get('codiceCartaFidelity2'));
        }

        // delete fields to ignore
        for (const ignored of this.IGNORE_FORM_FIELDS) {
            data.delete(ignored);
        }

        return data;
    }

    _validFiles() {
        const fileUpload = this._dEl('fileUpload');
        if (!fileUpload) return true;
        const fileUploadObj = this.register.getClass(fileUpload);
        return fileUploadObj.isValid();
    }

    async _initForm() {
        const user = await this.userService.getUser();
        await this._setPrivacyText();
        if (user) {
            jsonToForm(this.form, {
                ...user,
                codiceCartaFidelity: user.cartaFedelta,
            });
        }
    }

    async _setPrivacyText(forceCoop = null) {
        const user = await this.userService.getUser();
        let coop = 'cnd';
        if (user && user.newMappedCooperative) coop = user.newMappedCooperative;
        if (forceCoop) coop = forceCoop;
        try {
            const informativa = await this.apiProvider.getInformativa({
                cooperativa: coop,
                ecommerce: '0',
                card: user && (user.cartaFedelta || user.conadCard),
            });
            const richtext = runTemplate(richtextHtml, {
                extraClasses: this._el('richtext'),
                textIsRich: true,
                text: informativa.text,
            });
            const staleRichtext = this._dEl('richtext');
            if (staleRichtext) staleRichtext.remove();
            this.privacyDataText?.prepend(richtext);
        } catch (error) {
            console.warn(error);
        }
    }

    _showPrivacyAndCtas() {
        this.root.classList.add(this._mod('showBottom'));
    }
    _hidePrivacyAndCtas() {
        this.root.classList.remove(this._mod('showBottom'));
    }

    async _callLoader({ ccrt, ccrtt, card }) {
        const url = this.loaderEndpoint;
        const data = {
            ccrt,
            ccrtt,
            card,
        };
        try {
            const html = await this.apiProvider.loaderGet(url, data);
            return html;
        } catch (error) {
            console.error(error);
        }
        return null;
    }

    _setFormSection(html, section) {
        emptyElement(section);

        const element = htmlToElement(html);

        for (const child of [...element.children]) {
            section.append(child);
        }
    }

    _isCardsForm() {
        return this.formType && this.formType.value && this.formType.value[0] === '[';
    }

    async _getCardsFormRecordType() {
        if (!this._isCardsForm() || !this.cardRecordTypes) return null;
        return this.cardRecordTypes[await this._getCardRadioValue()] || null;
    }

    _getCardRadioValue() {
        if (!this.cardRadios) return null;
        const radioObj = this.register.getClass(this.cardRadios);
        return radioObj.getValue();
    }

    _getThematicValue() {
        const thematic = this._dEl('thematic');
        if (!thematic) return null;
        const obj = this.register.getClass(thematic);
        return obj.getValue() || null;
    }

    _getCCRTValue() {
        const ccrtEl = this._dEl('ccRequestType');
        if (!ccrtEl) return null;
        const obj = this.register.getClass(ccrtEl);
        return obj.getValue() || null;
    }

    _getInvisibleCCRT() {
        if (!this.formType || !this.formType.value) return null;
        return this.invisibleCCRTMap[this.formType.value];
    }

    async _getInvisibleThematic() {
        const FALLBACK = null;
        if (!this.formType || !this.formType.value) return FALLBACK;

        // app/site form treated separately
        if (this.formType.value === this.CONSTANTS.formTypeAppSite) {
            const ccrt = await this._getCCRTValue();
            let thematics;
            try {
                thematics = JSON.parse(this.invisibleThematicMap[this.formType.value]);
            } catch (error) {
                console.warn(
                    `Could not parse thematics for formType ${this.formType.value}: ${
                        this.invisibleThematicMap[this.formType.value]
                    }`
                );
                return FALLBACK;
            }
            if (ccrt === this.CONSTANTS.ccrtReqInfo) return thematics[0] || FALLBACK;
            if (ccrt === this.CONSTANTS.ccrtReqTechSupport) return thematics[1] || FALLBACK;
        }

        // stores form treated separately
        if (this.formType.value === this.CONSTANTS.formTypeStores) {
            const ccrt = await this._getCCRTValue();
            if (ccrt === this.CONSTANTS.ccrtComplPayments)
                return this.invisibleThematicMap[this.formType.value] || FALLBACK;
            return FALLBACK;
        }

        // other forms
        return this.invisibleThematicMap[this.formType.value] || FALLBACK;
    }

    _showStatusError() {
        if (this.statusMsg) return;
        this.statusMsg = runTemplate(statusMsgHtml, {
            uniqueId: this.root.id,
            message: 'Invio del form non riuscito. Alcuni campi non sono compilati correttamente.',
        });
        this.root.appendChild(this.statusMsg);
    }

    _hideStatusError() {
        this.statusMsg?.remove();
        this.statusMsg = null;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt063-contact-form');
}
