import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { storeData } from '../../../../../libs/pdv-utils';
import { emptyElement } from '../../../../../libs/utils';

export default class SelectStore extends Component {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();
        this.wrapper = this._dEl('wrapper');
        this.storeWrapper = this._dEl('storeWrapper');
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        [this.wrapper, this.storeWrapper].forEach((el) => {
            this._addListener(
                'click',
                (event) => {
                    if (event.target.closest('a[data-component]')) return;
                    this.flowManager.startFlow({
                        flowName: 'select-pos',
                        flowSteps: [{ name: 'select-pos-step-2' }],
                    });
                },
                el
            );
        });
    }

    _addStoreListeners() {
        this._addStoreListener('selectedStore', async (path, data) => {
            if (!data.anacanId) return;

            const storeCardHtml = (await import('../rt021-store-card/rt021-store-card.html')).default;

            const storeCardData = {
                extraClasses: this._el('store'),
                ...storeData(data),
                selected: true,
                simplified: true,
            };
            const storeCard = runTemplate(storeCardHtml, storeCardData);
            
            emptyElement(this.storeWrapper);
            this.storeWrapper.appendChild(storeCard);
            this.root.classList.add(this._mod('store'));
            this.root.classList.remove(this._mod('errored'));
        });
    }

    checkMissingField() {
        if (this.root.classList.contains(this._mod('store'))) {
            this.root.classList.remove(this._mod('errored'));
        } else {
            this.root.classList.add(this._mod('errored'));
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt108-select-store');
}
