import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { BREAKPOINTS } from '../../../../../libs/constants';

export default class StoreInfo extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {

        this._addListener(
            ['click', 'keydown'], 
            (e) => {
                if (e.type === 'keydown' && e.key !== 'Enter') return;
                const heading = e.target.closest(this._el('heading', true));
                if (!heading) return;

                const arrow = heading.querySelector(this._el('arrow', true));
                const content = heading.nextElementSibling;

                const doAccLogic = (window.innerWidth < BREAKPOINTS.l || !this._mobileAccOnly(heading));
                if (!doAccLogic) return;

                if (!this._isOpen(heading)) {
                    // about to be opened
                    content.style.visibility = 'visible';
                }

                arrow.classList.toggle(this._elMod('arrow', 'close'));
                if (content.classList.contains(this._el('contentSub'))) {
                    content.classList.toggle(this._elMod('contentSub', 'close'));
                    heading.classList.toggle(this._elMod('headingSub', 'close'));
                } else {
                    content.classList.toggle(this._elMod('content', 'close'));
                    heading.classList.toggle(this._elMod('heading', 'close'));
                }

                this._updateAriaAttributes(heading, content);
                if (!this._isOpen(heading)) {
                    // just closed
                    content.style.visibility = 'hidden';
                }
            }
        );

        this._addListener(
            'resize',
            () => {
                if (window.innerWidth >= BREAKPOINTS.l) {
                    // switch to desktop --> open all headings
                    const headings = this._dEl('heading', true);
                    headings.forEach((heading) => {
                        const arrow = heading.querySelector(this._el('arrow', true));
                        const content = heading.nextElementSibling;
                        content.style.visibility = 'visible';
                        arrow.classList.remove(this._elMod('arrow', 'close'));
                        if (content.classList.contains(this._el('contentSub'))) {
                            content.classList.remove(this._elMod('contentSub', 'close'));
                            heading.classList.remove(this._elMod('headingSub', 'close'));
                        } else {
                            content.classList.remove(this._elMod('content', 'close'));
                            heading.classList.remove(this._elMod('heading', 'close'));
                        }
                        this._updateAriaAttributes(heading, content);
                    });
                    return;
                }
                if (window.innerWidth < BREAKPOINTS.l) {
                    // switch to mobile --> close headingSub
                    const headingsSub = this._dEl('headingSub', true);
                    headingsSub.forEach((heading) => {
                        const arrow = heading.querySelector(this._el('arrow', true));
                        const content = heading.nextElementSibling;
                        content.style.visibility = 'visible';
                        arrow.classList.add(this._elMod('arrow', 'close'));
                        content.classList.add(this._elMod('contentSub', 'close'));
                        heading.classList.add(this._elMod('headingSub', 'close'));
                        this._updateAriaAttributes(heading, content);
                    });
                    return;
                }
            },
            window
        );
    }

    _mobileAccOnly(heading) {
        if (!heading) return false;
        return heading.classList.contains(this._el('headingSub'));
    }

    _isOpen(heading) {
        if (!heading) return false;
        return !heading.classList.contains(this._elMod('heading', 'close')) 
            && !heading.classList.contains(this._elMod('headingSub', 'close'));
    }

    _updateAriaAttributes(heading, content) {
        const isOpen = this._isOpen(heading);
        heading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        content.setAttribute('tabindex', `${isOpen ? '0' : '-1'}`);
        content.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt058-store-info');
}
