import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { generateTrackNegozioInfo, generateTrackVolantinoInfo } from '../../../../../libs/utils';

export default class CardFlyer extends Component {
    constructor(name, root) {
        super(name, root);
        this.download = this._dEl('download');
        this.trackingManager = getTrackingManager();

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.trackingManager.track(this.root, {
                    event: 'DownloadVolantino',
                    CustomLink: 'Click Download Volantino',
                    funnel: {
                        stepFunnel: 'Consultazione volantino',
                    },
                    negozioInfo: {
                        ...generateTrackNegozioInfo(),
                    },
                    volantinoInfo: {
                        ...generateTrackVolantinoInfo(),
                        TipologiaVolantino: 'Volantino scaricato',
                    },
                });
            },
            this.download
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt215-card-flyer');
}
