import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';

export default class PinCodeField extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.maxLength = parseInt(this.root.dataset.maxLength);
        this.input = this._dEl('input');
        this.error = this._dEl('error');

        this._initPinCode();
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        this._addListener(
            ['keyup', 'change', 'input'],
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                }
                this._changedInput();
            },
            this.input
        );

        this._addListener(
            'focusout',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.input
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                }
            },
            this.root,
            true
        );
    }

    _initPinCode() {
        this.input.value = '';
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }

    _invalidField() {
        this.error.innerText = 'Campo non valido';
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt034-pin-code-field');
}
