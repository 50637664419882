import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class HeroCarta extends Component {
    constructor(name, root) {
        super(name, root);
        this.arrow = this._dEl('icon');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener('click', () => {
            const offset = window.innerWidth < this.BREAKPOINT_L ? 300 : 400;
            window.scrollBy(0, offset);
        },
        this.icon
        );
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt100-hero-carta');
}
