import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { generateShareLinks } from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { BREAKPOINTS } from '../../../../../libs/constants';

export default class Recipe extends Component {
    constructor(name, root) {
        super(name, root);
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.trackingManager = getTrackingManager();

        this.title = this._dEl('title');
        this.top = this._dEl('top');
        this.ctas = this._dEl('ctas');
        this.container = this._dEl('ingredientsTabContainer');
        this.tab = this._dEl('ingredientsTab');
        this.num = this._dEl('peopleNum');
        this.minus = this._dEl('minus');
        this.plus = this._dEl('plus');
        this.quantity = this._dEl('quantity', true);
        this.showFraction = this.root.dataset.fraction;

        this.ctas.style.top = `${this.title.getBoundingClientRect().height + this.top.getBoundingClientRect().height + 16 + 24}px`;
        if(window.innerWidth >= this.BREAKPOINT_L) {
            this.container.style.height = `${this.tab.getBoundingClientRect().height}px`;
        } else {
            this.container.style.height = `${this.tab.getBoundingClientRect().height - 45}px`;
        }


        this.unit = new Array();
        this.initialQuantity = new Array();
        let p = parseInt(this.num.innerHTML, 10);
        this._calcUnit(this.quantity, p);

        if (this.showFraction == 'true') {
            this._multiply(this.unit, p);
        }

        this.share = this._dEl('ctaShare');
        this._init();
    }

    async _init(){
        await this._initShare();
        this._addEventListeners();
    }

    _addEventListeners() {

        this._addListener(
            'click',
            (ev) => {
                ev.preventDefault();
                if (!this.shareId) return;
                const event = new CustomEvent('showShareLinks', { bubbles: true });
                event.data = {
                    root: this._dEl('ctaBookmark'),
                };
                const popup = document.getElementById(this.shareId);
                popup.dispatchEvent(event);
                this.trackingManager.track(this.root, {
                    event: 'Condividi',
                    CustomLink: 'Click Condividi'
                });
                ev.stopPropagation();  
            },
            this.share
        );

        this._addListener('click', (e) => {
            const minus = e.target.closest(this._el('minus', true));
            const plus = e.target.closest(this._el('plus', true));
            if(!minus && !plus) return;

            e.preventDefault();
            let p = parseInt(this.num.innerHTML);
            if (minus) {
                if (p > 1) {
                    this.num.innerHTML = p - 1;
                    p = p - 1;
                    this._multiply(this.unit, p);
                }
            } else if (plus) {
                this.num.innerHTML = p + 1;
                p = p + 1;
                this._multiply(this.unit, p);
            }
        });

        window.addEventListener('resize', () => {
            if(window.innerWidth >= this.BREAKPOINT_L) {
                this.container.style.height = `${this.tab.getBoundingClientRect().height}px`;
            } else {
                this.container.style.height = `${this.tab.getBoundingClientRect().height - 45}px`;
            }
        });
    }

    async _initShare() {
        this.shareId = await generateShareLinks(this.root.dataset.link, this.root.dataset.title);
    }

    _multiply(unit, num) {
        for (let i = 0; i < unit.length; i++) {
            if (this.showFraction == 'false') {
                if (Math.round(unit[i] * num) == 0) {
                    this.quantity[i].innerHTML = 'q.b.';
                } else {
                    if (this.initialQuantity[i] >= 50) {
                        this.quantity[i].innerHTML = Math.round((unit[i] * num) / 5) * 5;
                    } else {
                        this.quantity[i].innerHTML = Math.round(unit[i] * num);
                    }
                }
            } else {
                this.quantity[i].innerHTML = _frac(unit[i] * num);
            }
        }
    }

    _calcUnit(quantity, num) {
        let q = new Array();
        for (let i = 0; i < quantity.length; i++) {
            q[i] = parseFloat(quantity[i].innerHTML);
            this.initialQuantity[i] = q[i];
            this.unit[i] = q[i] / num;
        }
    }
}

function _frac(num) {
    if (num < 1) {
        let fr;
        switch (true) {
            case num == 0: {
                return num;
            }
            case num > 0 && num <= 0.25: {
                fr = '1/4';
                break;
            }
            case num > 0.25 && num < 0.33: {
                fr = '1/2';
                break;
            }
            case num >= 0.33 && num < 0.4: {
                fr = '1/3';
                break;
            }
            case num > 0.4 && num < 0.6: {
                fr = '1/2';
                break;
            }
            case num >= 0.6 && num < 0.8: {
                fr = '2/3';
                break;
            }
            case num >= 0.8 && num < 0.9: {
                fr = '3/4';
                break;
            }
            case num >= 0.9: {
                return 1;
            }
            default:
                break;
        }
        return fr;
    } else {
        let decimal = Math.floor(num);
        let rest = num - decimal;
        let fraction = _frac(rest);
        if (fraction == 0) {
            return decimal.toString();
        }
        if (fraction == 1) {
            return (decimal + 1).toString();
        }
        return decimal.toString() + ' + ' + fraction.toString();
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt060-recipe');
}
