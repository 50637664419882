import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { formToJSON } from '../../../../../libs/form-to-json';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class FlyerFilters extends Component {
    constructor(name, root) {
        super(name, root);
        /* swiper */
        this.carousel = this._dEl('carousel');
        this.swiper = null;
        this.prev = this._dElMod('btn', 'prev');
        this.next = this._dElMod('btn', 'next');
        this.form = this._dEl('form');

        this.dynReg = getRegister();
        this.trackingManager = getTrackingManager();
        this._init();
    }

    async _init() {
        await this._initSwiper();
        this._initTrackingInfo();
        this._initEventsListener();
    }

    _initTrackingInfo() {
        this.TRACKING_SLIDE_LATERALE = {
            funnel: {
                stepFunnel: 'Scelta Categorie Prodotto',
            },
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
        };
    }

    _initEventsListener() {
        this._addListener(
            'rcInputChanged',
            (e) => {
                // Save into the store the current swiper translate prop
                this._emit('swiperTranslate', this.swiper.progress);
                initFliyerCategories(e, this, this.form, this.dynReg);
            },
            this.form
        );
        this._addListener(
            'click',
            () => {
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.prev
        );
        this._addListener(
            'click',
            () => {
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.next
        );
    }

    async _initSwiper() {
        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
        };

        options['navigation'] = {
            nextEl: this.next,
            prevEl: this.prev,
            disabledClass: this._elMod('btn', 'disabled'),
            hiddenClass: this._elMod('btn', 'hidden'),
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
        if (this.carousel && this.swiper) {
            const progress = this.storeManager.get('swiperTranslate');
            this.swiper.setProgress(progress);
            this.swiper.update();
        }
    }
}

export function initFliyerCategories(event, context, form, register) {
    let categories = context._dEl('slide', true);
    const filterAll = register.getClass(categories[0]);

    if (event.data.value == null && event.target.id != categories[0].id) {
        delete categories[0];
        let notOtherSelected = true;
        for (let [index, element] of categories.entries())
            if (index != 0) notOtherSelected &= !register.getClass(element).isSelected();

        if (notOtherSelected) filterAll.select(false);
    } else if (event.data.value == null && event.target.id == categories[0].id) {
        filterAll.select(false);
        return;
    } else if (event.data.value == '__all') {
        filterAll.select(false);
        for (let [index, element] of categories.entries()) if (index != 0) register.getClass(element).unselect(false);
    } else if (event.data.value != '__all') {
        filterAll.unselect(false);
    }

    const filters = Object.values({ ...formToJSON(form) });
    const filterLabels = Object.keys({ ...formToJSON(form) });

    //Update filters
    const key = 'appliedCategoriesFilters';
    context._emit(key, filters);
    const json = {};
    json['cat_lev0_id'] = filters;
    context._emit('rt150CategoriesFilters', { filters: json, filterLabels: filterLabels });
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt071-flyer-filters');
}
