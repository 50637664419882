import './style.scss';
import PopupComponent from '../../../../../libs/components/popup-component';
import { getRegister } from '../../../../../libs/register';
import { flowManager } from '../../../../../libs/flow-manager';

export default class LoginPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.loginEl = this._dEl('ctaAction');
        this._setCallbackCb(this.loginEl);
    }

    _setCallbackCb(linkA) {
        if (!linkA || !linkA.href) return;
        const link = new URL(linkA.href);
        link.searchParams.set('cb', window.location.href);
        linkA.href = link.href;
    }

    _onCancel() {
        flowManager.complete();
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt300-login-popup');
}
