import { getApiProvider } from '../../../../../libs/api-provider';
import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { closeLoader, emptyElement, htmlToElement, openLoader } from '../../../../../libs/utils';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';

export default class ListingComp extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.cardType = this.root.dataset.cardType;
        this.filtersWrapper = this._dEl('filtersWrapper');
        this.fixedCard = this._dEl('fixedCard');
        this.content = this._dEl('content');
        this.cards = this._dEl('cards');
        this.apiProvider = getApiProvider();

        this._initCards();
        this._addEventListeners();
        this._initFiltersModal();
    }

    async _initCards() {
        if (!this.cardType || this.cardType != 'News') {
            return;
        }
        /* cardType == 'News' */
        const user = await getUserService().getUser();
        this._loadCards({
            page: 1,
            cooperative: !user ? 'cnd' : ['cnd', user.newMappedCooperative?.trim().toLowerCase()],
        });
    }

    _initFiltersModal() {
        if (this.filtersWrapper && this.root.dataset.initialFilters) {
            const filtersData = JSON.parse(this.root.dataset.initialFilters);
            this._emit('reloadFilters', filtersData);
        }
    }

    _addEventListeners() {
        this._addListener(
            'rtPageChanged',
            async (event) => {
                const page = event.data.page;
                this._scrollToTop();
                await this._loadCards({ page: page, ...this.filters });
            },
            this.root
        );
        this._addStoreListener('rt111Filters', async (path, data) => {
            this.filters = data.filters;
            await this._loadCards({ page: 1, ...this.filters });
            if (this.filtersWrapper) this.filtersWrapper.focus();
        });
    }

    async _loadCards(data) {
        openLoader('main');
        const url = this.loaderEndpoint;
        try {
            const html = await this.apiProvider.loaderGet(url, data);
            this._appendRecipes(html);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    _appendRecipes(html) {
        emptyElement(this.content);
        if (this.filtersWrapper) emptyElement(this.filtersWrapper);
        if (this.fixedCard) {
            this.fixedCard.remove();
            this.fixedCard = null;
        }

        const element = htmlToElement(html);
        const cards = element.querySelector(this._el('cards', true));
        const pagination = element.querySelector(this._el('paginator', true));
        const filters = element.querySelector(this._el('filters', true));

        this.content.appendChild(cards);
        this.content.appendChild(pagination);

        if (this.filtersWrapper) {
            const filtersData = JSON.parse(element.dataset.filters);
            this.filtersWrapper.appendChild(filters);
            this._emit('reloadFilters', filtersData);
        }

        this._emit('forceUpdateLazy', true); // ha ancora senso?
    }

    _scrollToTop() {
        /* offset = header overall height */
        const offset = window.innerWidth < BREAKPOINTS.l ? 112 : 178 + 24;
        const top = this.filtersWrapper?.offsetTop || this.content.offsetTop;
        const scrollY = Math.max(top, offset) - offset;
        window.scrollTo(0, scrollY);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt111-listing');
}
