import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';

export default class Radio extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.inputs = this._dEl('input', true);
        this.labels = this._dEl('radioLabel', true);
        this.emptyInput = this._dEl('empty');
        setTimeout(() => {
            if (this.emptyInput) this.emptyInput.checked = false;
        }, 500);
        this.groupLabel = this._dEl('label');
        this.error = this._dEl('error');

        this._initEmptyInput();
        this._addEventListeners();
    }

    _getInput() {
        return this.inputs.filter((radio) => radio.checked)[0];
    }
    _initEmptyInput() {
        if (!this._getInput()) this.emptyInput.checked = true;
    }

    _addEventListeners() {

        this.labels.forEach((label) => {
            this._addListener(
                'keydown',
                (event) => {
                    if (!(event.key == 'Enter')) return;
                    label.click();
                    label.focus();
                },
                label
            );
        });

        this.inputs.forEach((input) => {
            this._addListener(
                'change',
                (event) => {
                    event.preventDefault();
                    this._checkState();
                    this._changedInput();
                },
                input
            );
            this._addListener(
                'invalid',
                () => {
                    this.setState('error');
                    this._requireField();
                },
                input,
                true
            );
        });

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
            },
            this.root,
            true
        );
    }

    getLabel() {
        return this.groupLabel;
    }

    getName() {
        return this.root.dataset.name;
    }

    getValue() {
        const checked = this.inputs.filter((radio) => radio.checked);
        return checked.length <= 0 ? '' : checked[0].value;
    }

    isValid() {
        if (this.emptyInput.required && this.emptyInput.checked) return false;
        return !this.emptyInput.required || (this.emptyInput.required && this.getValue());
    }

    reset() {
        this.inputs.forEach((input) => {
            input.checked = false;
        });
        this.emptyInput.checked = true;
        this.setState('');
    }

    enable() {
        this.root.classList.remove(this._mod('disabled'));
    }
    disable() {
        if (this.root.classList.contains(this._mod('disabled'))) return;
        this.root.classList.add(this._mod('disabled'));
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('rt004-radio');
}
