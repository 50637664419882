import Component from '../../../../../libs/components/component';
import {getRegister} from '../../../../../libs/register';
import './style.scss';

export default class CardsFaq extends Component {
    constructor(name, root) {
        super(name, root);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt157-cards-faq');
}
