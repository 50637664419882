import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { makeid } from '../../../../../libs/utils';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';

export default class Filter extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();

        this.btn = this._dEl('btn');
        this.count = this._dEl('count');
        this.SHOW_COUNT = this._mod('showCount');
        this.title = this.root.dataset.title;

        this._generateFilterPopup();
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                this._manageFilterPopup();
                event.stopPropagation();
            },
            this.btn
        );

        /* click outside the popup */
        this._addListener(
            'click',
            (event) => {
                const target = event.target;
                if (target.closest(this.getSelector()) || target.closest('.rt032-filter-popup')) return;
                this.root.classList.remove('open');
            },
            document
        );

        this._addListener(
            'resize',
            () => {
                if (window.innerWidth < BREAKPOINTS.l) {
                    /* mobile */
                    this._close();
                }
            },
            window
        );
    }

    _manageFilterPopup() {
        if (this.isOpen()) {
            this.root.classList.remove('open');
            this.btn.blur();
            this._callFilterPopup(this.root, this.popupId, true);
            return;
        }
        this._closeOthers();
        this.root.classList.add('open');
        this._callFilterPopup(this.root, this.popupId);
        this._emit('rt150ExplodeFilters', {
            filterName: this.title,
        });
    }

    isOpen() {
        return this.root.classList.contains('open');
    }

    _close() {
        this.root.classList.remove('open');
    }
    _open() {
        this.root.classList.add('open');
    }

    _callFilterPopup(filter, popupId, forceClose = false) {
        const event = new CustomEvent('showFilterPopup', { bubbles: true });
        event.data = {
            filter: filter,
            forceClose: forceClose,
            title: this.title,
        };
        document.getElementById(popupId).dispatchEvent(event);
    }

    _closeOthers() {
        // blur other filter buttons
        Array.from(document.querySelectorAll(`${this.getSelector()}`)).forEach((other) => {
            other.classList.remove('open');
            other.querySelector('button').blur();
        });
        // close other popups
        const all = Array.from(document.querySelectorAll('.rt032-filter-popup.open'));
        for (const other of all) {
            if (other.id == this.popupId) continue;
            this._callFilterPopup(null, other.id, true);
        }
    }

    setCount(count) {
        this.count.innerText = count;
        if (count <= 0) {
            this.root.classList.remove(this.SHOW_COUNT);
            return;
        }
        if (this.root.classList.contains(this.SHOW_COUNT)) return;
        this.root.classList.add(this.SHOW_COUNT);
    }

    async _generateFilterPopup() {
        if (!this.root.dataset.category) {
            console.error(`Missing filter category for filter "${this.btn.innerText}"`);
            return;
        }

        if (!this.root.dataset.filters) {
            console.error(`Could not generate filter popup for filter "${this.btn.innerText}"`);
            return;
        }

        try {
            this.category = this.root.dataset.category;
            this.filters = JSON.parse(this.root.dataset.filters);
        } catch (error) {
            console.error(`Error during parsing of filters for filter "${this.btn.innerText}"`);
            return;
        }

        const popup = document.querySelector(`.rt032-filter-popup[data-filter-id='${this.root.id}']`);
        if (popup) {
            popup.remove();
        }

        const filterPopupHtml = (await import('../rt032-filter-popup/rt032-filter-popup.html')).default;
        const id = makeid(10);
        const filterPopupData = {
            uniqueId: id,
            filterId: this.root.id,
            filterCategory: this.category,
            filters: this.filters,
            labels: {
                reset: getDictionary().get('Reset'),
                apply: getDictionary().get('Apply'),
            },
            searchable: this.root.hasAttribute('data-searchable'),
            searchLabel: this.root.dataset.searchLabel,
        };
        const filterPopup = runTemplate(filterPopupHtml, filterPopupData);

        const mainPage = document.body.querySelector('.rs1-page');
        mainPage.appendChild(filterPopup);

        this.popupId = id;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt031-filter');
}
