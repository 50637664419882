import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class Faq extends Component {
    constructor(name, root) {
        super(name, root);
        this._checkHide();

        this.tabList = this._dEl('tabs');
        this.tabs = Array.from(this.tabList.children);
        this.content = this._dEl('content', true);

        this.tabFocus = this.root.dataset.activeTab || 0;
        this.tabs[this.root.dataset.activeTab].setAttribute('tabindex', '0');
        this._selectTab(this.tabs[this.root.dataset.activeTab], this.content[this.root.dataset.activeTab], true);

        this._addListeners();
    }

    _addListeners() {
        // mettere dimensione -3000px e dimensione w 6000
        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }
            this.tabs[this.tabFocus].setAttribute('tabindex', '0');
            this.tabs[this.tabFocus]?.focus();
        });
    }

    _checkHide() {
        if (!this.root.hasAttribute('data-hide')) return;
        /* hide self */
        const event = new CustomEvent('hideLayoutItem', { bubbles: true });
        event.data = {
            hide: true,
        };
        this.root.dispatchEvent(event);
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }

    _getTabPanelFromTab(tab) {
        return this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt062-faq');
}
