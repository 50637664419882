import PopupComponent from '../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../libs/flow-manager';
import { getRegister } from '../../../../../libs/register';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import './style.scss';

export default class RefreshCPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.trackingManager = getTrackingManager();
    }

    /* override */
    _onOpen() {
        super._onOpen();
        //track pageView popup-c-refresh
        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up partecipazione concorso',
            },
        });
    }

    /* override */
    _onClose() {
        super._onClose();
        this.trackingManager.track(this.root, {
            event: 'uscitaPopupRefresh',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up partecipazione concorso - Esci',
            },
        });
    }

    _onCancel() {
        flowManager.complete();
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt303-refresh-c-popup');
}
