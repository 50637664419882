import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getApiProvider } from '../../../../../libs/api-provider';

export default class ItalyMap extends Component {
    constructor(name, root) {
        super(name, root);
        this.map = this._dEl('map');
        this.regions = this._dEl('regionList');
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.arrow = this._dEl('arrow');
        this.bottom = this._dEl('bottom');
        this.apiProvider = getApiProvider();
        this._doLogic();
    }

    async _doLogic() {
        await this._loadMap();
        this._removeUnusedRegions();
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener('click', (e) => {
            this.regionMap = e.target.closest('.regionMap');
            this.region = e.target.closest(this._el('region', true));
            this.accordion = e.target.closest(this._el('heading', true));

            //if not regionMap, no region or no accordion
            if (!this.regionMap && !this.region && !this.accordion) {
                return;
            }

            e.preventDefault();

            if (this.accordion != null) {
                this.arrow.classList.toggle(this._elMod('arrow', 'close'));
                this.bottom.classList.toggle(this._elMod('bottom', 'close'));
                this.regions.classList.toggle(this._elMod('regionList', 'close'));
                return;
            }

            if (this.regionMap) {
                this.dataId = this.regionMap.dataset.map;
                this.compId = this.regionMap.dataset.comp;
                this.region = document.getElementById(this.dataId);
            } else if (this.region) {
                this.dataId = this.region.dataset.map;
                this.compId = this.region.dataset.comp;
                this.regionMap = document.getElementById(this.dataId);
            }

            if (this.prevReg != null && this.prevReg != this.region) {
                this.prevReg.classList.remove(this._elMod('region', 'selected'));
                this.prevMap.classList.remove('regionMap--selected');
                this.prevComp.classList.remove(this._elMod('lancio', 'selected'));
            }

            this.comp = document.getElementById(this.compId);
            if (!this.comp) return;
            this.regionMap.classList.toggle('regionMap--selected');
            this.region.classList.toggle(this._elMod('region', 'selected'));
            this.comp.classList.toggle(this._elMod('lancio', 'selected'));
            const offset = window.innerWidth < this.BREAKPOINT_L ? 164 : 400;
            window.scrollBy(0, this.bottom.getBoundingClientRect().top - offset);

            this.prevMap = this.regionMap;
            this.prevReg = this.region;
            this.prevComp = this.comp;
        });
    }

    _removeUnusedRegions() {
        const regionListItems = this._dEl('region', true);
        const cardIds = new Set(this._dEl('lancio', true).map((card) => card.id));
        for (const item of regionListItems) {
            if (cardIds.has(item.dataset.comp)) continue;
            item.remove();
        }
    }

    async _loadMap() {
        const url = this.map.dataset.mapPath;
        try {
            const html = await this.apiProvider.textGet(url);
            this._appendMap(html);
        } catch (error) {
            console.error(error);
        }
    }

    _appendMap(data) {
        /* parse map */
        const parser = new DOMParser();
        const map = parser.parseFromString(data, 'image/svg+xml');
        this.map.append(map.firstChild);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt110-italy-map');
}
