import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { flyerData, flyersData, generateShareLinks, generateTrackVolantinoInfo } from '../../../../../libs/utils';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class CarrotBannerFsf extends Component {
    constructor(name, root) {
        super(name, root);

        this.FLYERS_OPEN = this._elMod('bottom', 'flyersOpen');
        this.TEXT_OPEN = this._elMod('bottom', 'textOpen');
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.FLYER_HIDDEN = this._elMod('flyer', 'hidden');
        this.LOAD_MORE_HIDDEN = this._elMod('loadMore', 'hidden');
        this.NO_SWIPE = this._mod('noSwipe');
        this.trackingManager = getTrackingManager();

        this.flyerVariant = this.root.dataset.flyerVariant;
        if (!this.flyerVariant) {
            console.error('Missing flyerVariant');
            return;
        }

        if (this.flyerVariant == '1/3') {
            this.flyerVariant = this._getAnacanIdParam() ? '1' : '3';
            this.root.setAttribute('data-flyer-variant', this.flyerVariant); // set on html
        }

        this.store = this.root.hasAttribute('data-store') ? JSON.parse(this.root.dataset.store) : null;
        this.flyer = this.root.hasAttribute('data-flyer') ? JSON.parse(this.root.dataset.flyer) : null;
        this._setTrackingConsts();

        this.flyersHeading = this.root.dataset.flyersHeading || '';
        this.downloadLabel = this.root.dataset.downloadLabel || null;
        this.shareLabel = this.root.dataset.shareLabel || null;
        this.readMoreLabel = this.root.dataset.readMoreLabel || null;
        this.readMoreText = this.root.dataset.readMoreText || null;

        this._doLogic();
    }

    async _doLogic() {
        if (this._getAnacanIdParam()) {
            try {
                this.store = await getApiProvider().getPointOfServiceByAnacanId({ anacanId: this._getAnacanIdParam() });
            } catch (e) {
                console.warn('Cannot get store ' + this._getAnacanIdParam());
            }
        }

        await this._renderContent();
        this._getElements();
        this.flyers = this._dEl('flyer', true);
        if (this.flyers && this.flyers.length > 3) this._showLoadMore();
        if (this.flyers && this.flyers.length > 2) {
            await this._initSwiper();
            this._addSwiperListeners();
            this._manageSwiperMobile();
        } else {
            this.root.classList.add(this.NO_SWIPE);
        }
        this._addEventListeners();
    }

    async _renderContent() {
        if (!this.flyerVariant) return;
        if (!this.flyer) return;

        const flyerVariant1Html = (await import('./partials/flyer-variant-1.html')).default;
        const flyerVariant2Html = (await import('./partials/flyer-variant-2.html')).default;
        const flyerVariant3Html = (await import('./partials/flyer-variant-3.html')).default;

        switch (this.flyerVariant) {
            case '1': {
                const focusedFlyer = flyerData(this.flyer);
                const link = {
                    href: focusedFlyer.downloadLink.href,
                    target: focusedFlyer.downloadLink.target,
                    download: focusedFlyer.downloadLink.download,
                    'data-link-tracking': JSON.stringify(this.DATA_LINK_TRACKING_DOWNLOAD),
                };
                const data = {
                    imageUrl: focusedFlyer.image,
                    imageAlt: focusedFlyer.imageAlt,
                    validity: focusedFlyer.validity,
                    title: focusedFlyer.feTitle,
                    flyerLink: link,
                    downloadLabel: this.downloadLabel,
                    shareLabel: this.shareLabel,
                    flyersHeading: this.flyersHeading,
                    otherFlyers:
                        this.store.volantini.length >= 2
                            ? flyersData(this.store).filter((other) => other.id !== focusedFlyer.id)
                            : null,
                    disabledDownload: focusedFlyer.disabledDownload,
                };
                if (this.store.volantini.length >= 2) {
                    data.otherFlyers.forEach((item) => {
                        const link = {
                            href: item.link.href,
                            target: item.link.target,
                            'data-link-tracking': JSON.stringify(this.DATA_LINK_TRACKING_OTH_FLYER),
                        };
                        item.link = link;
                        item.title = item.feTitle;
                    });
                }
                const contentEl = runTemplate(flyerVariant1Html, data);
                Array.from(contentEl.children).forEach((child) => this.root.append(child));
                this.shareId = await generateShareLinks(focusedFlyer.link.href, focusedFlyer.title);
                break;
            }
            case '2': {
                const flyer = flyerData(this.flyer);
                const link = {
                    href: flyer.downloadLink.href,
                    target: flyer.downloadLink.target,
                    download: flyer.downloadLink.download,
                    'data-link-tracking': JSON.stringify(this.DATA_LINK_TRACKING_DOWNLOAD),
                };
                const data = {
                    imageUrl: flyer.image,
                    imageAlt: flyer.imageAlt,
                    validity: flyer.validity,
                    title: flyer.feTitle,
                    flyerLink: link,
                    downloadLabel: this.downloadLabel,
                    shareLabel: this.shareLabel,
                    readMoreLabel: this.readMoreLabel,
                    readMoreText: this.readMoreText,
                    disabledDownload: flyer.disabledDownload,
                };
                const contentEl = runTemplate(flyerVariant2Html, data);
                this.root.append(contentEl);
                this.shareId = await generateShareLinks(flyer.link.href, flyer.title);
                break;
            }
            case '3': {
                const flyer = flyerData(this.flyer);
                const link = {
                    href: flyer.downloadLink.href,
                    target: flyer.downloadLink.target,
                    download: flyer.downloadLink.download,
                    'data-link-tracking': JSON.stringify(this.DATA_LINK_TRACKING_DOWNLOAD),
                };
                const data = {
                    imageUrl: flyer.image,
                    imageAlt: flyer.imageAlt,
                    validity: flyer.validity,
                    title: flyer.feTitle,
                    flyerLink: link,
                    downloadLabel: this.downloadLabel,
                    shareLabel: this.shareLabel,
                    disabledDownload: flyer.disabledDownload,
                };
                const contentEl = runTemplate(flyerVariant3Html, data);
                this.root.append(contentEl);
                this.shareId = await generateShareLinks(flyer.link.href, flyer.title);
                break;
            }
            default:
                break;
        }
    }

    _getElements() {
        if (!this.flyerVariant) return;

        /* common elements */
        this.share = this._dEl('share').querySelector(this._el('link', true));

        /* specific elements */
        switch (this.flyerVariant) {
            case '1': {
                this.bottom = this._dEl('bottom');
                this.heading = this._dEl('heading');
                this.flyersWrapper = this._dEl('flyers');
                this.carousel = this._dEl('carousel');
                this.track = this._dEl('track');
                this.prev = this._dEl('prev');
                this.next = this._dEl('next');
                this.loadMore = this._dEl('loadMore');
                break;
            }
            case '2': {
                this.bottom = this._dEl('bottom');
                this.heading = this._dEl('heading');
                this.textWrapper = this._dEl('readMoreText');
                break;
            }
            case '3': {
                break;
            }
            default:
                break;
        }
    }

    _addEventListeners() {
        if (!this.flyerVariant) return;

        /* common listeners */
        this._addListener(
            'click',
            (e) => {
                e.preventDefault();
                if (!this.shareId) return;
                const event = new CustomEvent('showShareLinks', { bubbles: true });
                event.data = {
                    root: this._dEl('share').querySelector('span:first-child'),
                };
                document.getElementById(this.shareId).dispatchEvent(event);
                e.stopPropagation();
                if (this.flyerVariant == '1')
                    this.trackingManager.track(this.root, {
                        funnel: {
                            stepFunnel: 'Interazioni con pagina volantino',
                        },
                        event: 'Condividi',
                        CustomLink: 'Click Condividi',
                    });

                if (this.flyerVariant == '2')
                    this.trackingManager.track(this.root, {
                        funnel: {
                            nomeFunnel: 'Volantino Bassi e Fissi',
                            stepFunnel: 'Interazioni con pagina volantino',
                        },
                        event: 'Condividi',
                        CustomLink: 'Click Condividi',
                        volantinoInfo: {
                            ...generateTrackVolantinoInfo(this.flyer),
                        },
                    });

                if (this.flyerVariant == '3')
                    this.trackingManager.track(this.root, {
                        funnel: {
                            nomeFunnel: 'Volantino',
                            stepFunnel: 'Interazioni con pagina volantino',
                        },
                        event: 'Condividi',
                        CustomLink: 'Click Condividi',
                        volantinoInfo: {
                            ...generateTrackVolantinoInfo(this.flyer),
                        },
                    });
            },
            this.share
        );

        /* specific listeners */
        switch (this.flyerVariant) {
            case '1': {
                this._addListener(
                    'click',
                    () => {
                        if (window.innerWidth >= this.BREAKPOINT_L) return;
                        this.bottom.classList.toggle(this.FLYERS_OPEN);
                    },
                    this.heading
                );
                this._addListener(
                    'click',
                    () => {
                        let step = 3;
                        let hidden = this.flyers.filter((flyer) => flyer.classList.contains(this.FLYER_HIDDEN));
                        if (hidden.length < step) {
                            hidden.forEach((flyer) => flyer.classList.remove(this.FLYER_HIDDEN));
                            this._hideLoadMore();
                        } else {
                            if (hidden.length == step) this._hideLoadMore();
                            while (step > 0) {
                                hidden[3 - step--].classList.remove(this.FLYER_HIDDEN);
                            }
                        }
                    },
                    this.loadMore
                );
                break;
            }
            case '2': {
                this._addListener(
                    'click',
                    () => {
                        this.bottom.classList.toggle(this.TEXT_OPEN);
                        this._updateTextWrapperHeight();
                    },
                    this.heading
                );
                this._addListener(
                    'resize',
                    () => {
                        this._updateTextWrapperHeight();
                    },
                    window
                );
                break;
            }
            case '3': {
                break;
            }
            default:
                break;
        }
    }

    async _initSwiper() {
        const swiperConfig = {
            direction: 'horizontal',
            slidesPerView: 'auto',
            wrapperClass: this._el('track'),
            slideClass: this._el('flyer'),
            spaceBetween: 16,
            slidesOffsetBefore: 3,
            slidesOffsetAfter: 3,
            mousewheel: {
                eventsTarget: this.carousel,
                releaseOnEdges: true,
            },
        };
        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, swiperConfig);
    }

    _addSwiperListeners() {
        this._addListener(
            'click',
            () => {
                this.swiper.slidePrev(200);
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.prev
        );
        this._addListener(
            'click',
            () => {
                this.swiper.slideNext(200);
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.next
        );
        this.swiper.on('reachBeginning', () => {
            this._disableEl(this.prev, 'prev');
            this._enableEl(this.next, 'next');
        });
        this.swiper.on('reachEnd', () => {
            this._disableEl(this.next, 'next');
            this._enableEl(this.prev, 'prev');
        });
        this.swiper.on('progress', (swiper, progress) => {
            if (progress == 0) {
                this._disableEl(this.prev, 'prev');
                return;
            }
            if (progress == 1) {
                this._disableEl(this.next, 'next');
                return;
            }
            this._enableEl(this.next, 'next');
            this._enableEl(this.prev, 'prev');
        });
        this._addListener(
            'resize',
            () => {
                this._manageSwiperMobile();
            },
            window
        );
    }

    _manageSwiperMobile() {
        if (window.innerWidth < this.BREAKPOINT_L) {
            this.swiper.slideTo(0);
            this.swiper.disable();
        } else {
            this.swiper.enable();
        }
    }

    _updateTextWrapperHeight() {
        if (window.innerWidth >= this.BREAKPOINT_L) {
            this.textWrapper.removeAttribute('style');
            return;
        }
        this.textWrapper.style.maxHeight = this.bottom.classList.contains(this.TEXT_OPEN)
            ? `${this.textWrapper.scrollHeight + 16}px`
            : '0px';
    }

    _hideLoadMore() {
        if (this.loadMore.classList.contains(this.LOAD_MORE_HIDDEN)) return;
        this.loadMore.classList.add(this.LOAD_MORE_HIDDEN);
    }
    _showLoadMore() {
        this.loadMore.classList.remove(this.LOAD_MORE_HIDDEN);
    }

    _disableEl(el, elName) {
        if (!el || !elName) return;
        if (!el.classList.contains(this._elMod(elName, 'disabled'))) el.classList.add(this._elMod(elName, 'disabled'));
    }
    _enableEl(el, elName) {
        if (!el || !elName) return;
        el.classList.remove(this._elMod(elName, 'disabled'));
    }

    _getAnacanIdParam() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('anacanId');
    }

    _setTrackingConsts() {
        this.DATA_LINK_TRACKING_DOWNLOAD = {
            event: 'DownloadVolantino',
            CustomLink: 'Click Download Volantino',
            volantinoInfo: {
                TipologiaVolantino: 'Volantino scaricato',
            },
            funnel: {
                stepFunnel: 'Consultazione volantino',
            },
        };

        this.DATA_LINK_TRACKING_OTH_FLYER = {
            event: 'CardVolantino',
            CustomLink: 'Click Vai al Volantino',
            volantinoInfo: {
                ...generateTrackVolantinoInfo(),
            },
            funnel: { stepFunnel: 'Interazioni con pagina volantino' },
        };

        this.TRACKING_SLIDE_LATERALE = {
            funnel: {
                stepFunnel: 'Slide Laterale Card Volantino',
            },
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
        };
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt102-carrot-banner-fsf');
}
