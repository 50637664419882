import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { flyersData, generateTrackNegozioInfo, generateTrackVolantinoInfo } from '../../../../../libs/utils';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class CarrotBannerHsf extends Component {
    constructor(name, root) {
        super(name, root);

        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.FLYER = this._el('flyer');
        this.FLYER_HIDDEN = this._elMod('flyer', 'hidden');
        this.LOAD_MORE_HIDDEN = this._elMod('loadMore', 'hidden');
        this.NO_SWIPE = this._mod('noSwipe');

        this.trackingManager = getTrackingManager();
        this._setTrackingConsts();

        this._doLogic();
    }

    async _doLogic() {
        this._getElements();
        await this._renderFlyers();
        this.flyers = this._dEl('flyer', true);
        if (this.flyers && this.flyers.length > 3) this._showLoadMore();
        if (this.flyers && this.flyers.length > 1) {
            await this._initSwiper();
            this._addSwiperListeners();
            this._manageSwiperMobile();
        } else {
            this.root.classList.add(this.NO_SWIPE);
        }
        this._addEventListeners();
    }

    _getElements() {
        this.store = this.root.hasAttribute('data-store') ? JSON.parse(this.root.dataset.store) : null;
        this.loadMore = this._dEl('loadMore');
        this.flyersWrapper = this._dEl('flyers');
        this.carousel = this._dEl('carousel');
        this.track = this._dEl('track');
        this.prev = this._dEl('prev');
        this.next = this._dEl('next');
    }

    async _renderFlyers() {
        if (!this.store || !this.store.anacanId) return;

        const flyerCall = (await import('../rt215-card-flyer/rt215-card-flyer.html')).default;
        const flyers = flyersData(this.store);
        for (let i = 0; i < flyers.length; i++) {
            const flyer = flyers[i];
            let flyerData = {
                ...flyer,

                variant: '2',
                uniqueId: flyer.id,
                yumpuId: flyer.yumpuId,
                extraClasses: `${this.FLYER} ${i > 2 ? this.FLYER_HIDDEN : ''}`,
                imageUrl: flyer.image,
                imageAlt: flyer.imageAlt,
                validity: flyer.validity,
                title: flyer.feTitle,
                link: {
                    target: '_blank',
                    ...flyer.link,
                    'data-link-tracking': JSON.stringify(this.DATA_LINK_TRACKING),
                },
                linkLabel: flyer.linkLabel,
            };
            const flyerEl = runTemplate(flyerCall, flyerData);
            this.track.appendChild(flyerEl);
        }
    }

    async _initSwiper() {
        const swiperConfig = {
            direction: 'horizontal',
            slidesPerView: 'auto',
            wrapperClass: this._el('track'),
            slideClass: this._el('flyer'),
            spaceBetween: 16,
            slidesOffsetBefore: 3,
            slidesOffsetAfter: 3,
            scrollbar: {
                el: this._dEl('scrollbar'),
                hide: false,
                draggable: true,
                dragClass: this._el('scrollbarDrag'),
            },
            mousewheel: {
                eventsTarget: this.carousel,
                releaseOnEdges: true,
            },
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, swiperConfig);
    }

    _addSwiperListeners() {
        this._addListener(
            'click',
            () => {
                this.swiper.slidePrev(200);
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.prev
        );
        this._addListener(
            'click',
            () => {
                this.swiper.slideNext(200);
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.next
        );
        this.swiper.on('reachBeginning', () => {
            this._disableEl(this.prev, 'prev');
            this._enableEl(this.next, 'next');
        });
        this.swiper.on('reachEnd', () => {
            this._disableEl(this.next, 'next');
            this._enableEl(this.prev, 'prev');
        });
        this.swiper.on('progress', (swiper, progress) => {
            if (progress == 0) {
                this._disableEl(this.prev, 'prev');
                return;
            }
            if (progress == 1) {
                this._disableEl(this.next, 'next');
                return;
            }
            this._enableEl(this.next, 'next');
            this._enableEl(this.prev, 'prev');
        });
        this._addListener(
            'resize',
            () => {
                this._manageSwiperMobile();
            },
            window
        );
    }

    _manageSwiperMobile() {
        if (window.innerWidth < this.BREAKPOINT_L) {
            this.swiper.slideTo(0);
            this.swiper.disable();
        } else {
            this.swiper.enable();
        }
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                let step = 3;
                let hidden = this.flyers.filter((flyer) => flyer.classList.contains(this.FLYER_HIDDEN));
                if (hidden.length < step) {
                    hidden.forEach((flyer) => flyer.classList.remove(this.FLYER_HIDDEN));
                    this._hideLoadMore();
                } else {
                    if (hidden.length == step) this._hideLoadMore();
                    while (step > 0) {
                        hidden[3 - step--].classList.remove(this.FLYER_HIDDEN);
                    }
                }
            },
            this.loadMore
        );
    }

    _hideLoadMore() {
        if (this.loadMore.classList.contains(this.LOAD_MORE_HIDDEN)) return;
        this.loadMore.classList.add(this.LOAD_MORE_HIDDEN);
    }
    _showLoadMore() {
        this.loadMore.classList.remove(this.LOAD_MORE_HIDDEN);
    }

    _disableEl(el, elName) {
        if (!el || !elName) return;
        if (!el.classList.contains(this._elMod(elName, 'disabled'))) el.classList.add(this._elMod(elName, 'disabled'));
    }
    _enableEl(el, elName) {
        if (!el || !elName) return;
        el.classList.remove(this._elMod(elName, 'disabled'));
    }

    _setTrackingConsts() {
        this.DATA_LINK_TRACKING = {
            event: 'CardVolantino',
            CustomLink: 'Click Vai al volantino',
            negozioInfo: { ...generateTrackNegozioInfo() },
            volantinoInfo: {
                ...generateTrackVolantinoInfo(),
            },
        };
        this.TRACKING_SLIDE_LATERALE = {
            funnel: {
                nomeFunnel: 'Homepage',
                stepFunnel: 'Slide Laterale Card Volantino',
            },
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
            negozioInfo: {
                ...generateTrackNegozioInfo(),
            },
        };
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt102-carrot-banner-hsf');
}
