import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { closeLoader, openLoader } from '../../../../../libs/utils';
import { getApiProvider } from '../../../../../libs/api-provider';

export default class CardProductFlyer extends Component {
    constructor(name, root) {
        super(name, root);

        this.ctaFavorite = this._dEl('favorite');
        this.productId = this.root.dataset.code;
        this.idProdottoNegozioUtente = null;

        this.flowManager = getFlowManager();
        this.trackingManager = getTrackingManager();
        this.apiProvider = getApiProvider();
        this.userService = getUserService();

        this._addListeners();
        this._addStoreListeners();
    }

    _addStoreListeners() {
        this._addStoreListener('PropsCurrentFlyer', (path, data) => {
            const { anacanId, disaggregatedId } = data;
            this.disaggregatedId = disaggregatedId;
            this.anacanId = anacanId;

            // after get flyer props, check favorite
            this._checkFavorite();
        });
    }

    _addListeners() {
        if (this.ctaFavorite) this._addListener('click', () => this._setFavorite(), this.ctaFavorite);
    }

    async _setFavorite() {
        const favoriteStore = await this.userService.getStore();
        const savedStores = await this.userService.getSavedStores();
        const savedAnacanIds = savedStores.map((item) => item.anacanId);
        if (savedAnacanIds.includes(this.anacanId)) {
            await this._addRemoveDisFavoriteProduct();
        } else if (favoriteStore.anacanId == this.anacanId) {
            await this._addStoreAndDisFavoriteProduct();
        } else {
            this.flowManager.startFlow({
                flowName: 'add-store-popup',
                flowSteps: [{ name: 'add-store-popup' }],
                onComplete: async () => {
                    await this._addStoreAndDisFavoriteProduct();
                },
                initialData: { anacanId: this.anacanId },
            });
        }
    }

    async _sendTracking(add = true) {
        const isNotLogged = (await this.userService.getUser()) == null;

        this.trackingManager.track(this.root, {
            funnel: {
                stepFunnel: 'Interazioni con prodotti disaggregato',
            },
            CustomLink: isNotLogged ? 'Callout registrazione utente non loggato' : add ? 'Aggiunta' : 'Rimozione',
            prodottoInfo: {
                componente: 'Card prodotto',
                evidenza: this.root.dataset.evidenza ? 'SI' : 'NO',
                nome: 'data.nome',
                ean: 'data.ean',
                marchio: 'data.brand',
                sottomarchio: '',
                categoria: 'data.category',
                sottocategoria: 'data.subCategory',
                meccanicaPromozionale: 'data.meccanica',
                tipologiaPromozione: 'data.meccanica',
                tagPromozione: 'data.tagPromo',
                cardistaPromozione: 'data.cardistaPromo',
            },
        });
    }

    async _addRemoveDisFavoriteProduct() {
        openLoader('main');
        try {
            if (this.idProdottoNegozioUtente) {
                // delete
                const res = await getUserService().removeDisFavoriteProduct(
                    this.anacanId,
                    this.idProdottoNegozioUtente
                );
                if (res) {
                    this._sendTracking(false);
                    this._checkFavorite();
                }
            } else {
                // add
                const res = await getUserService().addDisFavoriteProduct(
                    this.anacanId,
                    this.disaggregatedId,
                    this.productId
                );
                if (res) {
                    this._sendTracking(true);
                    this._checkFavorite();
                }
            }
        } catch (err) {
            console.warn(err);
        } finally {
            closeLoader('main');
        }
    }

    /**
     * Add the current store to saved stores and product to favorites
     */
    async _addStoreAndDisFavoriteProduct() {
        try {
            openLoader('main');
            const addStore = await this.userService.addSavedStore(this.anacanId);
            if (addStore) {
                await getUserService().addDisFavoriteProduct(this.anacanId, this.disaggregatedId, this.productId);
                this._checkFavorite();
            }
        } catch (err) {
            console.warn(err);
        } finally {
            closeLoader('main');
        }
    }

    async _checkFavorite() {
        if (!this.anacanId || this.anacanId == '' || (await this.userService.getUser()) == null) {
            return;
        }

        // If user logged show heart
        this.ctaFavorite.classList.add(this._elMod('favorite', 'show'));

        this.cta =  getRegister().getClass(this.ctaFavorite);
        this.idProdottoNegozioUtente = await getUserService().checkDisFavoriteProduct(
            this.anacanId,
            this.disaggregatedId,
            this.productId
        );
        if (this.idProdottoNegozioUtente) {
            this.cta.setIcon('ec-icon-like-on');
        } else {
            this.cta.setIcon('ec-icon-like-off');
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt213-card-product-flyer');
}
