import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { BREAKPOINTS } from '../../../../../libs/constants';

export default class Modal extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();

        this.page = this.register.getClass(document.querySelector('.rs1-page'));
        this.modalName = this.root.dataset.name;
        this.backdrop = this._dEl('backdrop');
        this.rightContent = this._dEl('rightContent');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                if (!event.target.matches(`[data-open-modal='${this.modalName}']`)) return;
                event.preventDefault();
                this.open();
            },
            document
        );
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.cancel();
            },
            this.backdrop
        );
        this._addListener(
            'click',
            (event) => {
                if (!event.target.matches('[data-close-modal]')) return;
                event.preventDefault();
                if (
                    window.innerWidth < BREAKPOINTS.l &&
                    this.rightContent &&
                    this.rightContent.classList.contains(this._elMod('rightContent', 'forceShow'))
                ) {
                    this.rightContent.classList.remove(this._elMod('rightContent', 'forceShow'));
                    return;
                }
                this.cancel();
            },
            this.root
        );
    }

    open() {
        const otherModalEl = document.querySelector(this._mod('open', true));
        if (otherModalEl) {
            this.register.getClass(otherModalEl).close(true);
            this.root.classList.add(this._mod('noTransition'));
            this.root.classList.add(this._mod('open'));
            this.root.offsetHeight;
            this.root.classList.remove(this._mod('noTransition'));
        } else {
            this.root.classList.add(this._mod('open'));
        }
        this.page.setBlockScroll();
        this._sendOpenEvent();
    }

    close(force = false) {
        if (!force) {
            this.root.classList.remove(this._mod('open'));
        } else {
            this.root.classList.add(this._mod('noTransition'));
            this.root.classList.remove(this._mod('open'));
            this.root.offsetHeight;
            this.root.classList.remove(this._mod('noTransition'));
        }
        this.page.removeBlockScroll();
        this._sendCloseEvent();
    }

    cancel() {
        this._sendCancelEvent();
        this.close();
    }

    isOpen() {
        return this.root.classList.contains(this._mod('open'));
    }

    showRight() {
        if (!this.rightContent.classList.contains(this._elMod('rightContent', 'forceShow')))
            this.rightContent.classList.add(this._elMod('rightContent', 'forceShow'));
    }

    hideRight() {
        this.rightContent.classList.remove(this._elMod('rightContent', 'forceShow'));
    }

    _sendOpenEvent() {
        const event = new CustomEvent('rcModalOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCloseEvent() {
        const event = new CustomEvent('rcModalClose', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCancelEvent() {
        const event = new CustomEvent('rcModalCancel', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    toggle() {
        if (this.root.classList.contains(this._mod('open'))) {
            this.close();
        } else {
            this.open();
        }
    }

    getModalName() {
        return this.modalName;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.ru002-modal');
}
