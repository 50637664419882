import './style.scss';
import Component from '../../../../../libs/components/component';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';
import { getRegister } from '../../../../../libs/register';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../libs/form-to-json';
import { isPositive } from '../../../../../libs/utils';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { openLoader, closeLoader } from '../../../../../libs/utils';

export default class UpdateDataRefresh extends Component {
    constructor(name, root) {
        super(name, root);
        this.apiProvider = getApiProvider();
        this.myConadApiProvider = getMyConadApiProvider();
        this.userService = getUserService();
        this.dictionary = getDictionary();
        this.register = getRegister();
        this.storeManager = getStoreManager();
        this.trackingManager = getTrackingManager();
        this.flowManager = getFlowManager();
        this.links = this.root.querySelectorAll('a');
        this.consents = [];
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.title = this._dEl('title');
        this.form = this._dEl('form');
        this.submit = this._dEl('submit');
        this.thankYouPageUrl = this.root.dataset.thankYouPageUrl;
        if (!this.thankYouPageUrl) {
            console.warn('Could not get thankYouPageUrl.');
        }
        this.labelIVnonValorizzatoStep = 'Landing page aggiornamento dati - 4 Consenso non valorizzato';
        this.labelIVvalorizzatoStep = 'Landing page aggiornamento dati - 4 Consenso valorizzato';
        this.wrapperAlreadyDoneAll = this._dEl('wrapperAlreadyDoneAll');
        this.paragraphBannerAlreadyDone = this._dEl('paragraphBannerAlreadyDone');
        this.wrapperAlreadyParticipated = this._dEl('wrapperAlreadyParticipated');
        this.bottomTextInformative = this._dEl('bottomTextInformative');
        this.paragraphBannerAlreadyParticipated = this._dEl('paragraphBannerAlreadyPartecipated');
        this.alreadyDoneAllLink = this._dEl('alreadyDoneDiscoverMoreLink');
        this.alreadyParticipatedLink = this._dEl('alreadyPartecipatedDiscoverMoreLink');
        this.userInteract = false;
        this.currentPrivacy = null;

        this.choice;

        this._init();
    }

    async _init() {
        this.userData = await this.userService.getUser();
        const url = this.loaderEndpoint;
        openLoader('main');

        if (this.userData && url) {
            if (this.userData.refresh) {
                let privacyTzTrackingView = this.userData?.privacyTZ
                    ? this.labelIVvalorizzatoStep
                    : this.labelIVnonValorizzatoStep;
                //track pageView Landing
                this.trackingManager.track(this.root, {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel: privacyTzTrackingView,
                    },
                });
                const template = document.querySelector(this._el('templateForm', true));
                const clone = template.content.cloneNode(true);

                // add mails or remove it if necessary
                let mailAddresses = [];
                if (this.userData.mailOcdb != this.userData.email) {
                    mailAddresses.push({
                        label: this.userData.email,
                        value: this.userData.email,
                        selected: !this.userData.mailOcdb,
                    });

                    if (this.userData.mailOcdb) {
                        mailAddresses.push({
                            label: this.userData.mailOcdb,
                            value: this.userData.mailOcdb,
                            selected: false,
                        });
                    }

                    let selectTemplateElement = this.register.getClass(
                        clone.querySelector(this._el('mailAddress', true))
                    );
                    selectTemplateElement.setItems(mailAddresses);
                } else {
                    clone.querySelector(this._el('mailAddress', true)).remove();
                }

                // check telefono or remove it if necessary, TODO: fix regex
                if (
                    this.userData.telefonoCellulare &&
                    this.userData.telefonoCellulare.match(/^([+]{0,1})?([0-9]{0,2})?([0-9]{9,10})$/)
                ) {
                    clone.querySelector(this._el('phoneNumber', true)).remove();
                }

                // check privacy or remove it if necessary
                let showCommonPrivacy = false;
                let show4Privacy = false;

                if (this.userData.privacy1 != 'S' || this.userData.privacy2 != 'S') {
                    showCommonPrivacy = true;
                    show4Privacy = true;
                }

                if (!this.userData.privacyTZ) {
                    show4Privacy = true;
                }

                if (!showCommonPrivacy) {
                    clone.querySelector(this._el('marketingRadio', true)).closest(this._el('box', true)).remove();
                    clone.querySelector(this._el('profilationRadio', true)).closest(this._el('box', true)).remove();
                    clone
                        .querySelector(this._el('coopComunicationRadio', true))
                        .closest(this._el('box', true))
                        .remove();
                }

                if (!show4Privacy) {
                    clone
                        .querySelector(this._el('marketingComunicationPartnerRadio', true))
                        .closest(this._el('box', true))
                        .remove();
                }

                // remove privacy
                if (!show4Privacy && !showCommonPrivacy) {
                    clone.querySelector(this._el('consentsSection', true)).remove();
                }

                // append form
                this.form.appendChild(clone);
                jsonToForm(this.form, {
                    ...this.userData,
                });

                this._initPrivacyAndRegulationText(this.form);
                this._setPrivacyAndRegulation(this.form);

                this._dEl('box', true).forEach((el) => {
                    if (!el.classList.contains(this._el('boxCheckbox'))) {
                        this._initNotEnabledRadioText(el);
                    }
                });

                this._addEventListeners();

                this.title.classList.remove(this._elMod('title', 'hidden'));
                this.form.classList.remove(this._elMod('form', 'hidden'));
                this.submit.classList.remove(this._elMod('submit', 'hidden'));

            } else if (this.userData.refreshAlready) {
                let tempLink = this.alreadyParticipatedLink.cloneNode(true);
                tempLink.classList.remove(this._elMod('discoverMoreLink', 'hidden'));
                this.wrapperAlreadyParticipated.innerHTML = this.wrapperAlreadyParticipated.innerHTML.replace(
                    '$discoverMore',
                    tempLink.outerHTML
                );
                this.wrapperAlreadyParticipated.classList.remove(this._elMod('wrapperAlreadyParticipated', 'hidden'));
                this.bottomTextInformative.classList.add(this._elMod('bottomTextInformative', 'hidden'));

                this.trackingManager.track(this.root, {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel: 'Landing page partecipante',
                    },
                });
            } else {
                let tempLink = this.alreadyDoneAllLink.cloneNode(true);
                tempLink.classList.remove(this._elMod('discoverMoreLink', 'hidden'));
                this.wrapperAlreadyDoneAll.innerHTML = this.wrapperAlreadyDoneAll.innerHTML.replace(
                    '$discoverMore',
                    tempLink.outerHTML
                );
                this.wrapperAlreadyDoneAll.classList.remove(this._elMod('wrapperAlreadyDoneAll', 'hidden'));
                this.bottomTextInformative.classList.add(this._elMod('bottomTextInformative', 'hidden'));

                this._trackNotPartecipatedVariant();
            }
        } else {
            let tempLink = this.alreadyDoneAllLink.cloneNode(true);
            tempLink.classList.remove(this._elMod('discoverMoreLink', 'hidden'));
            this.wrapperAlreadyDoneAll.innerHTML = this.wrapperAlreadyDoneAll.innerHTML.replace(
                '$discoverMore',
                tempLink.outerHTML
            );
            this.wrapperAlreadyDoneAll.classList.remove(this._elMod('wrapperAlreadyDoneAll', 'hidden'));
            this.bottomTextInformative.classList.add(this._elMod('bottomTextInformative', 'hidden'));

            this._trackNotPartecipatedVariant();
        }
        this.root.querySelectorAll(this._elMod('discoverMoreLink', 'hidden', true)).forEach((hiddenButton) => {
            hiddenButton.remove();
        });

        closeLoader('main');
    }

    _addEventListeners() {
        this._dEl('box', true).forEach((box) => {
            this._addListener(
                'rcInputChanged',
                (event) => {
                    if (!event.target.classList.contains('rt003-checkbox')) {
                        this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
                    }
                    this._dEl('privacy').querySelector('input').required = true;
                    this._dEl('regulationCartaInsieme').querySelector('input').required = true;
                    this.userInteract = true;
                    this._enableSubmitBtn();
                },
                box
            );
        });

        this._addListener(
            'rcInputChanged',
            () => {
                let mailAddressInput = this.register.getClass(this._dEl('mailAddress'));
                if (mailAddressInput.getValue() == this.userData.mailOcdb) {
                    this._dEl('alertMessageInputs').classList.remove(this._elMod('alertMessageInputs', 'hidden'));
                    this._dEl('mailAlertMessage').classList.remove(this._elMod('mailAlertMessage', 'hidden'));
                } else {
                    if (!this._dEl('mailAlertMessage').classList.contains(this._elMod('mailAlertMessage', 'hidden'))) {
                        this._dEl('mailAlertMessage').classList.add(this._elMod('mailAlertMessage', 'hidden'));
                        this._dEl('alertMessageInputs').classList.add(this._elMod('alertMessageInputs', 'hidden'));
                    }
                }
                this.userInteract = true;
                this._enableSubmitBtn();
            },
            this._dEl('mailAddress')
        );

        this._addListener(
            'rcInputChanged',
            () => {
                this.userInteract = true;
                this._enableSubmitBtn();
            },
            this._dEl('phoneNumber')
        );

        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });

        this.submit.addEventListener('click', (event) => {
            event.preventDefault();

            if (!this.form.checkValidity()) return;

            this._submit();
        });

        this.links?.forEach((link) => {
            this._addListener(
                'click',
                (event) => {
                    if (this.userInteract) {
                        event.preventDefault();
                        this._openConfirmLeavePopUp(event.target.href);
                    }
                },
                link
            );
        });
    }

    async _submit() {
        let updatedData = formToJSON(this.form);
        if (updatedData.mailAddress && updatedData.mailAddress != this.userData.mailOcdb) {
            updatedData = { ...updatedData, emailOCDB: updatedData.mailAddress };
        }

        try {

            //track pageView Landing cta save click
            this.trackingManager.track(this.root, {
                event: 'salvaDatiRefresh',
                funnel: {
                    nomeFunnel: 'Conad Refresh',
                    stepFunnel:
                        ('privacyTZ' in this.userData ? this.labelIVvalorizzatoStep : this.labelIVnonValorizzatoStep) +
                        ' - CTA Salva',
                    quartoConsenso: updatedData.privacyTZ,
                },
            });

            delete updatedData.privacyCheckbox;
            delete updatedData.regulationCartaInsiemeCheckbox;
            delete updatedData.mailAddressLabel;

            let changedPrivacy = false;
            if (updatedData.privacy1 && updatedData.privacy1 != this.userData.privacy1) {
                changedPrivacy = true;
            }

            if (updatedData.privacy2 && updatedData.privacy2 != this.userData.privacy2) {
                changedPrivacy = true;
            }

            if (updatedData.privacy3 && updatedData.privacy3 != this.userData.privacy3) {
                changedPrivacy = true;
            }

            if (updatedData.privacyTZ && updatedData.privacyTZ != this.userData.privacyTZ) {
                changedPrivacy = true;
            }

            /*let choice;
            const extra = await this.flowManager.startAsyncFlow({
                flowName: 'save-profile',
                flowSteps: [{ name: 'refresh-prize-choice' }],
                initialData: {},
            });
            if (extra) {
                choice = extra.choice;
            }

            if(!choice) {
                return;
            }*/

            openLoader('main');

            /////////// update data
            if (updatedData.emailOCDB || updatedData.telefonoCellulare || changedPrivacy) {
                await this._callEditUserData(updatedData);
            }

            /*/////////// choice
            let changedCouponChoice = false;
            let result = await this.myConadApiProvider.setCouponChoice(choice);
            if (result) {
                changedCouponChoice = true;
            }*/ 

            /////////// update mail
            let changedMailLogin = false;
            if (updatedData.mailAddress && updatedData.mailAddress != this.userData.email) {
                const dataMailCall = {
                    email: updatedData.mailAddress,
                    updateGccMail: false,
                };
                let result = await this.myConadApiProvider.changeEmailRequestV2(dataMailCall);
                if (result) {
                    changedMailLogin = true;
                }
            }


            // reset session storage cache
            this.userService.resetUser();

            // send to complete
            const url = new URL(this.thankYouPageUrl, window.origin);
            if (changedMailLogin) {
                url.searchParams.append('newLoginMail', updatedData.mailAddress);
            }
           /* if (changedCouponChoice) {
                url.searchParams.append('choice', choice);
            }*/

            window.location = url.href;
        } catch (e) {
            console.warn(e);
        } finally {
            setTimeout(() => {
                closeLoader('main');
            }, 1000);
        }
    }

    async _callEditUserData(data) {
        const firmaDigitale = await this.myConadApiProvider.getFirmaDigitale();
        data.firmaDigitale = firmaDigitale;
        await this.myConadApiProvider.editUserData(data);
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _enableSubmitBtn() {
        const submitButton = this.register.getClass(this.submit);
        submitButton.setStatus('enabled');
    }

    async _disableSubmitBtn() {
        const submitButton = await this.register.getClass(this.submit);
        submitButton.setStatus('disabled');
    }

    _initNotEnabledRadioText(el) {
        let radio = this.register.getClass(el.querySelector('.rt004-radio'));
        if (radio.getValue() === 'N') {
            let boxNotSelected = el.querySelector(this._el('boxNotSelected', true));
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        }
    }

    _initPrivacyAndRegulationText(form) {
        const privacyCheckbox = form.querySelector(this._el('privacy', true));
        if (!privacyCheckbox) return;

        const privacyText = privacyCheckbox.querySelector('.rt003-checkbox__labelText');
        if (!privacyText) return;

        const privacyHtml = privacyText.innerHTML;
        if (!privacyHtml) return;

        const privacyLinkLabel =
            form.querySelector(this._el('boxCheckbox', true)).dataset.privacy || this.dictionary.get('privacy label');
        if (!privacyHtml.includes('$privacy')) {
            privacyText.innerHTML = privacyHtml;
            return;
        }
        const splitPrivacy = privacyHtml.split('$privacy');
        const linkPrivacy = document.createElement('a');
        linkPrivacy.href = '#';
        linkPrivacy.classList.add(this._el('privacyLink'));
        linkPrivacy.innerText = privacyLinkLabel;
        privacyText.innerHTML = splitPrivacy[0] + linkPrivacy.outerHTML + splitPrivacy[1];

        const regulationCheckbox = form.querySelector(this._el('regulationCartaInsieme', true));
        if (!regulationCheckbox) return;

        const regulationText = regulationCheckbox.querySelector('.rt003-checkbox__labelText');
        if (!regulationText) return;

        const regulationHtml = regulationText.innerHTML;
        if (!regulationHtml) return;

        const regulationLinkLabel =
            form.querySelector(this._el('boxCheckbox', true)).dataset.regulation ||
            this.dictionary.get('regulation label');
        if (!regulationHtml.includes('$regulation')) {
            regulationText.innerHTML = regulationHtml;
            return;
        }
        const splitRegulation = regulationHtml.split('$regulation');
        const linkRegulation = document.createElement('a');
        linkRegulation.href = '#';
        linkRegulation.classList.add(this._el('regulationLink'));
        linkRegulation.innerText = regulationLinkLabel;
        regulationText.innerHTML = splitRegulation[0] + linkRegulation.outerHTML + splitRegulation[1];
    }

    async _setPrivacyAndRegulation(form) {
        let coop = 'cnd';
        if (this.userData && this.userData.newMappedCooperative) coop = this.userData.newMappedCooperative;

        this.currentPrivacy = await this.apiProvider.getInformativa({
            cooperativa: coop,
            ecommerce: '0',
            card: this.userData && (this.userData.cartaFedelta || this.userData.conadCard),
        });
        //set privacy
        const privacyLink = form.querySelector(this._el('privacyLink', true));
        if (!privacyLink) {
            console.warn('Missing privacy link.');
            return;
        }
        privacyLink.href = this.currentPrivacy.linkPdfInformativa;
        privacyLink.title = `informativa ${this.currentPrivacy.version}`;
        privacyLink.target = '_blank';

        // set regulation
        const regolamentoLink = form.querySelector(this._el('regulationLink', true));
        if (!regolamentoLink) {
            console.warn('Missing regolamento link.');
            return;
        }
        //set regolamento link
        regolamentoLink.href = await this.myConadApiProvider.getRegolamentoLink(coop);
        regolamentoLink.title = `regolamento carta insieme`;
        regolamentoLink.target = '_blank';
    }

    _openConfirmLeavePopUp(url) {
        this.flowManager.startFlow({
            flowName: 'refresh-confirm-popup',
            flowSteps: [{ name: 'refresh-confirm-popup' }],
            initialData: { pageToGo: url },
        });
    }

    _trackNotPartecipatedVariant() {
        //track pageView Landing how to enter
        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Landing page come partecipare',
            },
        });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt154-update-data-refresh');
}
