
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class HeroCollection extends Component {
    constructor(name, root) {
        super(name, root);
        this.textColor = this.root.dataset.textColor;
        if (this.textColor) this._setTextColor();
    }

    _setTextColor() {
        const color = `${this.textColor}`;
        const textElements = [
            this._dEl('breadcrumb'),
            this._dEl('title'),
            this._dEl('description'),
            this._dEl('bottomText'),
        ];
        for (const el of textElements.filter((el) => !!el)) {
            el.style.color = color;
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt139-hero-collection');
}
