
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getApiProvider } from '../../../../../libs/api-provider';

export default class Strillo60Years extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    _doLogic() {
        this.cta = this._dEl('cta');
        this.ctaObj = this.cta ? ( getRegister().getClass(this.cta)) : null;
        this._updateCtaSize();
        this.credits = this._dEl('credits', true);
        this._checkUserCredits();
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this._updateCtaSize();
            },
            window
        );
    }

    async _checkUserCredits() {
        const user = await getUserService().getUser();

        // always visible on author side
        if (!window.wcmmodeDisabled) {
            // show component
            this.root.classList.remove(this._mod('hidden'));
        }

        // not visible for guest and light users
        if (!user || !user.cartaFedelta) {
            this._removeMainComponent();
            return;
        }

        // get user credits balance
        try {
            const data = {
                fidelityCard: user.cartaFedelta,
                initiativeCode: this.root.dataset.initiativeCode,
            }
            const result = await getApiProvider().getUserCreditsBalance(data);
            if (result.balance) {
                for (const creditsEl of this.credits) {
                    // update credits number
                    const creditsNumber = creditsEl.querySelector('span');
                    creditsNumber.innerText = result.balance;
                    // update credits text
                    const creditsText = creditsEl.querySelector('span + span');
                    creditsText.innerText = this.root.dataset.creditsLabel;
                }
                // update cta label
                this.ctaObj?.setLabel(this.root.dataset.playLabel);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            // show component
            this.root.classList.remove(this._mod('hidden'));
        }
    }

    _updateCtaSize() {
        this.ctaObj?.setSize(window.innerWidth < BREAKPOINTS.l ? 'small' : 'medium');
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt148-strillo-60years');
}
