
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';

export default class HeroPremio extends Component {
    constructor(name, root) {
        super(name, root);
        this.textColor = this.root.dataset.textColor;
        if (this.textColor) this._setTextColor();
    }

    _setTextColor() {
        const color = `${this.textColor}`;
        const textElements = [
            this._dEl('breadcrumb'),
            this._dEl('title'),
            this._dEl('abstract'),
            this._dEl('bolliniText'),
            this._dEl('pointsLabel'),
            this._dEl('pointsText'),
        ];
        for (const el of textElements.filter((el) => !!el)) {
            el.style.color = color;
        }
        const divider = this._dEl('divider');
        if (!divider) return;
        divider.style.backgroundColor = color;
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt140-hero-premio');
}
