import './style.scss';
import Component from '../../../../../libs/components/component';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getRegister } from '../../../../../libs/register';

export default class ThankYouSimpleImageText extends Component {
    constructor(name, root) {
        super(name, root);
        this.additionalText = this._dEl('additionalText');
        this.textWrapper = this._dEl('textWrapper');
        this.text = this._dEl('text');
        this.trackingManager = getTrackingManager();
        this._init();
    }

    _init() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('newLoginMail')) {
            this.additionalText.innerHTML = this.additionalText.innerHTML.replace('$email', urlParams.get('newLoginMail'));
            this.additionalText.classList.remove(this._elMod('additionalText', 'hidden'));
        }

        const choice = urlParams.get('choice');
        if (choice) {
            let choiceText;
            if (choice === 'shoppingCoupon') {
                choiceText = this.textWrapper.dataset.shoppingCouponText;
            }
            if (choice === 'travelCoupon') {
                choiceText = this.textWrapper.dataset.travelCouponText;
            }
            if (choiceText) {
                this.text.innerHTML = this.text.innerHTML.replace('$choice', choiceText);
            }
        }

        //track pageView thank you age
        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'TYP aggiornamento dati',
            },
        });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt155-thankyou-simple-image-text');
}
