import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';
import { generateTooltip } from '../../../../../libs/utils';

export default class TextField extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.error = this._dEl('error');
        this.info = this._dEl('info');
        this.tooltipId = null;

        if (this.root.hasAttribute('data-tooltip-text')) {
            this.setTooltip(this.root.dataset.tooltipText);
        }

        this.invalidFieldLabel = this.root.dataset.invalidFieldLabel || 'Campo non valido';
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        this._addListener(
            ['keyup', 'change', 'input'],
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                }
                this._changedInput();
            },
            this.input
        );

        this._addListener(
            'focusout',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.input
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                } else if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.root,
            true
        );

        this._addListener(
            'click',
            () => {
                const event = new CustomEvent('showTooltip', { bubbles: true });
                event.data = { 
                    show: true,
                    tooltipIcon: this.info,
                };
                document.getElementById(this.getTooltipId())?.dispatchEvent(event);
            },
            this.info
        );
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }

    _invalidField() {
        this.error.innerText = this.invalidFieldLabel;
    }

    getTooltipId() {
        return this.tooltipId;
    }

    async setTooltip(html, extraClasses = null) {
        if (this.tooltipId != null) document.getElementById(this.tooltipId)?.remove();
        this.tooltipId = await generateTooltip(html, extraClasses);
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt008-text-field');
}
