import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class BannerImageRefresh extends Component {
    constructor(name, root) {
        super(name, root);
        this.userService = getUserService();
        this.trackingManager = getTrackingManager();
        this.alreadyPartecipated = false;
        this.discoverMoreCta = this._dEl('discoverMoreLink');
        this.labelIVnonValorizzatoStep = 'Landing page aggiornamento dati - 4 Consenso non valorizzato';
        this.labelIVvalorizzatoStep = 'Landing page aggiornamento dati - 4 Consenso valorizzato';

        this._init();
        this._addEventListener();
    }

    async _init() {
        this.userData = await this.userService.getUser();
        if (this.userData) {
            this.alreadyPartecipated = this.userData.refreshAlready;
        }
    }

    _addEventListener() {
        this.discoverMoreCta.addEventListener('click', () => {
            if (this.userData.refresh) {
                //track pageView Landing cta discoverMore 153 click refresh
                this.trackingManager.track(this.root, {
                    event: 'interazioniRefresh',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel:
                            ('privacyTZ' in this.userData
                                ? this.labelIVvalorizzatoStep
                                : this.labelIVnonValorizzatoStep) + ' - Link Scopri di più',
                    },
                });
            } else if (this.alreadyPartecipated) {
                //track pageView Landing cta discoverMore 153 click refresh already participated
                this.trackingManager.track(this.root, {
                    event: 'interazioniRefresh',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel: 'Landing page partecipante - Link Scopri di piu',
                    },
                });
            } else {
                //track pageView Landing cta discoverMore 153 click no refresh
                this.trackingManager.track(this.root, {
                    event: 'interazioniRefresh',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel: 'Landing page come partecipare - Link Scopri di piu',
                    },
                });
            }
        });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt153-banner-image');
}
